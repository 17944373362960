import { StudyClient } from 'jf/api';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { StudyStep, useStudyBuilderState } from './StudyBuilderState';

export const useStudyBuilderSteps = () => {
  const state = useStudyBuilderState();

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: state.studyRef });

  if (!study) {
    return [];
  }

  const studySteps: StudyStep[] = [{ type: 'INTRO' }, { type: 'DEMOGRAPHIC' }];

  for (const slug of state.promptSlugs) {
    studySteps.push({ type: 'PROMPT', key: slug });
  }

  if (study.flags.includes('voting_enabled')) {
    studySteps.push({ type: 'VOTING' });
  }

  return studySteps;
};
