import React from 'react';
import { Flex, Grid, Progress } from 'antd';
import { css } from 'styled-components';

import { PublicStudyClient, Study } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { getStudySteps, useStudyState } from './StudyStateContext';

import JFLogoWatermark from 'jf/images/jf-logo-watermark.svg';

const { useBreakpoint } = Grid;

export const STUDY_PROGRESS_SIDEBAR_WIDTH = 360;

const styles = {
  sidebar: css`
    position: relative;
    z-index: 1;
    padding: 24px;
    background-color: ${(props) => props.theme.color.background.default};

    &.sidebar--large {
      position: fixed;
      height: 100vh;
      width: ${STUDY_PROGRESS_SIDEBAR_WIDTH}px;
      left: 0;
      border-right: 1px solid ${(props) => props.theme.color.border.primary};

      .ant-progress {
        .ant-progress-circle-trail {
          transition: stroke 0ms !important;
        }

        .ant-progress-circle-path {
          transition:
            all 300ms ease,
            stroke 0ms !important;
        }

        .ant-progress-text {
          .progress__inner {
            .inner__title {
              font-size: 48px;
              font-weight: 600;
              color: ${(props) => props.theme.color.text.primary};
            }

            .inner__subtitle {
              color: ${(props) => props.theme.color.text.tertiary};
              font-size: ${(props) => props.theme.variable.fontSize.sm};
            }
          }
        }
      }
    }

    &.sidebar--small {
      width: 100%;
      border-bottom: 1px solid ${(props) => props.theme.color.border.primary};

      .ant-progress {
        margin: 0;

        .ant-progress-outer {
          padding: 0;
        }
      }
    }

    .sidebar__header {
      .header__subtitle {
        color: ${(props) => props.theme.color.text.tertiary};
        font-size: 15px;
      }

      .header__title {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.4;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  `,
};

const getIndexPercent = (i, count) => Math.round((i / count) * 100);

export const StudyProgressSidebar: React.FC = () => {
  const theme = useDevExTheme();

  const studyState = useStudyState();
  const { data: study } = useClientQuery(PublicStudyClient.getPublicStudy, {
    studyRef: studyState.refs.studyRef,
  });

  const breakpoint = useBreakpoint();

  const studySteps = study ? getStudySteps(study) : [];

  const numberedStepStartIndex = studySteps.findIndex((step) => step.numbered);
  const numberedStepCount = studySteps.length - numberedStepStartIndex;
  const completedNumberedStepCount = Math.max(0, studyState.stepIndex - numberedStepStartIndex);

  const percent = getIndexPercent(completedNumberedStepCount, numberedStepCount);
  const nextPercent = getIndexPercent(completedNumberedStepCount + 1, numberedStepCount);

  const largeProgressSidebar = (
    <Flex
      vertical
      justify="space-between"
      css={styles.sidebar}
      className={'sidebar--large'}
      style={{
        // compensate for PreviewStudyHeader
        height: study?.status === Study.status.DRAFT ? 'calc(100vh - 103px)' : undefined,
      }}
    >
      <Flex vertical gap={140}>
        <Flex vertical gap="small" className={'sidebar__header'}>
          <div className={'header__subtitle'}>Survey</div>
          <div className={'header__title'}>{study?.name}</div>
        </Flex>

        <Flex justify="center">
          <Progress
            type="circle"
            percent={nextPercent}
            success={{ percent, strokeColor: theme.color.offbrand.default }}
            format={() => (
              <Flex vertical gap="small" className={'progress__inner'}>
                <div className={'inner__title'}>{percent}%</div>
                <div className={'inner__subtitle'}>
                  {completedNumberedStepCount}/{numberedStepCount} questions
                </div>
              </Flex>
            )}
            strokeColor={theme.color.offbrand.background.default}
            trailColor={theme.color.background.sunken}
            strokeLinecap={'square'}
            strokeWidth={10}
            size={180}
          />
        </Flex>
      </Flex>

      <Flex justify="center">
        <JFLogoWatermark style={{ color: theme.color.brand.logo }} />
      </Flex>
    </Flex>
  );

  const smallProgressSidebar = (
    <Flex vertical gap={20} css={styles.sidebar} className={'sidebar--small'}>
      <Flex vertical gap="small" className={'sidebar__header'}>
        <div className={'header__subtitle'}>Survey</div>
        <div className={'header__title'} style={{ whiteSpace: 'nowrap' }}>
          {study?.name}
        </div>
      </Flex>

      <Flex vertical gap={8}>
        <Progress
          percent={nextPercent}
          success={{ percent, strokeColor: theme.color.offbrand.default }}
          strokeColor={theme.color.offbrand.background.default}
          trailColor={theme.color.background.sunken}
          size={['100%', 20]}
        />
        <div style={{ color: theme.color.text.tertiary }}>
          {completedNumberedStepCount}/{numberedStepCount} questions
        </div>
      </Flex>
    </Flex>
  );

  return breakpoint.xl ? largeProgressSidebar : smallProgressSidebar;
};
