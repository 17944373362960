/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Company = {
    name: string;
    slug: string;
    readonly isEmp: boolean;
    /**
     * Return a boolean value corresponding to if the company has been onboarded.
     */
    readonly isOnboarded: boolean;
    /**
     * The estimated number of engineers at this company.
     */
    numberOfEmployees?: string;
    /**
     * Allow this company's data to be included in anonymous aggregate statistics
     */
    aggregatable?: boolean | null;
    /**
     * The issue tracking software of this company.
     *
     * * `0` - GitHub Issues
     * * `1` - ClickUp
     * * `2` - Shortcut
     * * `3` - GitLab
     * * `4` - Jira
     * * `5` - Azure Devops
     * * `6` - Trello
     * * `7` - Other
     * * `8` - I don't know
     */
    issueTrackingSw?: Company.issueTrackingSw | null;
    /**
     * The source code management software of this company.
     *
     * * `0` - BitBucket
     * * `1` - GitHub
     * * `2` - Perforce
     * * `3` - GitLab
     * * `4` - Azure Devops
     * * `5` - Other
     * * `6` - I don't know
     */
    sourceCodeMgmtSw?: Company.sourceCodeMgmtSw | null;
    readonly slackInstallUrl: string;
};

export namespace Company {

    /**
     * The issue tracking software of this company.
     *
     * * `0` - GitHub Issues
     * * `1` - ClickUp
     * * `2` - Shortcut
     * * `3` - GitLab
     * * `4` - Jira
     * * `5` - Azure Devops
     * * `6` - Trello
     * * `7` - Other
     * * `8` - I don't know
     */
    export enum issueTrackingSw {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
        '_5' = 5,
        '_6' = 6,
        '_7' = 7,
        '_8' = 8,
    }

    /**
     * The source code management software of this company.
     *
     * * `0` - BitBucket
     * * `1` - GitHub
     * * `2` - Perforce
     * * `3` - GitLab
     * * `4` - Azure Devops
     * * `5` - Other
     * * `6` - I don't know
     */
    export enum sourceCodeMgmtSw {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
        '_5' = 5,
        '_6' = 6,
    }


}

