import { useMemo } from 'react';
import { capitalize, keyBy, sortBy } from 'lodash-es';

import {
  NumericScore,
  NumericScoreWithTeamOrSegment,
  StudyClient,
  StudyLibraryClient,
} from 'jf/api';
import { arrayOf } from 'jf/utils/arrayOf';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyScores } from '../useStudyScores';
import { useStudyScoresBySegment } from '../useStudyScoresBySegment';

type DataRecord = {
  scaledAvg: number;
  type: string;
  typeLabel: string;
  segment?: {
    key: string;
    name: string;
    value: string;
  };
};

const getCsvData = (data: DataRecord[]) => {
  const headerRow = [capitalize(data[0]?.type ?? 'Type'), 'Score'];

  const segmented = data.some((datum) => datum.segment);
  if (segmented) {
    headerRow.splice(1, 0, 'Segment', 'Segment Value');
  }

  const csvData = [headerRow];

  if (!data.length) {
    csvData.push(arrayOf(headerRow.length).map(() => '')); // empty row
    return csvData;
  }

  for (const datum of data) {
    const dataRow = [datum.typeLabel, datum.scaledAvg.toString()];

    if (datum.segment) {
      dataRow.splice(1, 0, datum.segment.name, datum.segment.value);
    }

    csvData.push(dataRow);
  }

  return csvData;
};

const getJsonData = (data: DataRecord[]) => {
  return data.map((datum) => {
    const json = {
      [datum.type.toLowerCase()]: datum.typeLabel,
      score: datum.scaledAvg,
    };

    if (datum.segment) {
      json.segment = datum.segment.name;
      json.segmentValue = datum.segment.value;
    }

    return json;
  });
};

type UseExportableStudyScoresProps = {
  studyRef: string | undefined;
  teamRef: string | undefined;
  scoreType: NumericScore.type;
  segmented: boolean;
};

export const useExportableStudyScores = (props: UseExportableStudyScoresProps) => {
  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: props.studyRef });
  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);
  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const { data: scores } = useStudyScores({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    type: props.scoreType,
  });
  const { data: scoresBySegment } = useStudyScoresBySegment({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    type: props.scoreType,
  });

  const [csv, json] = useMemo(() => {
    if (!prompts || !topics || !scores || !scoresBySegment || !study) {
      return [];
    }

    const topicsBySlug = keyBy(topics, 'slug');
    const promptsBySlug = keyBy(prompts, 'slug');

    const scoreData: NumericScoreWithTeamOrSegment[] = props.segmented ? scoresBySegment : scores;

    const findSegmentIndex = (key?: string) =>
      key ? study.analysisSegments.findIndex((as) => as.key === key && as.visible) : 0;

    let data = scoreData
      .map((datum) => {
        let segment: DataRecord['segment'] | undefined;
        if (datum.segment) {
          segment = {
            key: `segment:${datum.segment.name}`,
            ...datum.segment,
          };
          // only show teamSegment when not filtering by team
        } else if (!props.teamRef && datum.teamSegment) {
          segment = {
            key: `teamSegment:${datum.teamSegment.name}`,
            ...datum.teamSegment,
          };
        }

        return {
          scaledAvg: datum.scaledAvg,
          type: datum.type,
          typeLabel:
            datum.type === NumericScore.type.TOPIC
              ? topicsBySlug[datum.slug].label
              : promptsBySlug[datum.slug].text,
          segment,
        };
      })
      .filter((record) => findSegmentIndex(record.segment?.key) > -1);

    data = sortBy(data, ['typeLabel', (record) => findSegmentIndex(record.segment?.key)]);

    return [getCsvData(data), getJsonData(data)];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prompts, topics, scores, scoresBySegment, props.scoreType, props.segmented, study]);

  return { csv, json };
};
