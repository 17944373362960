import React from 'react';
import { Menu, MenuProps } from 'antd';
import { css } from 'styled-components';

const styles = {
  menu: css`
    &&.ant-menu {
      border-inline-end: none;

      .ant-menu-item {
        padding-inline: ${(props) => props.theme.variable.spacing.sm}
          ${(props) => props.theme.variable.spacing.md};
        margin: 0;
        width: 100%;
        height: 32px;
        line-height: 32px;

        &:hover {
          background-color: ${(props) => props.theme.color.background.hovered};
        }
      }

      .ant-menu-item-divider {
        border-color: ${(props) => props.theme.color.border.primary};
        margin-block: ${(props) => props.theme.variable.spacing.sm};
      }
    }
  `,
};

export const DevExMenu: React.FC<MenuProps> = (props) => {
  return <Menu {...props} css={styles.menu} />;
};
