import React from 'react';
import { Flex } from 'antd';
import { faLineChart, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faClipboard } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { StudyClient, StudySequenceClient } from 'jf/api';
import { DevExPageHeader } from 'jf/common/DevExPageHeader';
import { DevExPageLayout } from 'jf/common/DevExPageLayout';
import { DevExPageTitle } from 'jf/common/DevExPageTitle';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExLoader } from 'jf/components/DevExLoader';
import { DevExRadioButtons } from 'jf/components/DevExRadioButtons';
import { useLocalStorage } from 'jf/utils/useBrowserStorage';
import { useClientQuery } from 'jf/utils/useClientQuery';
import { useNavigateToStudy } from 'jf/utils/useNavigateToStudy';

import { CreateStudySequenceModal } from './CreateStudySequenceModal';
import { OnboardingBanner } from './OnboardingBanner';
import { STUDIES_TABLE_FILTER_OPTIONS, StudiesTable, StudiesTableFilter } from './StudiesTable';
import { STUDY_SEQUENCE_CARD_MIN_WIDTH, StudySequenceCard } from './StudySequenceCard';

export const StudiesPage: React.FC = () => {
  const navigate = useNavigate();
  const navigateToStudy = useNavigateToStudy();
  const theme = useDevExTheme();

  const [onboardingBannerDismissed, setOnboardingBannerDismissed] = useLocalStorage(
    'devex_onboardingBanner_dismissed',
    false
  );

  const { data: studies } = useClientQuery(StudyClient.getStudies);
  const { data: studySequences } = useClientQuery(StudySequenceClient.getStudySequences);

  const createSeriesModal = useJFOverlay(CreateStudySequenceModal);

  const [selectedFilter, setSelectedFilter] = useLocalStorage(
    'selected_studies_table_filter',
    StudiesTableFilter.ALL
  );
  const onCreateSurvey = () => {
    trackEvent('survey-creator:create:start');
    navigate(`/study/build`);
  };

  const loading = !studies || !studySequences;

  return (
    <Flex vertical align="center" style={{ flex: 1 }}>
      <DevExPageTitle>DevEx Surveys</DevExPageTitle>
      <DevExPageHeader
        title="Surveys"
        extra={
          !!studies?.length && (
            <Flex gap={theme.variable.spacing.md}>
              <DevExButton
                icon={<FontAwesomeIcon icon={faLineChart} />}
                type="outline"
                size="large"
                onClick={() => createSeriesModal.open()}
                adminRequired
              >
                Create series
              </DevExButton>
              <DevExButton
                type="primary"
                size="large"
                icon={<FontAwesomeIcon icon={faPlus} />}
                onClick={onCreateSurvey}
                // GBAC RESTRICTION
                // only customer admins can create studies
                adminRequired
              >
                Create survey
              </DevExButton>
            </Flex>
          )
        }
      />

      <DevExPageLayout style={{ flex: 1 }}>
        {!onboardingBannerDismissed && (
          <OnboardingBanner onDismiss={() => setOnboardingBannerDismissed(true)} />
        )}

        {loading ? (
          <DevExLoader />
        ) : (
          <Flex vertical gap={theme.variable.spacing.xl}>
            {!!studySequences.length && (
              <div
                css={css`
                  display: grid;
                  grid-template-columns: repeat(
                    auto-fill,
                    minmax(${STUDY_SEQUENCE_CARD_MIN_WIDTH}, 1fr)
                  );
                  gap: ${(props) => props.theme.variable.spacing.md};
                `}
              >
                {studySequences.map((sequence) => (
                  <StudySequenceCard key={sequence.ref} sequence={sequence} />
                ))}
              </div>
            )}

            {!!studies.length && (
              <Flex vertical gap={theme.variable.spacing.lg}>
                <DevExRadioButtons
                  items={STUDIES_TABLE_FILTER_OPTIONS}
                  onChange={(filter: StudiesTableFilter) => {
                    setSelectedFilter(filter);
                    trackEvent('survey-list:filter:click', { option: filter });
                  }}
                  value={selectedFilter}
                />
                <StudiesTable
                  filter={selectedFilter}
                  onRowClick={(study) => navigateToStudy(study)}
                />
              </Flex>
            )}

            {!studies.length && onboardingBannerDismissed && (
              <DevExCard
                style={{
                  padding: theme.variable.spacing.lg,
                }}
                bodyStyle={{
                  gap: theme.variable.spacing.lg,
                  alignItems: 'center',
                }}
              >
                <DevExEmpty icon={faClipboard} />

                <div style={{ fontSize: 24, fontFamily: theme.variable.fontFamily.secondary }}>
                  You haven't done any surveys yet!
                </div>

                <div style={{ fontSize: 16, textAlign: 'center', lineHeight: 1.4, maxWidth: 300 }}>
                  Click the button below to start your{' '}
                  <span style={{ color: theme.color.brand.logo }}>Developer Experience</span>{' '}
                  journey!
                </div>

                <DevExButton
                  type="primary"
                  size="large"
                  onClick={() => navigate(`/study/build`)}
                  adminRequired
                >
                  Create survey
                </DevExButton>
              </DevExCard>
            )}
          </Flex>
        )}
      </DevExPageLayout>
    </Flex>
  );
};
