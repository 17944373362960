import React, { useMemo } from 'react';

import { RosterClient } from 'jf/api';
import { QuestionCardDemographic } from 'jf/pages/study/question/QuestionCardDemographic';
import { useClientQuery } from 'jf/utils/useClientQuery';

export const DEFAULT_TEAM_DEMOGRAPHIC_TEXT = 'What is your primary team?';

export const CustomizableDemographic: React.FC = () => {
  const { data: teams } = useClientQuery(RosterClient.getTeams);

  const teamOptions = useMemo(
    () =>
      teams
        ?.filter((team) => team.isLeaf && !team.isHidden)
        .map((team) => ({ value: team.ref, label: team.value })),
    [teams]
  );

  return (
    <QuestionCardDemographic
      disabled
      subtitle="Demographics"
      title={DEFAULT_TEAM_DEMOGRAPHIC_TEXT}
      placeholder={'Select team...'}
      options={teamOptions ?? []}
    />
  );
};
