import { keyBy } from 'lodash-es';
import { useQueryClient } from 'react-query';

import { RosterClient } from 'jf/api';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyBuilderState } from '../StudyBuilderState';

export const useCompleteConfigureTeamsStep = () => {
  const queryClient = useQueryClient();
  const state = useStudyBuilderState();

  const { data: teams } = useClientQuery(RosterClient.getTeams);

  const { mutateAsync: deleteTeam } = useClientMutation(RosterClient.deleteTeam);
  const { mutateAsync: createTeam } = useClientMutation(RosterClient.createTeam);
  const { mutateAsync: updateTeam } = useClientMutation(RosterClient.updateTeam);

  const complete = async () => {
    const teamsByRef = keyBy(teams, 'ref');
    const queries: Promise<any>[] = [];

    for (const unsavedTeam of state.teams) {
      const savedTeam = teamsByRef[unsavedTeam.ref];

      if (unsavedTeam.ref.startsWith('UNSAVED')) {
        // create team
        queries.push(
          createTeam({
            requestBody: {
              name: unsavedTeam.value,
              parentRef: unsavedTeam.parentRef,
            },
          })
        );
      } else if (unsavedTeam.isHidden !== savedTeam?.isHidden) {
        if (unsavedTeam.isHidden) {
          // delete/hide team
          queries.push(deleteTeam({ teamRef: unsavedTeam.ref }));
        } else {
          // unhide team
          queries.push(updateTeam({ teamRef: unsavedTeam.ref, requestBody: { isHidden: false } }));
        }
      }
    }

    if (queries.length) {
      return Promise.allSettled(queries).then(() => queryClient.refetchQueries(['GET_TEAMS']));
    }
  };

  return complete;
};
