import React, { useMemo, useState } from 'react';
import { Flex } from 'antd';
import { faLineChart } from '@fortawesome/pro-thin-svg-icons';
import { keyBy } from 'lodash-es';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { trackEvent } from 'jf/analytics/Analytics';
import { CanonicalTopic, Study, StudyLibraryClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExRechartsTooltipContent } from 'jf/components/DevExRechartsTooltipContent';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { getTopicPriorityBySlug } from '../studyAnalyzer/insights/getTopicPriorityBySlug';

import { useSequenceScores } from './useSequenceScores';
import { useSequenceStudies } from './useSequenceStudies';
import { useSequenceVerbatims } from './useSequenceVerbatims';

const CHART_HEIGHT = 500;
const LINE_WIDTH = 3;

type TopicPriorityChartProps = {
  sequenceRef: string | undefined;
  teamRef?: string;
};

export const TopicPriorityChart: React.FC<TopicPriorityChartProps> = (props) => {
  const theme = useDevExTheme();

  const [activeTopicSlug, setActiveTopicSlug] = useState<string>();

  const closedStudies = useSequenceStudies(props.sequenceRef, Study.status.CLOSED);

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const topicsBySlug = keyBy(topics, 'slug');

  const scoreQueries = useSequenceScores({
    sequenceRef: props.sequenceRef,
    teamRef: props.teamRef,
  });

  const topicVerbatimQueries = useSequenceVerbatims({
    sequenceRef: props.sequenceRef,
    teamRef: props.teamRef,
  });

  const data: Record<string, string | number>[] | undefined = useMemo(() => {
    if (
      !closedStudies ||
      !topics ||
      !scoreQueries.every((query) => query.isSuccess) ||
      !topicVerbatimQueries.every((query) => query.isSuccess)
    ) {
      return undefined;
    }
    const data: Record<string, string | number>[] = [];
    closedStudies.forEach((study, i) => {
      const studyScores = scoreQueries[i].data!;
      const studyVerbatims = topicVerbatimQueries[i].data!;

      const topicPriorityBySlug = getTopicPriorityBySlug(topics, studyScores, studyVerbatims);
      if (studyScores.length > 0 && studyVerbatims.length > 0) {
        const datum = {
          name: study.name,
          ...topicPriorityBySlug,
        };

        data.push(datum);
      }
    });
    return data;
  }, [closedStudies, topics, scoreQueries, topicVerbatimQueries]);
  const numStudies = data?.length;

  // collect topics that have data points on the chart, ordered by latest study priority
  const orderedTopics: CanonicalTopic[] = useMemo(() => {
    if (!topics || !data) {
      return [];
    }

    // using a Map since ordering is important here
    const orderedTopicsBySlug = new Map<string, CanonicalTopic>();

    // iterate through data in reverse, so the latest study dictates order
    for (const datum of [...data].reverse()) {
      // sort topic slugs by priority
      const sortedSlugs = Object.entries(datum)
        .filter(([key]) => key !== 'name')
        .sort((a, b) => (a[1] as number) - (b[1] as number))
        .map(([key]) => key);

      for (const slug of sortedSlugs) {
        orderedTopicsBySlug.set(slug, topicsBySlug[slug]);
      }
    }

    return [...orderedTopicsBySlug].map(([_key, value]) => value);
  }, [topics, data]);

  if (!data) {
    return <DevExSkeleton height={CHART_HEIGHT} />;
  }

  const activeTopic = activeTopicSlug ? topicsBySlug[activeTopicSlug] : undefined;

  const margin = parseInt(theme.variable.spacing.md);

  const isActive = (topicSlug: string) => !activeTopic || activeTopic.slug === topicSlug;
  return (
    <>
      {numStudies && numStudies > 1 ? (
        <ResponsiveContainer width={'100%'} height={CHART_HEIGHT}>
          <LineChart
            data={data}
            margin={{ left: margin * 2, top: margin, right: margin * 12, bottom: margin }}
            onMouseLeave={() => setActiveTopicSlug(undefined)}
          >
            <CartesianGrid vertical={false} stroke={theme.color.visualization.grid} />
            <RechartsTooltip
              content={(tooltipProps) => (
                <DevExRechartsTooltipContent
                  {...tooltipProps}
                  // custom handling to only allow a single active dot
                  payload={tooltipProps.payload?.filter((p) => p.name === activeTopic?.slug)}
                  formatValue={(value) => (
                    <Flex vertical gap={theme.variable.spacing.xs}>
                      {activeTopic?.label}
                      <strong>#{value}</strong>
                    </Flex>
                  )}
                />
              )}
            />
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              tick={{
                fill: theme.color.text.secondary,
                fontSize: theme.variable.fontSize.xs,
              }}
              interval={0}
              padding={{
                left: parseInt(theme.variable.spacing.md),
                right: parseInt(theme.variable.spacing.md),
              }}
              tickMargin={parseInt(theme.variable.spacing.md)}
            />
            <YAxis
              domain={[1, 'dataMax']}
              reversed
              tickCount={orderedTopics.length}
              orientation="left"
              tickLine={false}
              axisLine={false}
              yAxisId={'left'}
              tick={{ fill: theme.color.text.secondary }}
              width={parseInt(theme.variable.spacing.lg)}
              tickFormatter={(value, index) => `#${index + 1}`}
            />
            <YAxis
              domain={[1, 'dataMax']}
              reversed
              tickCount={orderedTopics.length}
              orientation="right"
              tickLine={false}
              axisLine={false}
              yAxisId={'right'}
              width={parseInt(theme.variable.spacing.lg)}
              tick={(tickProps) => {
                const topic = orderedTopics[tickProps.index];
                const active = isActive(topic.slug);
                return (
                  <text
                    x={tickProps.x}
                    y={tickProps.y + 4}
                    fill={theme.color.text.primary}
                    style={{
                      opacity: active ? 1 : theme.variable.opacity.disabled,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      trackEvent('series-analyzer:priority-chart:click-topic', {
                        seriesRef: props.sequenceRef,
                        topicSlug: topic.slug,
                      });
                      setActiveTopicSlug(topic.slug);
                    }}
                  >
                    {topic.label}
                  </text>
                );
              }}
            />
            {orderedTopics.map((topic, i) => {
              const active = isActive(topic.slug);
              return (
                <>
                  {/* Added an invisible line here to force the two y axes to display */}
                  <Line
                    key={topic.slug + '-hidden'}
                    type="linear"
                    dataKey={topic.slug}
                    yAxisId={'left'}
                    style={{ display: 'none' }}
                  />
                  <Line
                    key={topic.slug}
                    type="linear"
                    dataKey={topic.slug}
                    stroke={active ? theme.color.topic[topic.slug] : theme.color.visualization.grid}
                    strokeWidth={LINE_WIDTH}
                    dot={{ r: 4 }}
                    // custom handling to only allow a single active dot
                    activeDot={{
                      onMouseOver: () => setActiveTopicSlug(topic.slug),
                      opacity: topic.slug === activeTopicSlug ? 1 : 0,
                    }}
                    opacity={active ? 1 : theme.variable.opacity.disabled}
                    animationDuration={1000}
                    yAxisId={'right'}
                  />
                </>
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <DevExEmpty
          label="This team only has prioritized topics for one closed survey in the series"
          icon={faLineChart}
        />
      )}
    </>
  );
};
