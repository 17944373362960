import React from 'react';
import { Flex } from 'antd';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { faShare } from '@fortawesome/pro-regular-svg-icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { CircledFontAwesomeIcon } from 'jf/components/CircledFontAwesomeIcon';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExExpandableAlert, DevExExpandableAlertType } from 'jf/components/DevExExpandableAlert';
import { DevExModal } from 'jf/components/DevExModal';
import { DevExText } from 'jf/components/DevExText';
import { useStudySlackTargetUpdater } from 'jf/pages/studyBuilder/targetRespondents/useStudySlackTargetUpdater';

export const SlackDisconnectedModal: React.FC = () => {
  const modal = useJFOverlay(SlackDisconnectedModal);
  const { studyRef } = useParams<{ studyRef: string }>();
  const theme = useDevExTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const studySlackTargetUpdater = useStudySlackTargetUpdater(studyRef);

  const onCancel = () => {
    trackEvent('survey-analyzer:slack-disconnected:dismiss');
    modal.close();
    navigate('/studies');
  };

  const onConnect = () => {
    trackEvent('survey-analyzer:slack-disconnected:reconnect');
    window.location.href = `${window.location.origin}${window.dx.user?.company.slackInstallUrl}?state=${location.pathname}`;
  };

  const onRevert = () => {
    trackEvent('survey-analyzer:slack-disconnected:revert');
    // delete all existing Slack targets to revert study to "Share link" mode
    studySlackTargetUpdater.update([]).then(() => modal.close());
  };

  return (
    <DevExModal
      open={modal.isOpened}
      onCancel={onCancel}
      title="Slack Disconnected"
      footer={null}
      width={650}
    >
      <Flex vertical gap={theme.variable.spacing.md}>
        <DevExExpandableAlert
          type={DevExExpandableAlertType.WARNING}
          title="The Jellyfish DevEx Slack bot has been uninstalled. To continue, you must either reinstall the Slack bot or revert to manual distribution."
        />

        <Flex gap={theme.variable.spacing.md}>
          <DevExCard style={{ flex: 1 }} onClick={onConnect}>
            <Flex vertical align="center" gap={theme.variable.spacing.md}>
              <CircledFontAwesomeIcon icon={faSlack} style={{ color: theme.color.text.tertiary }} />
              <DevExText fontSize="md">Reconnect Slack bot</DevExText>
            </Flex>
          </DevExCard>

          <DevExCard style={{ flex: 1 }} onClick={onRevert}>
            <Flex vertical align="center" gap={theme.variable.spacing.md}>
              <CircledFontAwesomeIcon icon={faShare} style={{ color: theme.color.text.tertiary }} />
              <DevExText fontSize="md"> I'll share the link myself</DevExText>
            </Flex>
          </DevExCard>
        </Flex>
      </Flex>
    </DevExModal>
  );
};
