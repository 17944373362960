// COLORS ONLY TO BE USED VIA DevExTheme TOKENS!
export const _FOUNDATIONS = {
  neutral: [
    undefined,
    '#FFFFFF',
    '#FBFBFB',
    '#F5F4F5',
    '#E9E8EC',
    '#DEDDE2',
    '#C8C5D0',
    '#B7B3C4',
    '#928DA5',
    '#726C89',
    '#666179',
    '#2D2749',
    '#252045',
    '#1E173C',
    '#140D30',
    '#0D062B',
  ],
  red: [
    undefined,
    '#FFEFF0',
    '#FFCFD3',
    '#FF9CA3',
    '#FF7882',
    '#FF5763',
    '#E90F36',
    '#CE0B2E',
    '#B60928',
    '#890D23',
    '#660012',
  ],
  orange: [
    undefined,
    '#FFF3EB',
    '#FFE4CF',
    '#FFC8A0',
    '#FFAD70',
    '#FF9041',
    '#FF7511',
    '#C85604',
    '#A04503',
    '#7D3603',
    '#612500',
  ],
  yellow: [
    undefined,
    '#FFFBD7',
    '#FEF8B9',
    '#FEF08A',
    '#FDE047',
    '#FACC15',
    '#EAB308',
    '#CA8A04',
    '#A86300',
    '#8B500E',
    '#6B380B',
  ],
  green: [
    undefined,
    '#EBFFEF',
    '#ABFCBD',
    '#87F2AC',
    '#61E08C',
    '#40D47B',
    '#14A958',
    '#008445',
    '#00713B',
    '#044D28',
    '#003F21',
  ],
  blue: [
    undefined,
    '#EBF4FF',
    '#BFDBFE',
    '#93C5FD',
    '#60A5FA',
    '#3B82F6',
    '#2563EB',
    '#1D4ED8',
    '#193DB4',
    '#123187',
    '#0C205A',
  ],
  purple: [
    undefined,
    '#F3ECFE',
    '#E3D1FC',
    '#C7A3FA',
    '#AB75F7',
    '#8F47F5',
    '#7319F2',
    '#5613B5',
    '#471093',
    '#350C6E',
    '#230849',
  ],
  magenta: [
    undefined,
    '#FFF0F8',
    '#FFD6EB',
    '#FFB2D9',
    '#FF80BF',
    '#F358A5',
    '#DB2781',
    '#C6106B',
    '#A60D5A',
    '#8F0C4D',
    '#6C093B',
  ],
  lime: [
    undefined,
    '#F5FCCE',
    '#ECF99D',
    '#ECF99D',
    '#D9F23A',
    '#CFEF09',
    '#BAE21D',
    '#94BD0F',
    '#709605',
    '#4F7000',
    '#314A00',
  ],
  salmon: [
    undefined,
    '#FEF1EE',
    '#FDE3DE',
    '#FBD5CD',
    '#FAC7BD',
    '#F9B9AC',
    '#F8AC9B',
    '#F79E8B',
    '#F5907A',
    '#F4826A',
    '#F37459',
  ],
  teal: [
    undefined,
    '#E8F9F6',
    '#D1F3EC',
    '#B9EDE3',
    '#A2E7DA',
    '#8BE0D0',
    '#74DAC7',
    '#5DD4BE',
    '#45CEB5',
    '#2EC8AB',
    '#17C2A2',
  ],
};
