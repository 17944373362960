import React, { useEffect, useRef, useState } from 'react';
import { Flex, InputRef } from 'antd';
import { useNavigate } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { StudySequenceClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExModal } from 'jf/components/DevExModal';
import { queryClient } from 'jf/utils/queryClient';
import { useClientMutation } from 'jf/utils/useClientQuery';

export const CreateStudySequenceModal = () => {
  const modal = useJFOverlay(CreateStudySequenceModal);
  const [input, setInput] = useState('');
  const theme = useDevExTheme();
  const inputRef = useRef<InputRef>(null);
  const navigate = useNavigate();

  const { mutateAsync: createSequence, isLoading: isCreating } = useClientMutation(
    StudySequenceClient.createStudySequence
  );

  const onCancel = () => {
    if (!isCreating) {
      modal.close();
    }
  };

  const onOk = () => {
    createSequence({
      requestBody: {
        name: input,
      },
    }).then((sequence) => {
      const ref = sequence.ref;
      trackEvent('survey-list:series-actions:create', { seriesRef: ref });
      navigate(`/series/${ref}`);
      queryClient.invalidateQueries('GET_STUDY_SEQUENCES');
      modal.close();
    });
    modal.close();
  };

  useEffect(() => {
    if (modal.isOpened) {
      setTimeout(() => inputRef.current?.select(), 0);
      setInput('');
    }
  }, [modal.isOpened]);

  return (
    <DevExModal
      title="Create a new series"
      open={modal.isOpened}
      onCancel={onCancel}
      footer={
        <>
          <DevExButton onClick={onCancel}>Cancel</DevExButton>
          <DevExButton
            type="primary"
            onClick={onOk}
            loading={isCreating}
            disabled={!input}
            data-cy="create-series-button"
          >
            Create
          </DevExButton>
        </>
      }
    >
      <Flex vertical gap={theme.variable.spacing.sm}>
        <span>What would you like to name it?</span>
        <DevExInput
          ref={inputRef}
          value={input}
          onChange={setInput}
          placeholder="Series name"
          data-cy="name-series-input"
        />
      </Flex>
    </DevExModal>
  );
};
