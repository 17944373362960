/**
 * Get object keys with nesting.
 * @example
 * { alpha: { beta: 'value' } } => ['alpha.beta']
 */
export const getNestedObjectKeys = (object: object) => {
  const keys: string[] = [];

  for (const [key, value] of Object.entries(object)) {
    if (typeof value === 'object') {
      const nestedKeys = getNestedObjectKeys(value);
      keys.push(...nestedKeys.map((nestedKey) => `${key}.${nestedKey}`));
    } else {
      keys.push(key);
    }
  }

  return keys;
};
