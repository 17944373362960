import React from 'react';
import { Flex } from 'antd';

import { DevExPageHeader } from 'jf/common/DevExPageHeader';
import { DevExPageTitle } from 'jf/common/DevExPageTitle';

export const TeamsPage: React.FC = () => {
  return (
    <Flex vertical align="center" style={{ flex: 1 }}>
      <DevExPageTitle>DevEx Teams</DevExPageTitle>
      <DevExPageHeader title="Teams" />
    </Flex>
  );
};
