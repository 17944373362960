import { useNavigate } from 'react-router-dom';

import { Study } from 'jf/api';

export const useNavigateToStudy = () => {
  const navigate = useNavigate();

  const navigateToStudy = (study: Study, teamRef?: string) => {
    if (study.status === Study.status.DRAFT) {
      navigate(`/study/${study.ref}/build`);
    } else if (study.status === Study.status.OPEN) {
      navigate(`/study/${study.ref}/analyze/participation`);
    } else {
      const teamSection = teamRef ? `/${teamRef}` : '';
      navigate(`/study/${study.ref}/analyze${teamSection}/insights`);
    }
  };

  return navigateToStudy;
};
