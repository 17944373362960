import React from 'react';
import { Flex } from 'antd';
import { motion, Variants } from 'framer-motion';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExLottie } from 'jf/components/DevExLottie';
import Scroll from 'jf/devex/lottie/Scroll.json';

import { CenteredSticky } from './CenteredSticky';
import { GradientTransition } from './GradientTransition';

import JFLogo from 'jf/images/jf-logo.svg';

const styles = {
  landing: css`
    background-color: ${({ theme }) => theme.color.reveal.dark.background};
    .landing__text {
      width: 100vw;
      text-align: center;
      line-height: 2;
    }
  `,
};

export const RevealLanding: React.FC = () => {
  const theme = useDevExTheme();

  const fadeIn: Variants = {
    initial: { opacity: 0, y: 10 },
    animate: (custom: number) => ({
      opacity: 1,
      y: 0,
      transition: { delay: 0.3 * custom, duration: 0.7 },
    }),
  };

  return (
    <div>
      <div css={styles.landing}>
        <Flex vertical align="center" justify="center">
          <Flex style={{ paddingTop: '2%', paddingBottom: '12%' }}>
            {/* jf logo */}
            <JFLogo
              height={theme.variable.fontSize.xxl}
              color={theme.color.reveal.accent}
              style={{ paddingInlineEnd: theme.variable.spacing.sm }}
            />
            <div
              style={{
                fontSize: '48px',
                fontFamily: theme.variable.fontFamily.secondary,
                gap: theme.variable.spacing.lg,
              }}
            >
              <span style={{ fontWeight: 'bold', color: theme.color.reveal.dark.text }}>DevEx</span>
            </div>
          </Flex>
          {/* intro text */}
          <CenteredSticky className="landing__text">
            <motion.div
              variants={fadeIn}
              whileInView="animate"
              style={{
                fontSize: '64px',
                fontFamily: theme.variable.fontFamily.secondary,
                color: theme.color.reveal.dark.text,
              }}
              initial="initial"
              viewport={{ once: true }}
              custom={0}
            >
              Hi there!
            </motion.div>
            <motion.div
              variants={fadeIn}
              whileInView="animate"
              style={{
                fontSize: '48px',
                fontFamily: theme.variable.fontFamily.secondary,
                color: theme.color.reveal.dark.text,
              }}
              initial="initial"
              viewport={{ once: true }}
              custom={1}
            >
              Your DevEx results are in!
            </motion.div>
            <motion.div
              variants={fadeIn}
              whileInView="animate"
              style={{
                fontSize: '48px',
                fontFamily: theme.variable.fontFamily.secondary,
                color: theme.color.reveal.dark.text,
              }}
              initial="initial"
              viewport={{ once: true }}
              custom={4}
            >
              Are you ready?
            </motion.div>
            <motion.div
              variants={fadeIn}
              whileInView="animate"
              style={{
                fontSize: theme.variable.fontSize.sm,
                paddingTop: '2%',
                fontFamily: theme.variable.fontFamily.secondary,
                color: theme.color.reveal.dark.text,
              }}
              initial="initial"
              viewport={{ once: true }}
              custom={8}
            >
              Scroll down
            </motion.div>
          </CenteredSticky>
        </Flex>
        <Flex vertical align="center">
          <motion.div
            variants={fadeIn}
            whileInView="animate"
            initial="initial"
            viewport={{ once: true }}
            custom={10}
          >
            <DevExLottie
              data={Scroll}
              colors={[theme.color.reveal.dark.text, theme.color.reveal.dark.secondary]}
              style={{ height: '80px', width: '80px' }}
              loop
            />
          </motion.div>
        </Flex>
      </div>
      <GradientTransition
        topColor={theme.color.reveal.dark.background}
        bottomColor={theme.color.reveal.purple.main.background}
      />
      <div
        style={{
          width: '100vw',
          height: '20vh',
          backgroundColor: theme.color.reveal.purple.main.background,
        }}
      />
    </div>
  );
};
