import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Flex, InputRef } from 'antd';
import { faCheck, faChevronRight, faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faMagnifyingGlass, faMessageQuestion } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual, keyBy, mapValues } from 'lodash-es';
import { css } from 'styled-components';

import { Prompt, StudyLibraryClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExMenuItem, DevExMenuItemProps } from 'jf/components/DevExMenuItem';
import { DevExModal } from 'jf/components/DevExModal';
import { DevExTag } from 'jf/components/DevExTag';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyBuilderState } from '../../StudyBuilderState';
import { useStudyBuilderSteps } from '../../useStudyBuilderSteps';
import { useStudyUpdater } from '../../useStudyUpdater';
import { PromptIcon } from '../PromptIcon';

const styles = {
  promptsModal: css`
    padding: 0;

    .card__body {
      flex-direction: row;
      gap: 0;
    }

    .promptsModal__sections {
      border-right: 1px solid ${(props) => props.theme.color.border.primary};
      width: 280px;
      height: 420px;

      > div {
        padding: ${(props) => props.theme.variable.spacing.sm};

        &:not(:last-child) {
          border-bottom: 1px solid ${(props) => props.theme.color.border.primary};
        }
      }
    }

    .promptsModal__prompts {
      padding: ${(props) => props.theme.variable.spacing.sm};
      flex: 1;
      height: 420px;
      overflow-y: auto;
    }
  `,
};

export const PromptsModal: React.FC = () => {
  const modal = useJFOverlay(PromptsModal);
  const theme = useDevExTheme();
  const state = useStudyBuilderState();

  const searchInputRef = useRef<InputRef>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const [scope, setScope] = useState<Prompt.scope>(Prompt.scope.CANONICAL);
  const [topicSlug, setTopicSlug] = useState<string>();

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);

  // clear state on close
  useEffect(() => {
    setScope(Prompt.scope.CANONICAL);
    setTopicSlug(undefined);
    setSearchQuery('');

    if (modal.isOpened) {
      // without setTimeout, this only works on fresh load and I'm not sure why...
      setTimeout(() => searchInputRef.current?.focus(), 0);
    }
  }, [modal.isOpened]);

  return (
    <DevExModal
      open={modal.isOpened}
      onCancel={() => modal.close()}
      title="Question Library"
      footer={null}
      width={1000}
    >
      <Flex vertical gap={theme.variable.spacing.md}>
        <DevExInput
          ref={searchInputRef}
          addonBefore={<FontAwesomeIcon icon={faSearch} />}
          placeholder="Search questions"
          value={searchQuery}
          onChange={setSearchQuery}
        />
        <DevExCard css={styles.promptsModal}>
          <Flex vertical className={'promptsModal__sections'}>
            <div>
              <PromptsModalMenuItem
                active={scope === Prompt.scope.CANONICAL && !topicSlug}
                onClick={() => {
                  setScope(Prompt.scope.CANONICAL);
                  setTopicSlug(undefined);
                }}
              >
                Template Questions
              </PromptsModalMenuItem>
            </div>

            <Flex vertical gap={theme.variable.spacing.sm} style={{ flex: 1, overflow: 'auto' }}>
              <div style={{ color: theme.color.text.tertiary }}>Template Questions by Topic</div>
              {topics?.map((topic) => (
                <PromptsModalMenuItem
                  key={topic.slug}
                  active={scope === Prompt.scope.CANONICAL && topicSlug === topic.slug}
                  onClick={() => {
                    setScope(Prompt.scope.CANONICAL);
                    setTopicSlug(topic.slug);
                  }}
                >
                  {topic.label}
                </PromptsModalMenuItem>
              ))}
            </Flex>

            <div>
              <PromptsModalMenuItem
                active={scope === Prompt.scope.CUSTOMER}
                onClick={() => setScope(Prompt.scope.CUSTOMER)}
              >
                Custom Questions
              </PromptsModalMenuItem>
            </div>
          </Flex>

          <Flex vertical className={'promptsModal__prompts'} gap={theme.variable.spacing.md}>
            <PromptsList
              scope={scope}
              topicSlug={scope === Prompt.scope.CANONICAL ? topicSlug : undefined}
              searchQuery={searchQuery}
            />

            {scope === Prompt.scope.CUSTOMER && (
              <Flex justify="center">
                <DevExButton
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  type="primary"
                  onClick={() => {
                    state.update({
                      promptSlugs: [...state.promptSlugs, 'UNSAVED'],
                      studyStep: { type: 'PROMPT', key: 'UNSAVED' },
                    });
                    modal.close(true);
                  }}
                >
                  Add custom question
                </DevExButton>
              </Flex>
            )}
          </Flex>
        </DevExCard>
      </Flex>
    </DevExModal>
  );
};

const PromptsModalMenuItem: React.FC<DevExMenuItemProps> = (props) => (
  <DevExMenuItem {...props}>
    <span>{props.children}</span>
    <FontAwesomeIcon icon={faChevronRight} />
  </DevExMenuItem>
);

const PromptsList: React.FC<{ scope: Prompt.scope; topicSlug?: string; searchQuery?: string }> = (
  props
) => {
  const theme = useDevExTheme();
  const state = useStudyBuilderState();
  const studyUpdater = useStudyUpdater(state.studyRef);

  const studySteps = useStudyBuilderSteps();
  const studyStepIndex = studySteps.findIndex((step) => isEqual(step, state.studyStep));

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);
  const { data: promptPoplarity } = useClientQuery(StudyLibraryClient.getPromptPopularity);

  const topicsBySlug = useMemo(() => (topics ? keyBy(topics, 'slug') : undefined), [topics]);
  const popularityByPrompt = useMemo(
    () => (promptPoplarity ? mapValues(keyBy(promptPoplarity, 'slug'), 'popularity') : undefined),
    [promptPoplarity]
  );

  // collect prompts based on CANONICAL or CUSTOMER scope
  const scopedPrompts = useMemo(() => {
    if (!prompts || !popularityByPrompt) {
      return [];
    }

    if (props.scope === Prompt.scope.CANONICAL) {
      return prompts
        .filter(
          (prompt) =>
            prompt.scope === Prompt.scope.CANONICAL &&
            // only show canonical prompts that have valid visibility
            prompt.visibility !== 'HIDDEN'
        )
        .sort((a, b) => (popularityByPrompt[b.slug] ?? 0) - (popularityByPrompt[a.slug] ?? 0));
    } else {
      return (
        prompts?.filter(
          (prompt) =>
            prompt.scope === Prompt.scope.CUSTOMER &&
            // only show customer prompts that are frozen or included in this study
            (prompt.frozen || state.promptSlugs.includes(prompt.slug))
        ) ?? []
      );
    }
  }, [prompts, popularityByPrompt, props.scope]);

  // filter prompts by topicSlug and searchQuery
  const filteredPrompts = useMemo(() => {
    return scopedPrompts.filter(
      (prompt) =>
        (!props.topicSlug || prompt.topicSlug === props.topicSlug) &&
        (!props.searchQuery || prompt.text.toLowerCase().includes(props.searchQuery.toLowerCase()))
    );
  }, [scopedPrompts, props.topicSlug, props.searchQuery]);

  if (!scopedPrompts.length) {
    return (
      <DevExEmpty
        icon={faMessageQuestion}
        label="You have not created any custom questions."
        style={{ paddingTop: theme.variable.spacing.xl }}
      />
    );
  }

  if (!filteredPrompts.length) {
    const scopeLabel = props.scope === Prompt.scope.CANONICAL ? 'template' : 'custom';
    const topicLabel = props.topicSlug && topicsBySlug?.[props.topicSlug].label;

    return (
      <DevExEmpty
        icon={faMagnifyingGlass}
        label={`No ${scopeLabel} questions${topicLabel ? ` for ${topicLabel}` : ''} match your search.`}
        style={{ paddingTop: theme.variable.spacing.xl }}
      />
    );
  }

  return (
    <Flex vertical gap={theme.variable.spacing.xs}>
      {filteredPrompts.map((prompt) => {
        const isActive = state.promptSlugs.includes(prompt.slug);
        return (
          <DevExMenuItem
            key={prompt.slug}
            active={isActive}
            onClick={() => {
              if (isActive) {
                // remove prompt
                const newPromptSlugs = state.promptSlugs.filter((slug) => slug !== prompt.slug);
                studyUpdater.update({ sequencedPrompts: newPromptSlugs });
                state.update({
                  promptSlugs: newPromptSlugs,
                });
                // if current step was removed, move to prior step
                if (isEqual(state.studyStep, { type: 'PROMPT', key: prompt.slug })) {
                  state.update({ studyStep: studySteps[studyStepIndex - 1] });
                }
              } else {
                // add prompt
                const newPromptSlugs = [...state.promptSlugs, prompt.slug];
                studyUpdater.update({ sequencedPrompts: newPromptSlugs });
                state.update({
                  promptSlugs: newPromptSlugs,
                  studyStep: { type: 'PROMPT', key: prompt.slug },
                });
              }
            }}
          >
            <Flex gap={theme.variable.spacing.sm} align="center">
              <PromptIcon type={prompt.type} style={{ fontSize: 18 }} />
              <span>{prompt.text}</span>
            </Flex>
            <Flex gap={theme.variable.spacing.sm} align="center">
              {!props.topicSlug && <DevExTag>{topicsBySlug?.[prompt.topicSlug]?.label}</DevExTag>}
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  visibility: !isActive ? 'hidden' : undefined,
                }}
              />
            </Flex>
          </DevExMenuItem>
        );
      })}
    </Flex>
  );
};
