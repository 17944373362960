import React, { useEffect, useMemo, useState } from 'react';
import { Col, Flex, Row, Tooltip } from 'antd';
import { faPlus, faWandMagicSparkles } from '@fortawesome/pro-regular-svg-icons';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { faCommentLines } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { Study, StudyClient, StudySequenceClient } from 'jf/api';
import { DevExPageLayout } from 'jf/common/DevExPageLayout';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExBadge } from 'jf/components/DevExBadge';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { DevExText } from 'jf/components/DevExText';
import { TopicSelect } from 'jf/pages/studyBuilder/customizeStudy/TopicSelect';
import { arrayOf } from 'jf/utils/arrayOf';
import { useClientFlags } from 'jf/utils/useClientFlags';
import { useClientPerms } from 'jf/utils/useClientPerms';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';
import { usePageStorage } from 'jf/utils/usePageStorage';

import { BenchmarkPercentile, BenchmarkSelect } from '../benchmark/BenchmarkSelect';
import { BenchmarkCell } from '../insights/BenchmarkCell';
import { TrendCell } from '../insights/TrendCell';
import { ScoreBlock } from '../ScoreBlock';
import { StarredTopicStar } from '../StarredTopicStar';
import { makeStudyAnalyzerPath } from '../StudyAnalyzerPage';
import { TeamCascader } from '../TeamCascader';

import { CommentCard } from './CommentCard';
import { PromptScoreCard } from './PromptScoreCard';
import { MetricTopicSlug, TopicMetrics } from './TopicMetrics';
import { useSortedTopicData } from './useSortedTopicData';

export const TopicsTab: React.FC = () => {
  const flags = useClientFlags();
  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef: string }>();
  const navigate = useNavigate();
  const theme = useDevExTheme();
  const queryClient = useQueryClient();
  const perms = useClientPerms();

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });
  const isClosed = study?.status === Study.status.CLOSED;

  const [topicSlug, setTopicSlug] = useState<string>();

  const { pageStateLoaded } = usePageStorage(
    { topic: [topicSlug, setTopicSlug] },
    'devex_survey_analyzer_topic'
  );

  const [benchmarkPercentile, setBenchmarkPercentile] = useState<BenchmarkPercentile>();

  const prioritizedTopicData = useSortedTopicData({
    studyRef,
    teamRef,
    benchmarkPercentile,
    sortBy: 'priority',
  });

  // default to highest prio topic
  useEffect(() => {
    if (pageStateLoaded && !topicSlug && prioritizedTopicData) {
      setTopicSlug(prioritizedTopicData[0].topic.slug);
    }
  }, [pageStateLoaded, topicSlug, prioritizedTopicData]);

  const topics = prioritizedTopicData?.map((topicData) => topicData.topic);

  const topicDatum = useMemo(() => {
    return prioritizedTopicData?.find((topicDatum) => topicDatum.topic.slug === topicSlug);
  }, [prioritizedTopicData, topicSlug]);

  const canShowMetrics = study && study.options.showMetrics;

  const hasGbacLimitedAccess = window.dx.user?.gbac.companyAccess === false;
  const showMetricCards =
    flags.metricCards && !!studyRef && topicSlug && !hasGbacLimitedAccess && canShowMetrics;

  const showBenchmarks = !!window.dx.user?.company.aggregatable && study && isClosed;

  const showStarredTopics = !!flags.actionItems && perms.role.customerAdmin && !!study?.sequenceRef;

  const createStarredTopic = useClientMutation(StudySequenceClient.createStarredTopic);

  const createActionItemMutation = useClientMutation(
    StudySequenceClient.createStarredTopicActionItem
  );

  const onAddActionItem = async (action: string) => {
    if (study?.sequenceRef && topicSlug) {
      trackEvent('survey-analyzer:topic:accept-action-item-recommendation', {
        topicSlug,
        text: action,
      });

      // create/activate StarredTopic
      await createStarredTopic.mutateAsync({
        sequenceRef: study.sequenceRef,
        requestBody: { topicSlug },
      });

      // create StarredTopicActionItem
      createActionItemMutation
        .mutateAsync({
          sequenceRef: study.sequenceRef!,
          topicSlug,
          requestBody: {
            text: action,
            isRecommendation: true,
          },
        })
        .then(() => {
          queryClient.invalidateQueries('GET_STARRED_TOPICS');
        });
    }
  };

  return (
    <DevExPageLayout>
      <Flex gap={theme.variable.spacing.sm}>
        {isClosed && (
          <TeamCascader
            studyRef={studyRef}
            value={teamRef}
            onChange={(teamRef) => navigate(makeStudyAnalyzerPath(studyRef, teamRef, 'topics'))}
            allowClear
          />
        )}
        {showBenchmarks && (
          <BenchmarkSelect value={benchmarkPercentile} onChange={setBenchmarkPercentile} />
        )}
        <TopicSelect
          topics={topics}
          value={topicSlug}
          onChange={setTopicSlug}
          placeholder={
            <>
              <FontAwesomeIcon icon={faFilter} />
              Topic
            </>
          }
          keepPlaceholder
        />
      </Flex>

      {topicDatum ? (
        <Flex
          align="center"
          gap={theme.variable.spacing.sm}
          style={{ fontSize: theme.variable.fontSize.lg, fontWeight: '600' }}
        >
          {showStarredTopics && !!topicSlug && (
            <StarredTopicStar
              sequenceRef={study?.sequenceRef}
              topicSlug={topicSlug}
              eventName="survey-analyzer:topic:star-topic"
            />
          )}
          {topicDatum.topic.label}
        </Flex>
      ) : (
        <DevExSkeleton fontSize="lg" width={160} noLineHeight />
      )}

      <Row gutter={parseInt(theme.variable.spacing.lg)}>
        <Col span={14}>
          <Flex vertical gap={theme.variable.spacing.lg}>
            <Flex vertical gap={theme.variable.spacing.sm}>
              <DevExText type="secondary" strong loading={!topicDatum}>
                Priority
              </DevExText>
              <Flex align="center" gap={theme.variable.spacing.md}>
                {topicDatum && (
                  <span style={{ fontSize: theme.variable.fontSize.lg, fontWeight: '600' }}>
                    #{topicDatum.priority}
                  </span>
                )}
                {topicDatum ? (
                  <div style={{ lineHeight: 'normal' }}>
                    <span style={{ fontWeight: '600' }}>
                      {topicDatum?.votes.length} respondent
                      {topicDatum?.votes.length !== 1 ? 's' : ''}{' '}
                    </span>
                    voted {topicDatum?.topic.label} a top priority when asked "What topics would you
                    like to see prioritized"{' '}
                  </div>
                ) : (
                  <DevExSkeleton width={['100%', '25%']} fontSize="sm" />
                )}
              </Flex>
            </Flex>

            <Flex vertical gap={theme.variable.spacing.sm}>
              <DevExText type="secondary" strong loading={!topicDatum}>
                Description
              </DevExText>
              {topicDatum ? (
                <div style={{ lineHeight: theme.variable.lineHeight }}>
                  {topicDatum.topic.longDescription}
                </div>
              ) : (
                <DevExSkeleton fontSize="sm" width={['100%', '100%', '50%']} />
              )}
            </Flex>

            {showMetricCards && (
              <TopicMetrics
                studyRef={studyRef}
                teamRef={teamRef}
                topicSlug={topicSlug as MetricTopicSlug}
              />
            )}

            <Flex vertical gap={theme.variable.spacing.sm}>
              <Flex gap={theme.variable.spacing.xs} align="baseline">
                <DevExText type="secondary" strong loading={!topicDatum}>
                  Survey Questions
                </DevExText>
                <DevExBadge count={topicDatum?.promptScores.length} />
              </Flex>

              <Flex vertical gap={theme.variable.spacing.md}>
                {topicDatum?.promptScores.map((score) => (
                  <PromptScoreCard key={score.slug} score={score} />
                )) ?? <DevExSkeleton height={268} />}
              </Flex>
            </Flex>
          </Flex>
        </Col>

        <Col span={10}>
          <Flex vertical gap={theme.variable.spacing.lg}>
            <Flex gap={theme.variable.spacing.md}>
              <Flex vertical gap={theme.variable.spacing.sm}>
                <DevExText type="secondary" strong loading={!topicDatum} loadingWidth={60}>
                  Score
                </DevExText>
                {topicDatum ? (
                  <ScoreBlock score={topicDatum.score.scaledAvg} size={32} />
                ) : (
                  <DevExSkeleton width={48} height={48} />
                )}
              </Flex>

              <Flex vertical gap={theme.variable.spacing.sm}>
                <DevExText type="secondary" strong loading={!topicDatum} loadingWidth={60}>
                  Trend
                </DevExText>
                {topicDatum && (
                  <Flex vertical justify="center" style={{ height: '100%' }}>
                    <TrendCell
                      trend={topicDatum.scoreTrend}
                      style={{ fontSize: theme.variable.fontSize.lg }}
                    />
                  </Flex>
                )}
              </Flex>

              <Flex vertical gap={theme.variable.spacing.sm}>
                <DevExText type="secondary" strong loading={!topicDatum} loadingWidth={80}>
                  Benchmark
                </DevExText>
                {topicDatum && (
                  <Flex vertical justify="center" style={{ height: '100%' }}>
                    <BenchmarkCell
                      benchmark={topicDatum.calculatedBenchmark}
                      style={{ fontSize: theme.variable.fontSize.lg }}
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>

            {topicDatum ? (
              <DevExCard
                style={{
                  background: theme.color.status.discovery.background,
                  borderColor: theme.color.status.discovery.border,
                }}
              >
                <Flex
                  style={{ color: theme.color.status.discovery.text, fontWeight: 600 }}
                  gap={theme.variable.spacing.sm}
                >
                  <FontAwesomeIcon icon={faWandMagicSparkles} />
                  Recommended Actions
                </Flex>
                <ul
                  style={{
                    margin: 0,
                    paddingInline: theme.variable.spacing.lg,
                    lineHeight: theme.variable.lineHeight,
                  }}
                >
                  {topicDatum.topic.actions.map((action, i) => (
                    <li key={i}>
                      <Flex justify="space-between" align="center" gap={theme.variable.spacing.sm}>
                        {action}
                        {showStarredTopics && (
                          <Tooltip title={`Add action item for ${topicDatum.topic.label}`}>
                            <DevExButton
                              icon={<FontAwesomeIcon icon={faPlus} />}
                              size="small"
                              type="text"
                              onClick={() => onAddActionItem(action)}
                              disabled={createActionItemMutation.isLoading}
                            />
                          </Tooltip>
                        )}
                      </Flex>
                    </li>
                  ))}
                </ul>
              </DevExCard>
            ) : (
              <DevExSkeleton height={150} />
            )}

            <Flex vertical gap={theme.variable.spacing.sm}>
              <Flex gap={theme.variable.spacing.xs} align="baseline">
                <DevExText type="secondary" strong loading={!topicDatum}>
                  Comments
                </DevExText>
                <DevExBadge count={topicDatum?.comments.length} />
              </Flex>
              <Flex vertical gap={theme.variable.spacing.md}>
                {topicDatum ? (
                  topicDatum?.comments.length ? (
                    topicDatum.comments.map((verbatim) => (
                      <CommentCard key={verbatim.ref} studyRef={studyRef} verbatim={verbatim} />
                    ))
                  ) : (
                    <DevExEmpty
                      icon={faCommentLines}
                      label="No respondents provided feedback when voting for this topic."
                    />
                  )
                ) : (
                  arrayOf(3).map((i) => <DevExSkeleton key={i} height={100} />)
                )}
              </Flex>
            </Flex>
          </Flex>
        </Col>
      </Row>
    </DevExPageLayout>
  );
};
