import React from 'react';
import { round } from 'lodash-es';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { NumericScore, Prompt } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExRechartsTooltipContent } from 'jf/components/DevExRechartsTooltipContent';
import { arrayOf } from 'jf/utils/arrayOf';

import { useFreqDistColors } from '../useFreqDistColors';

const LIKERT_LABELS = ['Strongly Disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly Agree'];
const NUMBER_LABELS = arrayOf(5).map((i) => (i + 1).toString());

type FreqDistHistogramProps = {
  prompt: Prompt;
  score: NumericScore;
};

export const FreqDistHistogram: React.FC<FreqDistHistogramProps> = (props) => {
  const theme = useDevExTheme();
  const freqDistColors = useFreqDistColors();

  const labels =
    props.prompt.type === Prompt.type.RATING_5L ? [...LIKERT_LABELS] : [...NUMBER_LABELS];
  if (props.prompt.polarity < 0) {
    labels.reverse();
  }

  const data = props.score.freqDist.map((frequency, i) => ({
    label: labels[i],
    frequency,
    color: freqDistColors[i],
  }));

  const borderRadius = parseInt(theme.variable.borderRadius);

  return (
    <ResponsiveContainer width="100%" height={160}>
      <BarChart data={data}>
        <CartesianGrid vertical={false} stroke={theme.color.visualization.grid} />
        <Tooltip
          cursor={{ fill: theme.color.background.hovered }}
          content={(tooltipProps) => (
            <DevExRechartsTooltipContent
              {...tooltipProps}
              formatValue={(value) =>
                `${value} responses (${round((value / props.score.n) * 100, 1)}%)`
              }
            />
          )}
        />
        <Bar dataKey="frequency" radius={[borderRadius, borderRadius, 0, 0]}>
          {data.map((datum, i) => (
            <Cell key={i} fill={datum.color} />
          ))}
        </Bar>
        <XAxis
          dataKey="label"
          axisLine={false}
          tickLine={false}
          tick={{ fill: theme.color.text.secondary, fontSize: theme.variable.fontSize.xxs }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fill: theme.color.text.secondary, fontSize: theme.variable.fontSize.xs }}
          allowDecimals={false}
          tickCount={4}
          width={parseInt(theme.variable.spacing.md)}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
