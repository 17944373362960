import React from 'react';
import { useQueryClient } from 'react-query';

import { trackEvent } from 'jf/analytics/Analytics';
import { StudySequenceClient } from 'jf/api';
import { DevExStar } from 'jf/components/DevExStar';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

type StarredTopicStarProps = {
  sequenceRef: string | undefined;
  topicSlug: string;
  eventName: string;
  className?: string;
};

export const StarredTopicStar: React.FC<StarredTopicStarProps> = (props) => {
  const queryClient = useQueryClient();

  const { data: starredTopics } = useClientQuery(StudySequenceClient.getStarredTopics, {
    sequenceRef: props.sequenceRef,
  });

  const isStarred = !!starredTopics?.find(({ topicSlug }) => topicSlug === props.topicSlug);

  const updateStarredTopic = useClientMutation(StudySequenceClient.updateStarredTopic);
  const createStarredTopic = useClientMutation(StudySequenceClient.createStarredTopic);

  const onToggleStarredTopic = (active: boolean) => {
    if (updateStarredTopic.isLoading || createStarredTopic.isLoading) {
      return;
    }

    trackEvent(props.eventName, { topicSlug: props.topicSlug, active });

    if (!active) {
      // deactivate existing StarredTopic
      updateStarredTopic
        .mutateAsync({
          sequenceRef: props.sequenceRef!,
          topicSlug: props.topicSlug,
          requestBody: { isActive: false },
        })
        .then(() => queryClient.invalidateQueries('GET_STARRED_TOPICS'));
    } else {
      // create/activate new StarredTopic
      createStarredTopic
        .mutateAsync({
          sequenceRef: props.sequenceRef!,
          requestBody: {
            topicSlug: props.topicSlug,
          },
        })
        .then(() => queryClient.invalidateQueries('GET_STARRED_TOPICS'));
    }
  };

  return (
    <DevExStar active={isStarred} onChange={onToggleStarredTopic} className={props.className} />
  );
};
