import React from 'react';
import { Flex } from 'antd';
import { faLineChart, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { Study, StudySequenceClient } from 'jf/api';
import { DevExPageHeader } from 'jf/common/DevExPageHeader';
import { DevExPageLayout } from 'jf/common/DevExPageLayout';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExFeedback } from 'jf/components/DevExFeedback';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { ReleaseTag } from 'jf/components/ReleaseTag';
import { arrayOf } from 'jf/utils/arrayOf';
import { useClientQuery } from 'jf/utils/useClientQuery';

import {
  SEQUENCED_STUDY_CARD_MIN_WIDTH,
  SequencedStudyCard,
} from '../studySequence/SequencedStudyCard';

import { AddStudyToSequenceModal } from './AddStudyToSequenceModal';
import { SequenceTeamCascader } from './SequenceTeamCascader';
import { TopicPriorityChart } from './TopicPriorityChart';
import { TopicScoreTrendChart } from './TopicScoreTrendChart';
import { useSequenceStudies } from './useSequenceStudies';

import DemoChartLight from 'jf/images/emptySequenceChart.svg';
import DemoChartDark from 'jf/images/emptySequenceChartDark.svg';

export const StudySequencePage = () => {
  const theme = useDevExTheme();
  const addStudyModal = useJFOverlay(AddStudyToSequenceModal);

  const { sequenceRef, teamRef } = useParams<{ sequenceRef: string; teamRef?: string }>();

  const { data: sequence } = useClientQuery(StudySequenceClient.getStudySequence, { sequenceRef });
  const sequenceStudies = useSequenceStudies(sequenceRef);

  const studyCount = sequenceStudies?.length;
  const closedStudyCount =
    sequenceStudies &&
    sequenceStudies.filter((study) => study.status === Study.status.CLOSED).length;
  const navigate = useNavigate();

  const getEmptyText = () => {
    if (!studyCount) {
      return 'Start adding surveys';
    } else if (!closedStudyCount) {
      return 'Add closed surveys';
    } else {
      return 'Continue your series';
    }
  };

  return (
    <Flex vertical>
      <DevExPageHeader
        title={
          sequence ? (
            <Flex align="center" gap={theme.variable.spacing.md}>
              {sequence.name}
              {!!sequenceRef && (
                <SequenceTeamCascader
                  sequenceRef={sequenceRef}
                  value={teamRef}
                  onChange={(teamRef) => {
                    const url = `/series/${sequenceRef}/${teamRef ? `team/${teamRef}/` : ''}`;
                    navigate(url);
                  }}
                  disabled={studyCount === 0}
                />
              )}
              <ReleaseTag stage="BETA" />
            </Flex>
          ) : undefined
        }
        extra={
          <>
            {!!studyCount && studyCount >= 2 && (
              <DevExFeedback
                prompt="What else would you like to see on this page?"
                placeholder="Share feedback about your series experience!"
                eventName={`series-analyzer:page-header:feedback`}
                eventContext={{ seriesRef: sequenceRef }}
              />
            )}
          </>
        }
      />

      <DevExPageLayout style={{ flex: 1 }}>
        {studyCount !== 0 && (
          <Flex gap={theme.variable.spacing.md}>
            {sequenceStudies && (
              <DevExButton
                style={{ height: '100%', paddingInline: theme.variable.spacing.md }}
                icon={<FontAwesomeIcon icon={faPlus} />}
                type="dashed"
                onClick={() => {
                  trackEvent('series-analyzer:add-survey-modal:open', { seriesRef: sequence!.ref });
                  addStudyModal.open({ sequenceRef: sequenceRef! });
                }}
                data-cy="add-survey-to-series-button"
              />
            )}
            <Flex vertical gap={theme.variable.spacing.sm} style={{ width: '100%' }}>
              <div
                css={css`
                  display: grid;
                  grid-template-columns: repeat(
                    auto-fill,
                    minmax(${SEQUENCED_STUDY_CARD_MIN_WIDTH}, 1fr)
                  );
                  gap: ${(props) => props.theme.variable.spacing.md};
                `}
              >
                {sequenceStudies?.map((study) => (
                  <SequencedStudyCard key={study.ref} study={study} teamRef={teamRef} />
                )) ?? arrayOf(4).map((i) => <DevExSkeleton key={i} height={125} />)}
              </div>
            </Flex>
          </Flex>
        )}

        {closedStudyCount === undefined || closedStudyCount >= 2 ? (
          <>
            <DevExCard title="How are topic scores trending?">
              <TopicScoreTrendChart sequenceRef={sequenceRef} teamRef={teamRef} />
            </DevExCard>
            <DevExCard title="How have priorities changed?">
              <TopicPriorityChart sequenceRef={sequenceRef} teamRef={teamRef} />
            </DevExCard>
          </>
        ) : (
          <DevExCard
            style={{ height: '100%', justifyContent: 'space-around', alignItems: 'center' }}
          >
            <Flex vertical gap={theme.variable.spacing.md} align="center">
              <div
                style={{
                  fontSize: theme.variable.fontSize.md,
                  lineHeight: theme.variable.lineHeight,
                  fontWeight: 600,
                  gap: theme.variable.spacing.md,
                }}
              >
                <FontAwesomeIcon
                  icon={faLineChart}
                  style={{ marginRight: theme.variable.spacing.sm }}
                />
                See how your developer sentiment is evolving
              </div>
              <div>
                {getEmptyText()} to see how your scores and priorities are evolving over time.
              </div>
              <DevExButton
                style={{ alignSelf: 'center' }}
                type="primary"
                icon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => addStudyModal.open({ sequenceRef: sequenceRef! })}
                data-cy="add-survey-to-series-button"
              >
                Survey
              </DevExButton>
            </Flex>
            {theme.key === 'dark' ? <DemoChartDark /> : <DemoChartLight />}
          </DevExCard>
        )}
      </DevExPageLayout>
    </Flex>
  );
};
