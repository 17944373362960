import { StudyVerbatimClient } from 'jf/api';
import { useClientQuery } from 'jf/utils/useClientQuery';

type UseStudyVerbatimsProps = {
  studyRef: string | undefined;
  teamRef?: string;
};

/**
 * Fetch study verbatims with optional `teamRef`.
 */
export const useStudyVerbatims = (props: UseStudyVerbatimsProps) => {
  const studyVerbatimsQuery = useClientQuery(
    StudyVerbatimClient.getTopicVerbatims,
    {
      studyRef: props.studyRef,
    },
    { enabled: !props.teamRef }
  );
  const studyVerbatimsForTeamQuery = useClientQuery(StudyVerbatimClient.getTopicVerbatimsForTeam, {
    studyRef: props.studyRef,
    teamRef: props.teamRef,
  });

  return props.teamRef ? studyVerbatimsForTeamQuery : studyVerbatimsQuery;
};
