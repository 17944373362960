import React, { useEffect, useState } from 'react';
import { CollapseProps, Flex } from 'antd';
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { UserClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCollapse } from 'jf/components/DevExCollapse';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExLogo } from 'jf/components/DevExLogo';
import { DevExRadioButtons } from 'jf/components/DevExRadioButtons';
import { DevExSteps } from 'jf/components/DevExSteps';
import { SimpleTransition } from 'jf/components/SimpleTransition';
import { useClientMutation } from 'jf/utils/useClientQuery';

const STEP_TIME_MS = 5000;

const styles = {
  page: css`
    flex: 1;
    padding: ${(props) => props.theme.variable.spacing.xxl};

    .page__header {
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};
      font-size: ${(props) => props.theme.variable.fontSize.xxl};
      text-align: center;
    }
  `,
  collapse: css`
    .ant-collapse-item {
      .ant-collapse-header-text {
        font-size: ${(props) => props.theme.variable.fontSize.xl};
      }

      :not(.ant-collapse-item-active) {
        .ant-collapse-header-text {
          color: ${(props) => props.theme.color.text.tertiary};
        }
      }
    }
  `,
  progressBar: css`
    background-color: ${(props) => props.theme.color.brand.default};
    height: 4px;
    margin-bottom: ${(props) => props.theme.variable.spacing.md};
  `,
};

const FormItem: React.FC<{ title: string }> = (props) => {
  const theme = useDevExTheme();

  return (
    <Flex vertical gap={theme.variable.spacing.md}>
      <div>{props.title}</div>
      {props.children}
    </Flex>
  );
};

type OnboardingStepComponentProps = {
  onBack?: () => void;
  onNext?: () => void;
  onLoad?: () => void;
  validated?: boolean;
  isLastStep?: boolean;
};

const OnboardingStepComponent: React.FC<OnboardingStepComponentProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <Flex vertical gap={theme.variable.spacing.xl} style={{ width: '100%' }}>
      {props.children}

      <Flex justify="end" gap={theme.variable.spacing.sm}>
        {props.onBack && (
          <DevExButton size="large" onClick={props.onBack}>
            Back
          </DevExButton>
        )}
        {props.onNext && (
          <DevExButton
            type="primary"
            size="large"
            onClick={props.onNext}
            disabled={!props.validated}
          >
            {props.isLastStep ? "Let's go!" : 'Next'}
          </DevExButton>
        )}
      </Flex>
    </Flex>
  );
};

const JOB_LEVEL_OPTIONS = [
  {
    value: 0,
    label: 'Founder',
  },
  {
    value: 1,
    label: 'Executive',
  },
  {
    value: 2,
    label: 'Vice President',
  },
  {
    value: 3,
    label: 'Senior Director',
  },
  {
    value: 4,
    label: 'Director',
  },
  {
    value: 5,
    label: 'Senior Manager',
  },
  {
    value: 6,
    label: 'Manager',
  },
  {
    value: 7,
    label: 'Individual Contributor',
  },
  {
    value: 8,
    label: 'Other',
  },
];

const COMPANY_SIZE_VALUES = ['1 - 149', '150 - 499', '500 - 999', '1000 - 4999', '5000+'];

const ISSUE_TRACKING_SOFTWARE_OPTIONS = [
  {
    value: 0,
    label: 'GitHub Issues',
  },
  {
    value: 1,
    label: 'ClickUp',
  },
  {
    value: 2,
    label: 'Shortcut',
  },
  {
    value: 3,
    label: 'GitLab',
  },
  {
    value: 4,
    label: 'Jira',
  },
  {
    value: 5,
    label: 'Azure Devops',
  },
  {
    value: 6,
    label: 'Trello',
  },
  {
    value: 7,
    label: 'Other',
  },
  {
    value: 8,
    label: "I don't know",
  },
];

const SOURCE_CODE_SOFTWARE_OPTIONS = [
  {
    value: 0,
    label: 'BitBucket',
  },
  {
    value: 1,
    label: 'GitHub',
  },
  {
    value: 2,
    label: 'Perforce',
  },
  {
    value: 3,
    label: 'GitLab',
  },
  {
    value: 4,
    label: 'Azure Devops',
  },
  {
    value: 5,
    label: 'Other',
  },
  {
    value: 6,
    label: "I don't know",
  },
];

type OnboardingStep = {
  key: string;
  label: string;
  Component: React.FC<OnboardingStepComponentProps>;
};

const ONBOARDING_STEPS: OnboardingStep[] = [
  {
    key: 'user',
    label: 'Tell us about yourself',
    Component: (props) => {
      const theme = useDevExTheme();

      // we load data from window.dx.user in case they reload step
      const initialName = (window.dx.user?.name ?? '').split(' ');
      const initialJobLevel = window.dx.user?.jobLevel;

      const [firstName, setFirstName] = useState<string | undefined>(initialName[0]);
      const [lastName, setLastName] = useState<string | undefined>(initialName.slice(1).join(' '));
      const [jobLevel, setJobLevel] = useState<number | undefined | null>(initialJobLevel);

      const validated = !!firstName && !!lastName && typeof jobLevel === 'number';

      const { mutateAsync: updateUser } = useClientMutation(UserClient.updateUser);

      const onNext = () => {
        trackEvent('free-inbound:onboarding:configure-user');

        if (validated) {
          updateUser({ requestBody: { name: `${firstName} ${lastName}`, jobLevel } }).then(
            (user) => {
              // rather than invalidating a request like normal, we can just update the window object
              window.dx.user = user;
            }
          );
        }

        props.onNext?.();
      };

      return (
        <OnboardingStepComponent {...props} onNext={onNext} validated={validated}>
          <FormItem title="What's your first and last name?">
            <Flex gap={theme.variable.spacing.sm}>
              <DevExInput
                placeholder="First name"
                size="large"
                value={firstName}
                onChange={setFirstName}
              />
              <DevExInput
                placeholder="Last name"
                size="large"
                value={lastName}
                onChange={setLastName}
              />
            </Flex>
          </FormItem>

          <FormItem title="What best describes your role?">
            <DevExRadioButtons
              items={JOB_LEVEL_OPTIONS.map(({ value, label }) => ({
                key: value.toString(),
                label: label,
              }))}
              onChange={(key) => setJobLevel(Number(key))}
              value={jobLevel?.toString()}
            />
          </FormItem>
        </OnboardingStepComponent>
      );
    },
  },
  {
    key: 'company',
    label: 'Tell us about your company',
    Component: (props) => {
      const [companyName, setCompanyName] = useState<string | undefined>(
        window.dx.user?.company.name
      );
      const [companySize, setCompanySize] = useState<string | undefined>(
        window.dx.user?.company.numberOfEmployees
      );
      const [companyIssueTrackingSw, setCompanyIssueTrackingSw] = useState<
        number | undefined | null
      >(window.dx.user?.company.issueTrackingSw);

      const [companySourceCodeSw, setCompanySourceCodeSw] = useState<number | undefined | null>(
        window.dx.user?.company.sourceCodeMgmtSw
      );

      const validated =
        !!companyName &&
        !!companySize &&
        typeof companyIssueTrackingSw === 'number' &&
        typeof companySourceCodeSw === 'number';

      const { mutateAsync: updateCompany } = useClientMutation(UserClient.updateCompany);

      const onNext = () => {
        trackEvent('free-inbound:onboarding:configure-company');

        if (validated) {
          updateCompany({
            requestBody: {
              name: companyName,
              numberOfEmployees: companySize,
              issueTrackingSw: companyIssueTrackingSw,
              sourceCodeMgmtSw: companySourceCodeSw,
            },
          }).then((company) => {
            if (window.dx.user) {
              // rather than invalidating a request like normal, we can just update the window object
              window.dx.user.company = company;
            }
          });
        }

        props.onNext?.();
      };

      return (
        <OnboardingStepComponent {...props} onNext={onNext} validated={validated}>
          <FormItem title="What's the name of your company?">
            <DevExInput
              placeholder="Company name"
              size="large"
              value={companyName}
              onChange={setCompanyName}
            />
          </FormItem>

          <FormItem title="How many employees does your company have?">
            <DevExRadioButtons
              items={COMPANY_SIZE_VALUES.map((value) => ({
                key: value,
                label: value,
              }))}
              onChange={(key: string) => setCompanySize(key)}
              value={companySize}
            />
          </FormItem>

          <FormItem title="What is your primary issue tracking software?">
            <DevExRadioButtons
              items={ISSUE_TRACKING_SOFTWARE_OPTIONS.map(({ value, label }) => ({
                key: value.toString(),
                label,
              }))}
              onChange={(key: string) => setCompanyIssueTrackingSw(Number(key))}
              value={companyIssueTrackingSw?.toString()}
            />
          </FormItem>

          <FormItem title="What is your primary source code management software?">
            <DevExRadioButtons
              items={SOURCE_CODE_SOFTWARE_OPTIONS.map(({ value, label }) => ({
                key: value.toString(),
                label,
              }))}
              onChange={(key: string) => setCompanySourceCodeSw(Number(key))}
              value={companySourceCodeSw?.toString()}
            />
          </FormItem>
        </OnboardingStepComponent>
      );
    },
  },
  {
    key: 'start',
    label: 'Get Started',
    Component: (props) => {
      const [progressBarWidth, setProgressBarWidth] = useState(0);
      const [currStep, setCurrStep] = useState(1);
      const [isFinished, setIsFinished] = useState(false);
      const theme = useDevExTheme();

      const stepLabels = [
        'Creating your first survey',
        'Sharing your survey',
        'Analyzing the results',
      ];

      const items: CollapseProps['items'] = stepLabels.map((label, i) => ({
        key: i + 1,
        label: `${i + 1}. ${label}`,
        children: (
          <div>
            <div style={{ width: `${progressBarWidth}%` }} css={styles.progressBar} />
            <img src={`/static/img/onboarding-step-${i + 1}-${theme.key}.svg`} alt="" />
          </div>
        ),
        showArrow: false,
      }));

      const onNext = () => {
        props.onNext?.();
        props.onLoad?.();
      };
      const onChange = (newKeys: string[]) => {
        setCurrStep(Number(newKeys[0]));
        setIsFinished(true);
      };

      useEffect(() => {
        let startTime: DOMHighResTimeStamp | null = null;
        const animate = (currTime: DOMHighResTimeStamp) => {
          if (!isFinished) {
            if (!startTime) {
              startTime = currTime;
            }
            const progress = currTime - startTime!;
            const newWidth = Math.min((progress / STEP_TIME_MS) * 100, 100);
            setProgressBarWidth(newWidth);

            if (newWidth < 100) {
              requestAnimationFrame(animate);
            } else {
              // This callback serves to ensure the currStep is accurate before setting with the new value
              setCurrStep((currStep) => {
                if (currStep < stepLabels.length) {
                  startTime = null;
                  return currStep + 1;
                }
                setIsFinished(true);
                return currStep;
              });
            }
          }
        };
        requestAnimationFrame(animate);
      }, [currStep]);
      return (
        <div>
          <OnboardingStepComponent {...props} onNext={onNext} validated={isFinished} isLastStep>
            <DevExCollapse
              activeKey={currStep}
              items={items}
              onChange={onChange}
              css={styles.collapse}
              accordion
              size="large"
              defaultActiveKey={items![0].key}
            />
          </OnboardingStepComponent>
        </div>
      );
    },
  },
];

export const OnboardingPage: React.FC = () => {
  const theme = useDevExTheme();
  const navigate = useNavigate();

  const [stepIndex, setStepIndex] = useState(0);
  const [lastAction, setLastAction] = useState<'next' | 'back'>();

  const onBack = () => {
    setStepIndex((i) => i - 1);
    setLastAction('back');
  };
  const onNext = () => {
    setStepIndex((i) => i + 1);
    setLastAction('next');
  };

  const onLoad = () => {
    if (window.dx.user?.isOnboarded && window.dx.user?.company.isOnboarded) {
      navigate('/');
    }
  };

  let onboardingSteps = ONBOARDING_STEPS;

  // filter out company configuration step if it is not needed
  if (window.dx.user?.company.isOnboarded) {
    onboardingSteps = onboardingSteps.filter((step) => step.key !== 'company');
  }

  return (
    <Flex vertical>
      <Flex style={{ paddingLeft: theme.variable.spacing.lg }}>
        <DevExLogo style={{ paddingBlock: theme.variable.spacing.md }} height="28px" />
      </Flex>
      <Flex vertical align="center" gap={theme.variable.spacing.xl} css={styles.page}>
        <div className="page__header">Welcome to Jellyfish DevEx</div>

        <DevExSteps labels={onboardingSteps.map((step) => step.label)} activeIndex={stepIndex} />

        {onboardingSteps.map(({ Component, key }, i) => (
          <SimpleTransition
            key={i}
            name="configuration-step"
            timeout={500}
            in={i === stepIndex}
            inDelay={500 + 50}
            cssInit={css`
              opacity: 1;
              transform: translateX(0);
            `}
            cssExit={css`
              opacity: 0;
              transform: translateX(${lastAction === 'next' ? '-200px' : '200px'});
            `}
            cssEnter={css`
              opacity: 0;
              transform: translateX(${lastAction === 'next' ? '200px' : '-200px'});
            `}
          >
            <div style={{ width: key === 'start' ? 680 : 400 }}>
              <Component onBack={i > 0 ? onBack : undefined} onNext={onNext} onLoad={onLoad} />
            </div>
          </SimpleTransition>
        ))}
      </Flex>
    </Flex>
  );
};
