import React, { useMemo } from 'react';
import { DefaultOptionType } from 'antd/es/select';

import { CanonicalTopic } from 'jf/api';
import { DevExSelect, DevExSelectProps } from 'jf/components/DevExSelect';

type TopicSelectProps = DevExSelectProps & {
  topics: CanonicalTopic[] | undefined;
};

export const TopicSelect: React.FC<TopicSelectProps> = (props) => {
  const { topics } = props;
  const options: DefaultOptionType[] = useMemo(() => {
    if (!topics) {
      return [];
    }

    return topics
      .map((topic) => ({
        label: topic.label,
        value: topic.slug,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [topics]);

  return (
    <DevExSelect
      {...props}
      options={options}
      placeholder={props.placeholder ?? 'Select topic'}
      showSearch
      keepPlaceholder={props.keepPlaceholder}
    />
  );
};
