import React, { useMemo, useState } from 'react';
import { Flex } from 'antd';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keyBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { Study, StudyClient, StudySequence } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExDash } from 'jf/components/DevExColumnDash';
import { DevExMenu } from 'jf/components/DevExMenu';
import { DevExPopover } from 'jf/components/DevExPopover';
import { DevExText } from 'jf/components/DevExText';
import { sortByISO } from 'jf/utils/sortByISO';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { DeleteStudySequenceModal } from './DeleteStudySequenceModal';
import { RenameStudySequenceModal } from './RenameStudySequenceModal';
import { StudyStatusBubble } from './StudyStatusBubble';

export const STUDY_SEQUENCE_CARD_MIN_WIDTH = '240px';
const MAX_STUDY_DISPLAY_COUNT = 5;

type StudySequenceCardProps = {
  sequence: StudySequence;
};

export const StudySequenceCard: React.FC<StudySequenceCardProps> = (props) => {
  const navigate = useNavigate();
  const theme = useDevExTheme();
  const renameSequenceModal = useJFOverlay(RenameStudySequenceModal);
  const deleteSequenceModal = useJFOverlay(DeleteStudySequenceModal);

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const { data: studies } = useClientQuery(StudyClient.getStudies);

  const sequenceStudies = useMemo(() => {
    if (!studies) {
      return [];
    }

    const studiesByRef = keyBy(studies, 'ref');

    return props.sequence.studyRefs
      .map((studyRef) => studiesByRef[studyRef])
      .filter((sequence) => !!sequence);
  }, [studies, props.sequence]);

  const closedStudies = sequenceStudies.filter((study) => study.status === Study.status.CLOSED);

  const dateFormat = 'LLL yyyy';

  const getDateString = () => {
    // if there are no closed surveys show nothing
    // if there is one show that close date
    // if there are more than one show oldest close date to latest close date
    if (closedStudies.length === 1) {
      const date = closedStudies[0].closeDate!;
      return DateTime.fromISO(date).toFormat(dateFormat);
    } else if (closedStudies.length > 1) {
      const sortedClosedStudies = sortByISO(closedStudies, 'closeDate');
      const newestStudy = sortedClosedStudies[sortedClosedStudies.length - 1];
      const oldestStudy = sortedClosedStudies[0];
      const startDate = DateTime.fromISO(oldestStudy.closeDate!).toFormat(dateFormat);
      const endDate = DateTime.fromISO(newestStudy.closeDate!).toFormat(dateFormat);
      return `${startDate} to ${endDate}`;
    }
    return '';
  };

  const bubbleStyles = (index: number) => css`
    box-shadow: 0 0 0 2px ${(props) => props.theme.color.card.background.default};
    z-index: ${props.sequence.studyRefs.length - index}; // stack earlier items on top

    &:not(:first-child) {
      margin-left: -${(props) => props.theme.variable.spacing.xs};
    }
  `;

  const hiddenSequenceStudies = sequenceStudies.slice(MAX_STUDY_DISPLAY_COUNT);

  return (
    <DevExCard
      onClick={() => {
        trackEvent('survey-list:series-tile:navigate', { seriesRef: props.sequence.ref });
        navigate(`/series/${props.sequence.ref}`);
      }}
      data-cy="study-sequence-card"
    >
      <Flex vertical justify="space-between" style={{ height: '125px' }}>
        <div
          style={{
            fontSize: theme.variable.fontSize.md,
            lineHeight: theme.variable.lineHeight,
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={props.sequence.name}
        >
          {props.sequence.name}
        </div>

        <Flex>
          {sequenceStudies.length > 0 ? (
            sequenceStudies
              .slice(0, MAX_STUDY_DISPLAY_COUNT)
              .map((study, i) => (
                <StudyStatusBubble key={study.ref} study={study} tooltip css={bubbleStyles(i)} />
              ))
          ) : (
            <DevExDash />
          )}

          {!!hiddenSequenceStudies.length && (
            <StudyStatusBubble
              study={hiddenSequenceStudies}
              tooltip
              css={bubbleStyles(sequenceStudies.length)}
            />
          )}
        </Flex>

        <Flex justify="space-between" align="center">
          <DevExText
            type="secondary"
            ellipsis
            style={{
              visibility: closedStudies.length > 0 ? 'initial' : 'hidden',
            }}
          >
            {getDateString()}
          </DevExText>

          <DevExPopover
            trigger={['click']}
            content={
              <DevExMenu
                items={[
                  {
                    key: 'rename',
                    label: 'Rename',
                    onClick: () => {
                      trackEvent('survey-list:series-actions:start-rename', {
                        seriesRef: props.sequence.ref,
                      });
                      renameSequenceModal.open({
                        sequence: props.sequence,
                      });
                      setIsMenuOpened(false);
                    },
                  },
                  {
                    type: 'divider',
                  },
                  {
                    key: 'delete',
                    label: 'Delete',
                    danger: true,
                    onClick: () => {
                      trackEvent('survey-list:series-actions:start-delete', {
                        seriesRef: props.sequence.ref,
                      });
                      deleteSequenceModal.open({
                        sequence: props.sequence,
                      });
                      setIsMenuOpened(false);
                    },
                  },
                ]}
                selectable={false}
              />
            }
            open={isMenuOpened}
            onOpenChange={setIsMenuOpened}
          >
            <DevExButton
              type="text"
              icon={<FontAwesomeIcon icon={faEllipsisV} />}
              onClick={(event) => {
                trackEvent('survey-list:series-actions:open', { seriesRef: props.sequence.ref });
                event.stopPropagation();
              }}
              data-cy="actions-button"
              // GBAC RESTRICTION
              // only customer admins can update/delete sequences
              adminRequired
            />
          </DevExPopover>
        </Flex>
      </Flex>
    </DevExCard>
  );
};
