import React, { useEffect, useMemo, useState } from 'react';
import { Flex } from 'antd';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce, sortBy } from 'lodash-es';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { SlackEntity, SlackTargetingClient, StudySlackTarget } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExLoader } from 'jf/components/DevExLoader';
import { DevExSelect, DevExSelectProps } from 'jf/components/DevExSelect';
import { DevExTag } from 'jf/components/DevExTag';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';
import { useLivePropState } from 'jf/utils/useLivePropState';

type SlackTargetEditorProps = {
  size?: DevExSelectProps['size'];
  slackTargets: StudySlackTarget[];
  onChange: (slackTargets: StudySlackTarget[]) => void;
  eventCategory: 'survey-analyzer' | 'survey-editor';
  preventDeletion?: boolean; // prevent deleting saved slack targets
};

export const SlackTargetEditor: React.FC<SlackTargetEditorProps> = (props) => {
  const theme = useDevExTheme();

  const [initialSlackTargets] = useState(props.slackTargets);
  const [slackTargets, setSlackTargets] = useLivePropState(props.slackTargets, props.onChange);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

  const { data: slackEntities, isFetching } = useClientQuery(
    SlackTargetingClient.getSlackEntities,
    { filter: debouncedSearchQuery },
    { enabled: debouncedSearchQuery.length >= 1 }
  );

  const { mutateAsync: expandSlackEntity, isLoading: isExpanding } = useClientMutation(
    SlackTargetingClient.expandSlackEntity
  );

  const debounceFiredSearchQuery = useMemo(() => debounce(setDebouncedSearchQuery, 300), []);

  useEffect(() => {
    if (searchQuery) {
      debounceFiredSearchQuery(searchQuery);
    } else {
      debounceFiredSearchQuery.cancel();
      setDebouncedSearchQuery('');
    }
  }, [searchQuery]);

  const isSearching = searchQuery !== debouncedSearchQuery || isFetching;

  const slackEntityOptions = useMemo(() => {
    return sortBy(slackEntities, (entity) => entity.name.toLowerCase()).map((entity) => ({
      value: entity.id,
      label: `${entity.type === SlackEntity.type.CHANNEL ? '#' : '@'}${entity.name}`,
    }));
  }, [slackEntities]);

  const sortedSlackTargets = sortBy(slackTargets, (target) => target.displayName?.toLowerCase());

  return (
    <Flex vertical gap={theme.variable.spacing.md}>
      <DevExSelect
        size={props.size}
        showSearch
        filterOption={() => true} // leave filtering to the BE only
        options={!isSearching ? slackEntityOptions : undefined}
        value={[]}
        onChange={(value) => {
          const entity = slackEntities?.find((entity) => entity.id === value);
          if (entity) {
            trackEvent(`${props.eventCategory}:target-respondent:add`, {
              id: entity.id,
              type: entity.type,
            });

            expandSlackEntity({
              requestBody: {
                entityId: entity.id,
                entityType: entity.type,
              },
            }).then((newSlackTargets) => {
              setSlackTargets((slackTargets) => {
                return [
                  ...slackTargets,
                  // filter out duplicates before adding
                  ...newSlackTargets.filter(
                    (newTarget) => !slackTargets.some((target) => target.id === newTarget.id)
                  ),
                ];
              });
            });
          }

          setSearchQuery('');
        }}
        placeholder="Search for @users, @groups, and #channels"
        loading={isSearching}
        notFoundContent={
          isSearching ? (
            <DevExLoader tip="Searching Slack..." />
          ) : (
            <DevExEmpty
              icon={faSlack}
              label={
                searchQuery
                  ? `No slack targets found matching "${searchQuery}".`
                  : 'Search for @users, @groups, and #channels in your Slack instance.'
              }
            />
          )
        }
        onSearch={setSearchQuery}
      />

      {(!!slackTargets.length || isExpanding) && (
        <Flex vertical gap={theme.variable.spacing.sm}>
          {
            <span
              style={{ color: theme.color.text.secondary, fontSize: theme.variable.fontSize.xs }}
            >
              {slackTargets.length} target{slackTargets.length === 1 ? '' : 's'}
            </span>
          }

          <Flex gap={theme.variable.spacing.sm} wrap="wrap" align="center">
            {sortedSlackTargets.map((target) => {
              const preventDeletion =
                props.preventDeletion && initialSlackTargets.some(({ id }) => id === target.id);
              return (
                <DevExTag
                  key={target.id}
                  color="purple"
                  css={css`
                    &&&&:has(button) {
                      padding-block: 0;
                      padding-right: ${theme.variable.spacing.xs};
                    }
                  `}
                >
                  <Flex align="center">
                    <div style={{ height: 16 }}>{target.displayName}</div>
                    {!preventDeletion && (
                      <DevExButton
                        icon={<FontAwesomeIcon icon={faXmark} />}
                        size="small"
                        type="text"
                        onClick={() => {
                          setSlackTargets((slackTargets) =>
                            slackTargets.filter(({ id }) => id !== target.id)
                          );
                          trackEvent(`${props.eventCategory}:target-user:remove`, {
                            id: target.id,
                          });
                        }}
                      />
                    )}
                  </Flex>
                </DevExTag>
              );
            })}
            {isExpanding && <DevExTag>Adding targets...</DevExTag>}
            <DevExButton
              size="small"
              type="text"
              onClick={() => setSlackTargets(props.preventDeletion ? initialSlackTargets : [])}
            >
              Clear all
            </DevExButton>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
