import { countBy, keyBy, orderBy } from 'lodash-es';

import { CanonicalTopic, NumericScore, TopicVerbatimWithTeamRef } from 'jf/api';

export const getTopicPriorityBySlug = (
  topics: CanonicalTopic[],
  topicScores: NumericScore[],
  topicVerbatims: TopicVerbatimWithTeamRef[]
) => {
  const topicBySlug = keyBy(topics, 'slug');
  const scoreBySlug = keyBy(topicScores, 'slug');
  const voteCountBySlug = countBy(topicVerbatims, 'topicSlug');

  const scoredTopics = topicScores.map((score) => topicBySlug[score.slug]);

  // sort topics by vote count, then score, then name
  const sortedTopics = orderBy(
    scoredTopics,
    [
      (topic) => voteCountBySlug[topic.slug] ?? 0,
      (topic) => scoreBySlug[topic.slug].scaledAvg,
      'name',
    ],
    ['desc', 'asc', 'asc']
  );

  const priorityBySlug = {};
  sortedTopics.forEach((topic, i) => (priorityBySlug[topic.slug] = i + 1));

  return priorityBySlug;
};
