import React, { useMemo } from 'react';
import { Flex, Tooltip } from 'antd';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBy, keyBy } from 'lodash-es';
import { useNavigate } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import {
  NumericScoreWithTeam,
  NumericScoreWithTeamOrSegment,
  StudyClient,
  StudyLibraryClient,
} from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { Heatmap } from 'jf/components/Heatmap';
import { arrayOf } from 'jf/utils/arrayOf';
import { useClientFlags } from 'jf/utils/useClientFlags';
import { useClientPerms } from 'jf/utils/useClientPerms';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { StarredTopicStar } from '../StarredTopicStar';
import { ANONYMITY_THRESHOLD, makeStudyAnalyzerPath } from '../StudyAnalyzerPage';
import { useScoreColors } from '../useScoreColors';
import { useStudyScores } from '../useStudyScores';
import { useStudyScoresBySegment } from '../useStudyScoresBySegment';

import { TopicScorePopover } from './TopicScorePopover';

const CELL_SIZE = 48;

const YLabel: React.FC<{
  value: string;
  count: number;
  countTooltip?: string;
}> = (props) => {
  const theme = useDevExTheme();

  return (
    <Flex
      align="center"
      justify="end"
      gap={theme.variable.spacing.sm}
      style={{
        height: CELL_SIZE,
        // unset highlight behavior
        width: '100%',
        color: theme.color.text.primary,
        cursor: 'default',
      }}
    >
      {props.value}

      {!!props.count && (
        <Tooltip title={props.countTooltip}>
          <Flex
            align="center"
            gap={theme.variable.spacing.xs}
            style={{ color: theme.color.text.secondary }}
          >
            {props.count}
            <FontAwesomeIcon icon={faUser} style={{ fontSize: theme.variable.fontSize.xs }} />
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
};

type SegmentScoreHeatmapProps = {
  studyRef: string | undefined;
  teamRef?: string;
  segmentKey: string | undefined;
};

export const SegmentScoreHeatmap: React.FC<SegmentScoreHeatmapProps> = (props) => {
  const flags = useClientFlags();
  const theme = useDevExTheme();
  const { colorByScore } = useScoreColors();
  const navigate = useNavigate();
  const perms = useClientPerms();

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: props.studyRef });

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const { data: topicScores } = useStudyScores({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    type: 'TOPIC',
  });
  const { data: topicScoresBySegment } = useStudyScoresBySegment({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    type: 'TOPIC',
  });

  const showStarredTopics = !!flags.actionItems && perms.role.customerAdmin && !!study?.sequenceRef;

  const [data, xLabels, yLabels, xKeys, yKeys] = useMemo(() => {
    if (!topics || !topicScores || !topicScoresBySegment || !props.segmentKey) {
      return [];
    }

    const topicsBySlug = keyBy(topics, 'slug');

    // sort topic scores from worst to best
    topicScores.sort((a, b) => a.scaledAvg - b.scaledAvg);

    // compile xLabels and xKeys from topics
    const xLabels = topicScores.map((score) => (
      <Flex key={score.slug} gap={theme.variable.spacing.xs}>
        {showStarredTopics && (
          <StarredTopicStar
            sequenceRef={study?.sequenceRef}
            topicSlug={score.slug}
            eventName="survey-analyzer:heatmap:star-topic"
          />
        )}
        {topicsBySlug[score.slug].label}
      </Flex>
    ));
    const xKeys = topicScores.map((score) => score.slug);

    // sort segment scores by topic order
    const findTopicIndex = (slug: string) => xKeys.findIndex((key) => key === slug);
    topicScoresBySegment.sort((a, b) => findTopicIndex(a.slug) - findTopicIndex(b.slug));

    let topicScoresBySegmentValue: { [segmentValue: string]: NumericScoreWithTeamOrSegment[] } = {};

    const segmentName = props.segmentKey.split(':')[1];
    if (props.segmentKey.startsWith('teamSegment')) {
      // group segment scores by team.value
      topicScoresBySegmentValue = groupBy(
        topicScoresBySegment.filter((score) => score.teamSegment?.name === segmentName),
        'teamSegment.value'
      );
    } else {
      // group segment scores by segment.value
      topicScoresBySegmentValue = groupBy(
        topicScoresBySegment.filter((score) => score.segment?.name === segmentName),
        'segment.value'
      );
    }

    // when filtering by teamRef, find the team's name (ex. "R&D", "Engineering")
    const teamName = (topicScores[0] as NumericScoreWithTeam).teamSegment?.value;

    // sort segment values
    const segmentValues = Object.keys(topicScoresBySegmentValue).sort();

    // compile yLabels and yKeys from segment values
    const yLabels = [
      <YLabel
        key="everyone"
        value={teamName ?? 'Everyone'}
        count={Math.max(...topicScores.map((score) => score.n))}
      />,
      ...segmentValues.map((value) => (
        <YLabel
          key={value}
          value={value}
          count={Math.max(...topicScoresBySegmentValue[value].map((score) => score.n))}
        />
      )),
    ];
    const yKeys = [
      undefined,
      ...segmentValues.map((value) => {
        const score = topicScoresBySegmentValue[value][0];
        return score.segment?.ref ?? score.teamSegment!.ref;
      }),
    ];

    // compile data rows
    const data: number[][] = [
      // add first row with overall data
      topicScores.map((score) => score.scaledAvg),
      // add row for each segment value
      ...segmentValues.map((value) => {
        // account for the possibility that a segment could be missing indiviudal topics due to anonymity threshold
        const scoreBySlug = keyBy(topicScoresBySegmentValue[value], 'slug');
        return xKeys.map((slug) => scoreBySlug[slug]?.scaledAvg ?? -1);
      }),
    ];

    return [data, xLabels, yLabels, xKeys, yKeys];
  }, [topics, topicScores, topicScoresBySegment, props.segmentKey]);

  if (!data || !xLabels || !yLabels || !xKeys || !yKeys) {
    return (
      <Flex vertical gap={theme.variable.spacing.xs}>
        {arrayOf(6).map((i) => (
          <Flex key={i} gap={theme.variable.spacing.xs}>
            {arrayOf(12).map((j) => (
              <DevExSkeleton key={j} height={CELL_SIZE} width={CELL_SIZE} />
            ))}
          </Flex>
        ))}
      </Flex>
    );
  }

  // get relevant variables based on the x, y coordinates of a cell
  const getCellVariables = (x: number, y: number | undefined) => {
    // x will always map to the selected topic
    const topicSlug = xKeys[x];

    // y will map to the selected segment (which may be a team)
    const yKey = y !== undefined ? yKeys[y] : undefined;

    const segmentRef = props.segmentKey?.startsWith('segment') ? yKey : undefined;

    // teamRef may come from the page-level filter or the selected segment
    let teamRef = props.teamRef;
    if (props.segmentKey?.startsWith('teamSegment') && yKey) {
      teamRef = yKey;
    }

    return {
      teamRef,
      topicSlug,
      segmentRef,
    };
  };

  return (
    <Heatmap
      data={data}
      xLabels={xLabels}
      yLabels={yLabels}
      cellSize={CELL_SIZE}
      onClick={([x, y]) => {
        if (x !== undefined) {
          // prevent clicks for null squares
          if (y !== undefined && data[y][x] < 0) {
            return;
          }

          const { topicSlug, segmentRef } = getCellVariables(x, y);

          trackEvent('survey-analyzer:heatmap:click', {
            surveyRef: props.studyRef,
            topicSlug: topicSlug,
            segmentKey: props.segmentKey,
            segmentValue: segmentRef,
          });

          if (y === undefined) {
            navigate(
              makeStudyAnalyzerPath(props.studyRef, props.teamRef, 'topics', { topic: topicSlug })
            );
          }
        }
      }}
      cellRender={(x, y) => {
        if (data[y][x] < 0) {
          return (
            <Tooltip
              title={`There were not enough responses for this topic to meet the anonymity threshold of ${ANONYMITY_THRESHOLD}.`}
            >
              —
            </Tooltip>
          );
        } else {
          const { teamRef, topicSlug, segmentRef } = getCellVariables(x, y);

          return (
            <TopicScorePopover
              studyRef={props.studyRef}
              teamRef={teamRef}
              topicSlug={topicSlug}
              segmentRef={segmentRef}
            >
              <Flex
                align="center"
                justify="center"
                style={{ width: '100%', height: '100%', cursor: 'default' }}
              >
                {data[y][x]}
              </Flex>
            </TopicScorePopover>
          );
        }
      }}
      cellStyle={(x, y) => ({
        color: theme.color.text.dark,
        backgroundColor: colorByScore(data[y][x]),
        borderRadius: theme.variable.spacing.xs,
        opacity: data[y][x] < 0 ? 0.5 : 1,
      })}
      rowStyle={(y, area) => ({
        gap: 2, // increase column gap
        marginBottom: y === 0 ? theme.variable.spacing.md : 1, // increase row gap
        minWidth: area === 'label' ? 80 : undefined, // use minWidth to keep heatmap in place between segments
      })}
      xLabelsPosition="top"
    />
  );
};
