import React from 'react';
import { Popover, PopoverProps } from 'antd';
import { createGlobalStyle } from 'styled-components';

const PopoverStyle = createGlobalStyle`
  .ant-popover.devex-popover {
    color: ${(props) => props.theme.color.text.primary};
    filter: drop-shadow(0 0 ${(props) => props.theme.variable.spacing.md} ${(props) => props.theme.color.shadow.default});
    line-height: 1;

    .ant-popover-arrow::before, .ant-popover-inner {
      background-color: ${(props) => props.theme.color.background.raised};
    }

    .ant-popover-arrow::after, .ant-popover-inner {
      box-shadow: 0 0 0 1px ${(props) => props.theme.color.border.primary}
    }

    .ant-popover-inner {
      padding: 0;

      .ant-popover-title, .ant-popover-inner-content {
        padding: ${(props) => props.theme.variable.spacing.md};
        color: ${(props) => props.theme.color.text.primary};
      }

      .ant-popover-title {
        margin: 0;
        padding-bottom: 0;
        font-size: ${(props) => props.theme.variable.fontSize.md};
      }
    }
  }
`;

interface DevExPopoverProps extends PopoverProps {}

export const DevExPopover: React.FC<DevExPopoverProps> = (props) => {
  return (
    <>
      <PopoverStyle />
      <Popover
        rootClassName="devex-popover"
        {...props}
        content={<div onClick={(event) => event.stopPropagation()}>{props.content}</div>}
      />
    </>
  );
};
