/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ForeignDevExUser } from './ForeignDevExUser';
import type { PromptSlug } from './PromptSlug';
import type { StudyAnalysisSegment } from './StudyAnalysisSegment';

/**
 * Study metadata with response overview.
 */
export type Study = {
    name: string;
    ref: string;
    lastStudyRef?: string;
    /**
     * * `DRAFT` - DRAFT
     * * `OPEN` - OPEN
     * * `CLOSED` - CLOSED
     * * `DELETED` - DELETED
     */
    status: Study.status;
    /**
     * * `D` - D
     * * `A` - A
     * * `O` - O
     */
    type: Study.type;
    /**
     * Notes on the objective of the study - to help contextualize the results
     */
    notes?: string;
    /**
     * An optional custom welcome message to display to the user when they start the study
     */
    welcomeMessage?: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    openDate?: string | null;
    closeDate?: string | null;
    companyName: string;
    flags: Array<string>;
    prompts: Array<PromptSlug>;
    analysisSegments: Array<StudyAnalysisSegment>;
    createdBy?: ForeignDevExUser;
    readonly lastUpdated: string;
    readonly options: Record<string, any>;
    sequenceRef?: string;
    readonly responseCount: number;
    /**
     * Configurable number of expected responses for a study. Null if it hasn't been configured yet.
     */
    targetResponses?: number | null;
    readonly sentAt: string | null;
};

export namespace Study {

    /**
     * * `DRAFT` - DRAFT
     * * `OPEN` - OPEN
     * * `CLOSED` - CLOSED
     * * `DELETED` - DELETED
     */
    export enum status {
        DRAFT = 'DRAFT',
        OPEN = 'OPEN',
        CLOSED = 'CLOSED',
        DELETED = 'DELETED',
    }

    /**
     * * `D` - D
     * * `A` - A
     * * `O` - O
     */
    export enum type {
        D = 'D',
        A = 'A',
        O = 'O',
    }


}

