import React from 'react';
import { Flex } from 'antd';
import { faArrowTurnDownRight, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { max, partition } from 'lodash-es';
import { css } from 'styled-components';

import { Team } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExButton } from 'jf/components/DevExButton';
import { useLivePropState } from 'jf/utils/useLivePropState';

import { ChildTeamCard } from './ChildTeamCard';
import { ParentTeamCard } from './ParentTeamCard';

type TeamsEditorProps = {
  teams: Team[];
  onChange: (teams: Team[]) => void;
  style?: React.CSSProperties;
};

export const TeamsEditor: React.FC<TeamsEditorProps> = (props) => {
  const theme = useDevExTheme();

  const [teams, setTeams] = useLivePropState(props.teams, props.onChange);

  const baseLevel = max(teams?.map((team) => team.level)) ?? 1;
  const onlyBaseTeams = baseLevel === 1;

  const onDeleteTeam = (deletedTeam: Team) => {
    if (deletedTeam.ref.startsWith('UNSAVED')) {
      // remove unsaved team
      setTeams(teams.filter((team) => team.ref !== deletedTeam.ref));
    } else {
      // toggle hidden status of EMP team
      setTeams(
        teams.map((team) =>
          team.ref === deletedTeam.ref ? { ...team, isHidden: !team.isHidden } : team
        )
      );
    }
  };

  const onAddTeam = (parentTeam: Team | undefined) => {
    // skip if unnamed team already exists under parent
    if (teams.find((team) => team.parentRef === parentTeam?.ref && !team.value)) {
      return;
    }

    const ref = `UNSAVED_${btoa(Math.random().toString()).substring(0, 24)}`;

    // add a team with no name, so that the user can name it
    setTeams((teams) => [
      ...teams,
      {
        value: '',
        ref,
        parentRef: parentTeam?.ref,
        level: baseLevel,
        levelName: '',
        isLeaf: true,
        isHidden: false,
        isEmp: false,
        isDeleted: false,
      },
    ]);
  };

  const onRenameTeam = (ref: string, name: string) => {
    const team = teams.find((team) => team.ref === ref);
    if (team) {
      team.value = name;
      setTeams([...teams]);
    }
  };

  // render teams recursively to create hierarchical structure
  const renderTeam = (team: Team) => {
    const childTeams = teams.filter((t) => t.parentRef === team.ref);

    if (!childTeams.length) {
      return <ChildTeamCard key={team.ref} team={team} onDelete={() => onDeleteTeam(team)} />;
    }

    let [baseChildTeams, nonBaseChildTeams] = partition(
      childTeams,
      (team) => team.level === baseLevel
    );

    // we currently only allow adding new base level teams next to ones that already exist
    const allowAdding = team.level === baseLevel - 1 && !team.isLeaf;

    return (
      <Flex vertical gap={theme.variable.spacing.sm} key={team.ref}>
        <ParentTeamCard team={team} onAdd={allowAdding ? () => onAddTeam(team) : undefined} />

        <Flex>
          <FontAwesomeIcon
            icon={faArrowTurnDownRight}
            style={{ padding: theme.variable.spacing.sm }}
          />

          <Flex vertical gap={theme.variable.spacing.sm} style={{ flexGrow: 1 }}>
            {!!baseChildTeams.length && renderChildTeams(team, baseChildTeams)}
            {nonBaseChildTeams.map(renderTeam)}
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderChildTeams = (parentTeam: Team | undefined, childTeams: Team[]) => {
    return (
      <div
        css={css`
          display: grid;
          grid-template-columns: ${childTeams.length ? '1fr 1fr' : '1fr'};
          gap: ${(props) => props.theme.variable.spacing.sm};
        `}
      >
        {childTeams.map((childTeam) => {
          return (
            <ChildTeamCard
              key={childTeam.ref}
              team={childTeam}
              style={{ width: 'auto' }}
              onDelete={() => onDeleteTeam(childTeam)}
              onAdd={() => onAddTeam(parentTeam)}
              onRename={(name) => onRenameTeam(childTeam.ref, name)}
            />
          );
        })}

        {
          // when there's no parent team (like in the case of non-EMP companies), provide team adding button in grid
          !parentTeam && !childTeams.some((team) => !team.value) && (
            <DevExButton
              type="dashed"
              icon={<FontAwesomeIcon icon={faPlus} />}
              style={{ height: 42, justifyContent: 'center' }}
              onClick={() => onAddTeam(parentTeam)}
            >
              Add team(s)
            </DevExButton>
          )
        }
      </div>
    );
  };

  return (
    <Flex vertical gap={theme.variable.spacing.sm} style={props.style}>
      {onlyBaseTeams
        ? renderChildTeams(undefined, teams)
        : teams.filter((team) => team.level === 1).map(renderTeam)}
    </Flex>
  );
};
