import { useMemo } from 'react';

import { Study, StudyClient } from 'jf/api';
import { sortByISO } from 'jf/utils/sortByISO';
import { useClientQuery } from 'jf/utils/useClientQuery';

export const useSequenceStudies = (sequenceRef: string | undefined, status?: Study.status) => {
  const { data: studies } = useClientQuery(StudyClient.getStudies);

  const sequenceStudies = studies?.filter((study) => study.sequenceRef === sequenceRef);

  const statusStudies = useMemo(
    () =>
      sequenceStudies &&
      status &&
      sortByISO(sequenceStudies.filter((study) => study.status === status) ?? [], 'closeDate'),
    [sequenceStudies, status]
  );
  return status && sequenceStudies ? statusStudies : sequenceStudies;
};
