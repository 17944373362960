import React, { useState } from 'react';
import { Flex } from 'antd';
import { faShieldHalved } from '@fortawesome/pro-thin-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';

import { Study, StudyClient } from 'jf/api';
import { DevExPageLayout } from 'jf/common/DevExPageLayout';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExSegmented, DevExSegmentedOption } from 'jf/components/DevExSegmented';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { AnonymityThresholdAlert } from '../AnonymityThresholdAlert';
import { BenchmarkPercentile, BenchmarkSelect } from '../benchmark/BenchmarkSelect';
import { makeStudyAnalyzerPath } from '../StudyAnalyzerPage';
import { TeamCascader } from '../TeamCascader';
import { useStudySegments } from '../useStudySegments';

import { SegmentScoreHeatmap } from './SegmentScoreHeatmap';
import { TopicScoreTable } from './TopicScoreTable';

export const InsightsTab: React.FC = () => {
  const navigate = useNavigate();
  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef: string }>();
  const theme = useDevExTheme();

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });

  const showTeams = study?.status === Study.status.CLOSED;
  const showBenchmarks = !!window.dx.user?.company.aggregatable;

  const [benchmarkPercentile, setBenchmarkPercentile] = useState<BenchmarkPercentile>();
  const [segmentKey, setSegmentKey] = useState<string>();

  const studySegments = useStudySegments({ studyRef, teamRef });

  let segmentOptions: DevExSegmentedOption[] = [];
  if (studySegments) {
    segmentOptions = studySegments.map((segment) => ({ value: segment.key, label: segment.name }));
  }

  return (
    <DevExPageLayout>
      <Flex gap={theme.variable.spacing.sm}>
        {showTeams && (
          <TeamCascader
            studyRef={studyRef}
            value={teamRef}
            onChange={(teamRef) => navigate(makeStudyAnalyzerPath(studyRef, teamRef, 'insights'))}
            allowClear
          />
        )}
        {showBenchmarks && (
          <BenchmarkSelect value={benchmarkPercentile} onChange={setBenchmarkPercentile} />
        )}
      </Flex>

      <DevExCard title="How are we doing across topics?" bodyStyle={{ overflowX: 'auto' }}>
        <TopicScoreTable
          studyRef={studyRef}
          teamRef={teamRef}
          benchmarkPercentile={benchmarkPercentile}
        />
      </DevExCard>

      {
        <DevExCard
          title="How do we differ across segments?"
          extra={
            !!segmentOptions.length && (
              <DevExSegmented
                options={segmentOptions}
                value={segmentKey}
                onChange={setSegmentKey}
              />
            )
          }
          bodyStyle={{ overflowX: 'auto' }}
        >
          {studySegments?.length === 0 ? (
            <DevExEmpty
              icon={faShieldHalved}
              label="No segments groups met the anonymity threshold."
            />
          ) : (
            <div style={{ padding: theme.variable.spacing.sm, margin: '0 auto' }}>
              <SegmentScoreHeatmap studyRef={studyRef} teamRef={teamRef} segmentKey={segmentKey} />
            </div>
          )}

          {!!studySegments && <AnonymityThresholdAlert />}
        </DevExCard>
      }
    </DevExPageLayout>
  );
};
