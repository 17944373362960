/**
 * Compute the width of a string in pixels given a font.
 */
export const computeTextWidth = (text: string, font: string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context) {
    context.font = font;
    return context.measureText(text).width;
  }

  return 0;
};

export const getComputedPropertyStyle = (element: HTMLElement, property: string) => {
  return window.getComputedStyle(element, null).getPropertyValue(property);
};

export const getElementFont = (element: HTMLElement) => {
  const fontWeight = getComputedPropertyStyle(element, 'font-weight') || 'normal';
  const fontSize = getComputedPropertyStyle(element, 'font-size') || '16px';
  const fontFamily = getComputedPropertyStyle(element, 'font-family') || 'Source Sans Pro';

  return `${fontWeight} ${fontSize} ${fontFamily}`;
};
