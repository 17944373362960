import React, { ReactNode, useRef } from 'react';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';

import { CenteredSticky } from './CenteredSticky';
import { GradientTransition } from './GradientTransition';
import { usePageIndexTracker } from './studyReveal';

const styles = {
  transition: css`
    height: 200vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.color.reveal.dark.background};

    .transition__header {
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};
      font-size: ${(props) => props.theme.variable.fontSize.xxl};
      color: ${(props) => props.theme.color.reveal.dark.text};
      font-weight: bold;
      line-height: ${(props) => props.theme.variable.lineHeight};
      text-align: center;
    }
  `,
};

type TransitionProps = {
  title: ReactNode;
  slug: string;
};

export const TransitionPage: React.FC<TransitionProps> = (props) => {
  const theme = useDevExTheme();
  const targetRef = useRef(null);

  usePageIndexTracker(targetRef, props.slug);

  return (
    <div ref={targetRef}>
      <div css={styles.transition}>
        <CenteredSticky className="transition__header">{props.title}</CenteredSticky>
      </div>
      <GradientTransition
        topColor={theme.color.reveal.dark.background}
        middleColor={theme.color.reveal.purple.main.background}
        bottomColor={theme.color.reveal.scores.background}
      />
    </div>
  );
};
