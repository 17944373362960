import React from 'react';
import { Flex } from 'antd';
import { css } from 'styled-components';

import { useDevExTheme } from './themes/DevExTheme';

const PAGE_LAYOUT_MAX_WIDTH = '1080px';

const styles = {
  pageLayout: css`
    width: 100%;
    padding: ${(props) => props.theme.variable.spacing.lg};

    .pageLayout__content {
      width: 100%;
      max-width: ${PAGE_LAYOUT_MAX_WIDTH};
    }
  `,
};

type DevExPageLayoutProps = {
  style?: React.CSSProperties;
  className?: string;
  maxWidth?: number; // default: 1080px
};

export const DevExPageLayout: React.FC<DevExPageLayoutProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <Flex justify="center" css={styles.pageLayout} style={props.style} className={props.className}>
      <Flex
        vertical
        gap={theme.variable.spacing.lg}
        className="pageLayout__content"
        style={{ maxWidth: props.maxWidth ?? PAGE_LAYOUT_MAX_WIDTH }}
      >
        {props.children}
      </Flex>
    </Flex>
  );
};
