import React, { useState } from 'react';
import { Flex } from 'antd';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { Study, StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExLink } from 'jf/components/DevExLink';
import { DevExTag } from 'jf/components/DevExTag';
import { useClientMutation } from 'jf/utils/useClientQuery';

import { useStudyBuilderState } from '../StudyBuilderState';
import { useStudyUpdater } from '../useStudyUpdater';

export const PublishStudyStep: React.FC = () => {
  const navigate = useNavigate();
  const theme = useDevExTheme();
  const queryClient = useQueryClient();
  const state = useStudyBuilderState();
  const studyUpdater = useStudyUpdater(state.studyRef);

  const [isPublishing, setIsPublishing] = useState(false);
  const [showTargets, setShowTargets] = useState(false);

  const { mutateAsync: updateStudy } = useClientMutation(StudyClient.updateStudy);

  const onBack = () => {
    state.update({ step: 'configure-teams' });
  };

  const onPublish = () => {
    if (state.studyRef) {
      setIsPublishing(true);
      trackEvent('survey-editor:publish:publish', { surveyRef: state.studyRef });
      updateStudy({
        studyRef: state.studyRef,
        requestBody: {
          status: Study.status.OPEN,
        },
      })
        .then(() => {
          queryClient.invalidateQueries('GET_STUDIES');
          queryClient.invalidateQueries(['GET_STUDY', state.studyRef]);
          queryClient.invalidateQueries('GET_PROMPTS'); // in case prompts were frozen
          queryClient.removeQueries(['GET_PUBLIC_STUDY', state.studyRef]);
          navigate(`/study/${state.studyRef}/analyze/participation`);
        })
        .catch(() => {
          setIsPublishing(false);
        });
    }
  };

  return (
    <Flex
      vertical
      style={{ fontSize: theme.variable.fontSize.md, lineHeight: theme.variable.lineHeight }}
      gap={theme.variable.spacing.lg}
    >
      <div style={{ fontSize: theme.variable.fontSize.xl }}>Publish and Review</div>

      <Flex vertical gap={theme.variable.spacing.sm}>
        Be sure to give your study a standout name!
        <DevExInput
          value={state.name}
          onChange={(value) => {
            state.update({ name: value });
            studyUpdater.update({ name: value });
          }}
          placeholder="Add name"
          error={!state.name}
          size="large"
          style={{ borderRadius: theme.variable.borderRadius }}
        />
      </Flex>

      <Flex vertical gap={theme.variable.spacing.sm}>
        Once your survey is published, you will no longer be able to make changes to it.{' '}
        {state.slackTargets.length
          ? `The survey will be sent individually to ${state.slackTargets.length} people in Slack.`
          : 'You will get a link to share with target respondents.'}
        {!!state.slackTargets.length && (
          <Flex vertical gap={theme.variable.spacing.sm}>
            <DevExLink
              onClick={() => setShowTargets(!showTargets)}
              style={{ fontSize: theme.variable.fontSize.sm, width: 'fit-content' }}
            >
              {showTargets ? 'Hide target respondents' : 'Show target respondents'}
            </DevExLink>
            {showTargets && (
              <Flex wrap="wrap" gap={theme.variable.spacing.sm}>
                {state.slackTargets.map((target) => (
                  <DevExTag key={target.id} color="purple">
                    {target.displayName}
                  </DevExTag>
                ))}
              </Flex>
            )}
          </Flex>
        )}
      </Flex>

      <div>Are you ready to publish?</div>

      <Flex justify="start" gap={theme.variable.spacing.sm}>
        <DevExButton
          type="primary"
          size="large"
          loading={isPublishing || state.updatingStudy}
          disabled={state.updatingStudy}
          onClick={onPublish}
        >
          {state.updatingStudy ? 'Saving' : isPublishing ? 'Publishing' : 'Yes, publish'}
        </DevExButton>
        <DevExButton size="large" disabled={isPublishing} onClick={onBack}>
          Not yet, take me back
        </DevExButton>
      </Flex>
    </Flex>
  );
};
