import React, { ReactNode, useState } from 'react';
import { Collapse, Flex } from 'antd';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronRight,
  faCircleExclamation,
  faCircleXmark,
  faGraduationCap,
  faLightbulb,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { opacify } from 'polished';
import styled from 'styled-components';

import { useDevExTheme } from '../common/themes/DevExTheme';

const Alert = styled.div<{ $border: string; $background: string }>`
  .ant-collapse {
    border-color: ${(props) => props.$border};
    overflow: hidden;

    .ant-collapse-item {
      border-color: inherit;

      .ant-collapse-header {
        color: ${(props) => props.theme.color.text.primary};
        background: ${(props) => props.$background};
        padding-inline: ${(props) => props.theme.variable.spacing.md};
        padding-block: ${(props) => props.theme.variable.spacing.sm};
        border-radius: 0;

        .ant-collapse-header-text {
          text-align: start;

          svg {
            font-size: ${(props) => props.theme.variable.fontSize.md};
          }
        }

        .ant-collapse-expand-icon {
          height: 24px;

          svg {
            font-size: ${(props) => props.theme.variable.fontSize.sm};
            transform: rotateZ(90deg);
            transition: transform 150ms ease;

            &.expandItem--active {
              transform: rotateZ(-90deg);
            }
          }
        }
      }

      .ant-collapse-content {
        border-color: inherit;
        background: ${(props) => `${opacify(-0.05, props.$background)}`};
        line-height: ${(props) => props.theme.variable.lineHeight};
        color: ${(props) => props.theme.color.text.primary};
        font-size: ${(props) => props.theme.variable.fontSize.sm};
      }
    }
  }
`;

export enum DevExExpandableAlertType {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  REVEAL = 'REVEAL',
}

type DevExExpandableAlertProps = {
  title: ReactNode;
  icon?: ReactNode;
  type?: DevExExpandableAlertType;
  style?: React.CSSProperties;
  className?: string;
  hover?: boolean;
};

export const DevExExpandableAlert: React.FC<DevExExpandableAlertProps> = (props) => {
  const theme = useDevExTheme();

  const type = props.type ?? DevExExpandableAlertType.INFO;

  const colorsByType: {
    [key in DevExExpandableAlertType]: { background: string; text: string; border: string };
  } = {
    INFO: theme.color.status.info,
    WARNING: theme.color.status.warning,
    ERROR: theme.color.status.error,
    REVEAL: theme.color.reveal.scores.expandable,
  };

  const iconByType: { [key in DevExExpandableAlertType]: IconProp } = {
    INFO: faLightbulb,
    WARNING: faCircleExclamation,
    ERROR: faCircleXmark,
    REVEAL: faGraduationCap,
  };

  const colors = colorsByType[type];
  const icon = iconByType[type];

  const [isOpen, setIsOpen] = useState(false);

  const isEmpty = !props.children;

  return (
    <Alert
      $border={colors.border}
      $background={colors.background}
      style={{ ...props.style, pointerEvents: isEmpty ? 'none' : undefined }}
      className={props.className}
      onMouseEnter={() => (props.hover ? setIsOpen(true) : undefined)}
      onMouseLeave={() => (props.hover ? setIsOpen(false) : undefined)}
      onClick={() => setIsOpen(!isOpen)}
    >
      <Collapse
        activeKey={isOpen ? 'alert' : ''}
        items={[
          {
            key: 'alert',
            label: (
              <Flex align="center" gap={theme.variable.spacing.sm} style={{ color: colors.text }}>
                {props.icon ?? <FontAwesomeIcon icon={icon} />}
                {props.title}
              </Flex>
            ),
            children: props.children,
          },
        ]}
        expandIcon={({ isActive }) =>
          isEmpty ? null : (
            <FontAwesomeIcon
              icon={faChevronRight}
              className={isActive ? 'expandItem--active' : undefined}
            />
          )
        }
        expandIconPosition="end"
      />
    </Alert>
  );
};
