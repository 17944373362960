import { StudyScoreClient } from 'jf/api';
import { useClientQuery } from 'jf/utils/useClientQuery';

type UseStudyScoresProps = {
  studyRef: string | undefined;
  teamRef?: string;
  type?: Parameters<typeof StudyScoreClient.getStudyScores>[0]['type'];
};

/**
 * Fetch study scores with optional `teamRef`.
 */
export const useStudyScores = (props: UseStudyScoresProps) => {
  const studyScoresQuery = useClientQuery(
    StudyScoreClient.getStudyScores,
    {
      studyRef: props.studyRef,
      type: props.type,
    },
    { enabled: !props.teamRef }
  );
  const studyScoresForTeamQuery = useClientQuery(StudyScoreClient.getStudyScoresForTeam, {
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    type: props.type,
  });

  return props.teamRef ? studyScoresForTeamQuery : studyScoresQuery;
};
