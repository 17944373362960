import React from 'react';
import { Flex, TooltipProps } from 'antd';

import { useDevExTheme } from '../common/themes/DevExTheme';

import { DevExButton } from './DevExButton';
import { DevExInput } from './DevExInput';
import { DevExPopover } from './DevExPopover';
import { DevExText } from './DevExText';

type CopyLinkPopoverProps = {
  title: string;
  description: string;
  link: string;
  placement?: TooltipProps['placement'];
  onCopy?: () => void;
};

export const CopyLinkPopover: React.FC<CopyLinkPopoverProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <DevExPopover
      content={
        <Flex vertical gap={theme.variable.spacing.sm} style={{ width: 420 }}>
          <Flex gap={theme.variable.spacing.sm}>
            <DevExInput value={props.link} />
            <DevExButton type="primary" copyValue={props.link} onClick={props.onCopy}>
              Copy
            </DevExButton>
          </Flex>
          <DevExText type="secondary" lined>
            {props.description}
          </DevExText>
        </Flex>
      }
      title={props.title}
      trigger="click"
      placement={props.placement}
    >
      {props.children}
    </DevExPopover>
  );
};
