import React from 'react';
import { Flex } from 'antd';

import { trackEvent } from 'jf/analytics/Analytics';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { StudiesTable, StudiesTableFilter } from 'jf/pages/studies/StudiesTable';

import { useStudyCreator } from '../useStudyCreator';

export const ClosedStudies: React.FC = () => {
  const theme = useDevExTheme();
  const studyCreator = useStudyCreator();

  return (
    <>
      <div style={{ fontSize: theme.variable.fontSize.lg }}>
        Select the past survey you'd like to use
      </div>
      <Flex vertical gap={theme.variable.spacing.md} style={{ width: '100%' }}>
        <StudiesTable
          filter={StudiesTableFilter.CLOSED}
          onRowClick={(study) => {
            trackEvent('survey-creator:past-surveys:clone', {
              ref: study.ref,
              name: study.name,
              closeDate: study.closeDate,
            });

            studyCreator.create(
              {
                name: `Duplicate of ${study.name}`,
                welcomeMessage: study.welcomeMessage,
                sequencedPrompts: study.prompts.map((prompt) => prompt.slug),
                flags: study.flags,
                sequenceRef: study.sequenceRef,
              },
              // create a new StudySequence if cloning a study that is not already in one
              !study.sequenceRef
                ? { name: `${study.name} Series`, studyRefs: [study.ref] }
                : undefined
            );
          }}
          columns={['name', 'status', 'last_updated', 'created_by']}
        />
      </Flex>
    </>
  );
};
