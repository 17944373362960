import React from 'react';
import { Flex, Grid } from 'antd';
import { faFrown, faLaugh, faMeh, faSmile, faTired } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExToggleButton } from 'jf/components/DevExToggleButton';
import { useLivePropState } from 'jf/utils/useLivePropState';

const { useBreakpoint } = Grid;

const LIKERT_SCORES = [1, 2, 3, 4, 5];
export const LIKERT_ICONS = [faTired, faFrown, faMeh, faSmile, faLaugh];
const LIKERT_LABELS = ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'];

interface LikertScaleProps {
  value?: number;
  onChange?: (score: number) => void;
  disabled?: boolean;
  numeric?: boolean;
  polarity: number;
}

export const LikertScale: React.FC<LikertScaleProps> = (props) => {
  const breakpoint = useBreakpoint();
  const theme = useDevExTheme();

  const [selectedScore, setSelectedScore] = useLivePropState(props.value, props.onChange);

  const negativePolarity = props.polarity < 0;

  const polarizeIndex = (index: number) => (negativePolarity ? 4 - index : index);

  return (
    <Flex vertical align="stretch" gap={theme.variable.spacing.sm}>
      <Flex gap="2.5%" style={{ height: 'min(72px, 14vw)' }}>
        {LIKERT_LABELS.map((label, i) => {
          const score = LIKERT_SCORES[polarizeIndex(i)];
          const icon = LIKERT_ICONS[polarizeIndex(i)];

          return (
            <DevExToggleButton
              key={score}
              toggled={score === selectedScore}
              onClick={() => setSelectedScore(score)}
              disabled={props.disabled}
            >
              {props.numeric ? (
                <>
                  {score}
                  {breakpoint.md && score === 1 && <span>Not at all</span>}
                  {breakpoint.md && score === 5 && <span>Very</span>}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={icon} />
                  {breakpoint.md && <span>{label}</span>}
                </>
              )}
            </DevExToggleButton>
          );
        })}
      </Flex>
      {!breakpoint.md && !props.numeric && (
        <Flex
          justify="space-between"
          style={{ fontSize: theme.variable.fontSize.sm, color: theme.color.text.secondary }}
        >
          <span style={{ order: negativePolarity ? 1 : 0 }}>{LIKERT_LABELS[polarizeIndex(0)]}</span>
          <span>{LIKERT_LABELS[polarizeIndex(4)]}</span>
        </Flex>
      )}
    </Flex>
  );
};
