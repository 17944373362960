import { useEffect, useState } from 'react';

/**
 * Since some elements (like iframe) do not natively support the onClick handler, this component infers a click event using hover and blur.
 * https://codesandbox.io/p/sandbox/react-detect-mouse-click-over-iframe-708ys
 */
export const useInferredClick = (onClick: () => void) => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (hovered) {
      window.addEventListener('blur', onClick);

      return () => {
        window.removeEventListener('blur', onClick);
      };
    }
  }, [hovered]);

  return {
    onMouseOver: () => {
      setHovered(true);
    },
    onMouseOut: () => {
      window.focus();
      setHovered(false);
    },
  };
};
