import React, { CSSProperties } from 'react';
import { Flex } from 'antd';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { css } from 'styled-components';

import { StudyScoreClient, TopicVerbatimWithTeamRef } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';
import { useClientQuery } from 'jf/utils/useClientQuery';

const styles = {
  verbatim: css`
    padding-block: ${(props) => props.theme.variable.spacing.sm};

    .verbatim__text {
      line-height: ${(props) => props.theme.variable.lineHeight};
      word-break: break-word;
      color: ${(props) => props.theme.color.text.primary};

      &.text--truncate {
        text-overflow: ellipsis;
        width: 400px;
        height: 75px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  `,
};

type DevExCommentProps = {
  studyRef: string | undefined;
  verbatim: TopicVerbatimWithTeamRef;
  style?: CSSProperties;
  truncate?: boolean;
};

export const CommentCard: React.FC<DevExCommentProps> = (props) => {
  const { data: scoredTeams } = useClientQuery(StudyScoreClient.getStudyScoredTeams, {
    studyRef: props.studyRef,
  });
  const theme = useDevExTheme();

  const team = scoredTeams?.find((team) => team.ref === props.verbatim.teamRef);
  const attributionLabel = team ? `${team.levelName} ${team.value}` : undefined;

  const textClassName = classNames('verbatim__text', { 'text--truncate': props.truncate });
  return (
    <DevExCard css={styles.verbatim}>
      <Flex vertical>
        <Flex style={{ color: theme.color.text.secondary }}>
          <FontAwesomeIcon icon={faQuoteLeft} />
        </Flex>
        <Flex>
          <span className={textClassName}>{props.verbatim.responseText}</span>
        </Flex>
        <Flex
          gap={theme.variable.spacing.sm}
          style={{ alignSelf: 'end', color: theme.color.text.secondary }}
        >
          {attributionLabel ? `— ${attributionLabel}` : ''}
          <FontAwesomeIcon icon={faQuoteRight} />
        </Flex>
      </Flex>
    </DevExCard>
  );
};
