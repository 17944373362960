import React, { useEffect, useMemo, useState } from 'react';
import { Flex, Grid } from 'antd';
import { delay, isEqual, sum } from 'lodash-es';
import { DateTime } from 'luxon';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { Prompt, PublicPrompt, PublicStudyClient } from 'jf/api';
import { ElementObserver } from 'jf/components/ElementObserver';
import { DEFAULT_TEAM_DEMOGRAPHIC_TEXT } from 'jf/pages/studyBuilder/customizeStudy/CustomizableDemographic';
import { DEFAULT_WELCOME_MESSAGE } from 'jf/pages/studyBuilder/customizeStudy/CustomizableIntroduction';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { QuestionCardDemographic } from './question/QuestionCardDemographic';
import { QuestionCardIntro } from './question/QuestionCardIntro';
import { QuestionCardPrompt } from './question/QuestionCardPrompt';
import { QuestionCardVerbatims, validateVerbatims } from './question/QuestionCardVerbatims';
import { STUDY_PROGRESS_SIDEBAR_WIDTH } from './StudyProgressSidebar';
import { getStudySteps, useStudyState } from './StudyStateContext';

const { useBreakpoint } = Grid;

const TRANSITION_DURATION = '250ms';

const styles = {
  questionCards: css`
    transition: transform ${TRANSITION_DURATION} ease;

    .questionCards__card {
      transition: all ${TRANSITION_DURATION} ease;

      &.card--inactive {
        pointer-events: none;
        opacity: 0.25;
        transform: scale(0.9);
        filter: blur(2.5px) grayscale(100%);
      }
    }
  `,
};

const PROMPT_GAP = 48;

export const StudyQuestions: React.FC = (props) => {
  const breakpoint = useBreakpoint();

  const studyState = useStudyState();
  const { data: study } = useClientQuery(PublicStudyClient.getPublicStudy, {
    studyRef: studyState.refs.studyRef,
  });
  const { data: studyResponse } = useClientQuery(PublicStudyClient.getStudyResponse, {
    studyResponseRef: studyState.refs.studyResponseRef,
  });
  const suggestedTeamRef = studyResponse?.teamRef;

  const stepIndex = studyState.stepIndex;

  const [questionCardHeights, setQuestionCardHeights] = useState<number[]>([]);

  // use question card heights to compute the correct positioning of the active question card
  const activeCardHeight = useMemo(
    () => questionCardHeights[stepIndex],
    [stepIndex, questionCardHeights]
  );
  const activeCardOffset = useMemo(
    () => sum(questionCardHeights?.slice(0, stepIndex)) + stepIndex * PROMPT_GAP,
    [stepIndex, questionCardHeights]
  );

  // track the last time the step was focused
  const [stepFocus, setStepFocus] = useState<DateTime>();
  // track the ms spent focused on the step before being blurred
  const [stepFocusMs, setStepFocusMs] = useState<number>(0);

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setStepFocus(DateTime.now());
    setStepFocusMs(0);
  }, [studyState.stageIndex, studyState.stepIndex]);

  useEffect(() => {
    const handleBlur = () => {
      setStepFocus((stepFocus) => {
        if (stepFocus) {
          // keep running count of ms spent focused before being blurred
          setStepFocusMs(
            (stepFocusMs) => stepFocusMs + DateTime.now().toMillis() - stepFocus.toMillis()
          );
        }

        // clear stepFocus when window has been blurred (this makes the logic indempotent)
        return undefined;
      });
    };

    const handleFocus = () => {
      // update stepFocus when window has been focused
      setStepFocus(DateTime.now());
    };

    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  const getResponseTimeMs = () => {
    let responseTimeMs = stepFocusMs;

    // there's no reason to believe stepFocus should ever be undefined here, but better safe than sorry!
    if (stepFocus) {
      responseTimeMs += DateTime.now().toMillis() - stepFocus.toMillis();
    }

    return responseTimeMs;
  };

  const onBack = () => {
    studyState.update({ stepIndex: studyState.stepIndex - 1 }); // go to last step
  };

  const onNext = () => {
    if (studyState.stepIndex === lastStepIndex) {
      trackEvent('survey-taker:last-question:submit', {
        surveyRef: studyState.refs.studyRef,
        surveyResponseRef: studyState.refs.studyResponseRef,
      });

      // delay end of survey so user can see progress hit 100%
      setSubmitting(true);
      delay(() => {
        studyState.update({
          savedEndTime: { endTime: DateTime.now().toISO(), saved: false },
          stageIndex: 2,
        });
      }, 1000);
    } else {
      studyState.update({ stepIndex: studyState.stepIndex + 1 }); // go to next step
    }
  };

  const onTeamSubmit = (teamRef: string) => {
    const teamRefUpdated = studyState.savedTeamRef?.teamRef !== teamRef;

    // update teamRef if it has change
    if (teamRefUpdated) {
      trackEvent('survey-taker:demographic-question:answer', {
        surveyRef: studyState.refs.studyRef,
        surveyResponseRef: studyState.refs.studyResponseRef,
        index: studyState.stepIndex,
        text: DEFAULT_TEAM_DEMOGRAPHIC_TEXT,
        suggestedValue: suggestedTeamRef,
        selectedValue: teamRef,
        suggestionAccepted: suggestedTeamRef ? teamRef === suggestedTeamRef : undefined,
      });

      studyState.update({
        savedTeamRef: {
          teamRef,
          saved: false,
        },
      });
    }
  };

  const onPromptSubmit = (prompt: PublicPrompt, value: number | string) => {
    const promptResponse = studyState.savedPromptResponses[prompt.slug];
    const currentValue = promptResponse?.absoluteValue ?? promptResponse?.responseText;
    const promptResponseUpdated = currentValue !== value;

    if (promptResponseUpdated) {
      const absoluteValue = typeof value === 'number' ? value : undefined;
      const responseText = typeof value === 'string' ? value : undefined;
      const responseTimeMs = getResponseTimeMs();

      trackEvent('survey-taker:score-question:answer', {
        surveyRef: studyState.refs.studyRef,
        surveyResponseRef: studyState.refs.studyResponseRef,
        index: studyState.stepIndex,
        prompt: {
          slug: prompt.slug,
          polarity: prompt.polarity,
        },
        absoluteValue,
        responseText,
        responseTimeMs,
      });

      const savedPromptResponses = { ...studyState.savedPromptResponses };
      savedPromptResponses[prompt.slug] = {
        absoluteValue,
        responseText,
        responseTimeMs,
        saved: false,
      };
      studyState.update({ savedPromptResponses });
    }
  };

  const onVerbatimsSubmit = (verbatims: { [topicSlug: string]: string }) => {
    const verbatimsUpdated = !isEqual(verbatims, studyState.savedVerbatims.verbatims);

    if (verbatimsUpdated) {
      const responseTimeMs = getResponseTimeMs();

      trackEvent('survey-taker:topic-voting:answer', {
        surveyRef: studyState.refs.studyRef,
        surveyResponseRef: studyState.refs.studyResponseRef,
        index: studyState.stepIndex,
        votes: verbatims,
        responseTimeMs,
      });

      studyState.update({
        savedVerbatims: {
          verbatims,
          responseTimeMs,
          saved: false,
        },
      });
    }
  };

  const studySteps = study ? getStudySteps(study) : [];

  const lastStepIndex = studySteps.length - 1;
  const numberedStepStartIndex = studySteps.findIndex((step) => step.numbered);

  const questionCards: JSX.Element[] = [];

  // create QuestionCard elements based on studySteps
  if (study) {
    for (let i = 0; i < studySteps.length; i++) {
      const step = studySteps[i];

      const disabled = i !== stepIndex;
      const nextLabel = i === lastStepIndex ? 'Submit' : 'Next';
      const number = i - numberedStepStartIndex + 1; // ex. "Question 1"

      switch (step.type) {
        case 'INTRO':
          questionCards.push(
            <QuestionCardIntro
              key={i}
              onNext={() => {
                trackEvent('survey-taker:introduction:next', {
                  surveyRef: studyState.refs.studyRef,
                  surveyResponseRef: studyState.refs.studyResponseRef,
                });
                onNext();
              }}
              questionCount={studySteps.filter((step) => step.numbered).length}
              title={<strong>{study?.name}</strong>}
              message={study?.welcomeMessage || DEFAULT_WELCOME_MESSAGE}
              disabled={disabled}
            />
          );
          break;
        case 'DEMOGRAPHIC':
          if (step.typeIndex === 0) {
            questionCards.push(
              <QuestionCardDemographic
                key={i}
                title={DEFAULT_TEAM_DEMOGRAPHIC_TEXT}
                placeholder={'Select team...'}
                options={study.teams.map((team) => ({ value: team.ref, label: team.value }))}
                value={studyState.savedTeamRef?.teamRef ?? suggestedTeamRef}
                onBack={(teamRef) => {
                  teamRef && onTeamSubmit(teamRef);
                  onBack();
                }}
                onNext={(teamRef) => {
                  teamRef && onTeamSubmit(teamRef);
                  onNext();
                }}
                disabled={disabled}
              />
            );
          }
          break;
        case 'PROMPT':
          const prompt = study.prompts[step.typeIndex!];
          const promptResponse = studyState.savedPromptResponses[prompt.slug];
          questionCards.push(
            <QuestionCardPrompt
              key={i}
              number={number}
              onBack={(value) => {
                value && onPromptSubmit(prompt, value);
                onBack();
              }}
              onNext={(value) => {
                value && onPromptSubmit(prompt, value);
                onNext();
              }}
              nextLabel={nextLabel}
              loading={i === lastStepIndex && submitting}
              prompt={prompt}
              tag={prompt.topicLabel}
              initialValue={promptResponse?.absoluteValue ?? promptResponse?.responseText}
              disabled={disabled}
            />
          );
          break;
        case 'VOTING':
          questionCards.push(
            <QuestionCardVerbatims
              key={i}
              number={number}
              onBack={(verbatims) => {
                validateVerbatims(verbatims) && onVerbatimsSubmit(verbatims);
                onBack();
              }}
              onNext={(verbatims) => {
                onVerbatimsSubmit(verbatims);
                onNext();
              }}
              nextLabel={nextLabel}
              loading={i === lastStepIndex && submitting}
              scoredPrompts={study.prompts
                .filter(
                  (prompt) =>
                    prompt.type === Prompt.type.RATING_5L || prompt.type === Prompt.type.RATING_5N
                )
                .map((prompt) => ({
                  ...prompt,
                  score: studyState.savedPromptResponses[prompt.slug]?.absoluteValue!,
                  topicLabel: prompt.topicLabel,
                }))}
              initialVerbatims={{ ...studyState.savedVerbatims.verbatims }}
              disabled={disabled}
            />
          );
          break;
      }
    }
  }

  return (
    <div
      style={{
        height: activeCardHeight,
        marginLeft: breakpoint.xl ? STUDY_PROGRESS_SIDEBAR_WIDTH / 2 : undefined,
        marginBlock: '5vh',
        width: '100%',
        transition: `height ${TRANSITION_DURATION} ease`,
      }}
    >
      <ElementObserver
        onChange={(_, initial) => {
          if (initial) {
            // set question card heights as a collective after initial render
            const questionCards = document.getElementById('questionCards');
            setQuestionCardHeights(
              Array.from(questionCards?.children ?? []).map((child) => child.clientHeight)
            );
          }
        }}
      >
        <Flex
          id="questionCards"
          vertical
          gap={PROMPT_GAP}
          css={styles.questionCards}
          style={{ transform: `translateY(-${activeCardOffset}px)` }}
        >
          {questionCards.map((card, index) => (
            <div
              key={card.key}
              className={`questionCards__card ${index !== stepIndex ? 'card--inactive' : ''}`}
            >
              <ElementObserver
                onChange={(el, initial) => {
                  if (!initial && el.height !== questionCardHeights[index]) {
                    // update new question card heights as needed
                    setQuestionCardHeights((questionCardHeights) => {
                      questionCardHeights[index] = el.height;
                      return [...questionCardHeights];
                    });
                  }
                }}
              >
                {card}
              </ElementObserver>
            </div>
          ))}
        </Flex>
      </ElementObserver>
    </div>
  );
};
