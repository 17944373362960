import React from 'react';
import { Flex } from 'antd';
import classNames from 'classnames';
import { css } from 'styled-components';

const styles = {
  item: css`
    cursor: pointer;
    padding: ${(props) => props.theme.variable.spacing.sm};
    border-radius: ${(props) => props.theme.variable.borderRadius};
    user-select: none;
    line-height: ${(props) => props.theme.variable.lineHeight};
    color: ${(props) => props.theme.color.text.secondary};
    width: 100%;

    &:hover {
      background-color: ${(props) => props.theme.color.background.hovered};
    }

    &.entry--active {
      background-color: ${(props) => props.theme.color.background.active};
      color: ${(props) => props.theme.color.text.primary};
    }

    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `,
};

export type DevExMenuItemProps = {
  id?: string;
  active?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
  className?: string;
};

export const DevExMenuItem: React.FC<DevExMenuItemProps> = (props) => {
  const className = classNames({
    'entry--active': props.active,
    ...(props.className ? { [props.className]: true } : {}),
  });

  return (
    <div
      id={props.id}
      css={styles.item}
      className={className}
      onClick={props.onClick}
      style={props.style}
    >
      <Flex justify="space-between" align="center">
        {props.children}
      </Flex>
    </div>
  );
};
