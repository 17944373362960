import React, { ReactNode } from 'react';
import { Flex } from 'antd';
import { MotionValue } from 'framer-motion';
import { useParams } from 'react-router-dom';

import { TopicVerbatimWithTeamRef } from 'jf/api/models/TopicVerbatimWithTeamRef';
import { DevExThemeProvider, useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';

import { CommentCard } from '../topicDrawer/CommentCard';

import { MotionDiv } from './MotionDiv';

type ScrollableCommentsProps = {
  scrollYProgress: MotionValue<number>;
  verbatims: TopicVerbatimWithTeamRef[];
  title: ReactNode;
  topicIndex: number;
};
const TopicTitle: React.FC<{ title: ReactNode }> = ({ title }) => {
  const theme = useDevExTheme();

  return <span style={{ fontWeight: 'bold', color: theme.color.reveal.accent }}>{title}</span>;
};

export const ScrollableComments: React.FC<ScrollableCommentsProps> = (props) => {
  const { studyRef } = useParams<{ studyRef: string }>();
  const theme = useDevExTheme();

  const titleVariants = {
    visible: { y: '0px', opacity: 1, transition: { duration: 0.05 } },
    hidden: { y: '120px', opacity: 0, transition: { duration: 0.05 } },
  };

  return (
    <Flex vertical>
      {(() => {
        const messages = {
          0: (
            <div>
              Some thoughts on <TopicTitle title={props.title} />
              {'   '}
              👀
            </div>
          ),
          1: (
            <div>
              People had lots to say about <TopicTitle title={props.title} />
              {'   '}
              🗣️
            </div>
          ),
          2: (
            <div>
              <TopicTitle title={props.title} /> generated a lot of discussion 🧐
            </div>
          ),
        };
        return (
          // comment title cards
          <MotionDiv
            variants={titleVariants}
            scrollYProgress={props.scrollYProgress}
            fadeIn={10 + props.topicIndex * 25}
            className="filteredScores__score"
            style={{
              paddingBottom: theme.variable.spacing.md,
            }}
          >
            <DevExCard
              style={{
                fontSize: theme.variable.fontSize.xl,
                textAlign: 'center',
                width: '900px',
                zIndex: '1100',
                backgroundColor: theme.color.reveal.purple.main.background,
                color: theme.color.reveal.purple.main.text,
                border: 'none',
                padding: '10px',
              }}
            >
              {messages[props.topicIndex]}
            </DevExCard>
          </MotionDiv>
        );
      })()}

      {/* comment cards */}
      <Flex gap={theme.variable.spacing.md} justify="center">
        {props.verbatims.map((comment, j) => (
          <MotionDiv
            variants={titleVariants}
            scrollYProgress={props.scrollYProgress}
            fadeIn={15 + props.topicIndex * 25}
            className="filteredScores__score"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            key={comment.ref}
          >
            <DevExThemeProvider themeKey="light">
              <CommentCard
                studyRef={studyRef}
                verbatim={{ ...comment }}
                style={{ border: 'none', textAlign: 'center' }}
                truncate
              />
            </DevExThemeProvider>
          </MotionDiv>
        ))}
      </Flex>
    </Flex>
  );
};
