import React, { useEffect, useRef, useState } from 'react';
import { InputRef } from 'antd';
import { useQueryClient } from 'react-query';

import { trackEvent } from 'jf/analytics/Analytics';
import { StudySequence, StudySequenceClient } from 'jf/api';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExModal } from 'jf/components/DevExModal';
import { useClientMutation } from 'jf/utils/useClientQuery';

type RenameStudySequenceModalProps = {
  sequence: StudySequence;
};

export const RenameStudySequenceModal: React.FC<RenameStudySequenceModalProps> = (props) => {
  const modal = useJFOverlay(RenameStudySequenceModal);
  const queryClient = useQueryClient();

  const inputRef = useRef<InputRef>(null);
  const [input, setInput] = useState(props.sequence.name);

  const { mutateAsync: updateStudySequence, isLoading: isUpdating } = useClientMutation(
    StudySequenceClient.updateStudySequence
  );

  const onCancel = () => {
    if (!isUpdating) {
      modal.close();
    }
  };

  const onOk = () => {
    trackEvent('survey-list:series-actions:rename', {
      seriesRef: props.sequence.ref,
    });
    updateStudySequence({
      sequenceRef: props.sequence.ref,
      requestBody: {
        name: input,
      },
    }).then(() => {
      queryClient.invalidateQueries('GET_STUDY_SEQUENCES');
      queryClient.invalidateQueries(['GET_STUDY_SEQUENCE', props.sequence.ref]);
      modal.close(true);
    });
  };

  // reset name after reopening modal
  useEffect(() => {
    if (modal.isOpened) {
      setInput(props.sequence.name);
      setTimeout(() => inputRef.current?.select(), 0);
    }
  }, [modal.isOpened, props.sequence.name]);

  return (
    <DevExModal
      open={modal.isOpened}
      onCancel={onCancel}
      onOk={onOk}
      title="Rename this series"
      footer={
        <>
          <DevExButton onClick={onCancel} disabled={isUpdating}>
            Cancel
          </DevExButton>
          <DevExButton
            type="primary"
            onClick={onOk}
            loading={isUpdating}
            disabled={input === props.sequence.name}
          >
            Save
          </DevExButton>
        </>
      }
    >
      <DevExInput
        ref={inputRef}
        value={input}
        onChange={setInput}
        placeholder="Series name"
        data-cy="rename-series-input"
      />
    </DevExModal>
  );
};
