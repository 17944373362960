import React from 'react';
import { Flex, Tooltip } from 'antd';
import { faEllipsisV, faWarning } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useQueryClient } from 'react-query';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { Study, StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExMenu } from 'jf/components/DevExMenu';
import { DevExPopover } from 'jf/components/DevExPopover';
import { useClientMutation } from 'jf/utils/useClientQuery';
import { useNavigateToStudy } from 'jf/utils/useNavigateToStudy';

import { StudyStatusBubble } from '../studies/StudyStatusBubble';
import { ANONYMITY_THRESHOLD } from '../studyAnalyzer/StudyAnalyzerPage';
import { useStudyParticipation } from '../studyAnalyzer/useStudyParticipation';

export const SEQUENCED_STUDY_CARD_MIN_WIDTH = '240px';

const styles = {
  sequencedStudyCard: css`
    .sequencedStudyCard__title {
      font-size: ${(props) => props.theme.variable.fontSize.md};
      line-height: ${(props) => props.theme.variable.lineHeight};
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .sequencedStudyCard__subtitle {
      color: ${(props) => props.theme.color.text.secondary};
    }

    .sequencedStudyCard__note {
      font-size: ${(props) => props.theme.variable.fontSize.xs};
      color: ${(props) => props.theme.color.text.secondary};
    }
  `,
};

type SequencedStudyCardProps = {
  study: Study;
  teamRef?: string;
};

export const SequencedStudyCard: React.FC<SequencedStudyCardProps> = (props) => {
  const navigateToStudy = useNavigateToStudy();
  const theme = useDevExTheme();
  const { mutateAsync: updateStudy } = useClientMutation(StudyClient.updateStudy);

  const { data: studyParticipation } = useStudyParticipation({
    teamRef: props.teamRef,
    studyRef: props.study.ref,
  });
  const isDraft = props.study.status === Study.status.DRAFT;

  const queryClient = useQueryClient();

  let studyDate = props.study.createdAt;
  let studyDateLabel = 'Created';
  if (props.study.status === Study.status.OPEN) {
    studyDate = props.study.openDate!;
    studyDateLabel = 'Opened';
  } else if (props.study.status === Study.status.CLOSED) {
    studyDate = props.study.closeDate!;
    studyDateLabel = 'Closed';
  }

  const responseCount = studyParticipation?.uniqueResponses;
  return (
    <DevExCard
      css={styles.sequencedStudyCard}
      onClick={() => {
        // We only want to pass the team ref if the team is included in the study (Assumption here if passes the threshold it is in the study)
        // This is avoid hitting a url that puts page in weird state where the team in url is not an option for the study
        const responseCount = studyParticipation?.uniqueResponses || 0;
        navigateToStudy(
          props.study,
          responseCount >= ANONYMITY_THRESHOLD ? props.teamRef : undefined
        );
      }}
      bodyStyle={{ justifyContent: 'space-between', height: '100%' }}
      data-cy="sequenced-study-card"
    >
      <Flex justify="space-between" gap={theme.variable.spacing.sm}>
        <Flex vertical gap={theme.variable.spacing.xs} style={{ overflow: 'hidden' }}>
          <div className="sequencedStudyCard__title">{props.study.name}</div>
          {!isDraft && (
            <Flex gap={theme.variable.spacing.sm}>
              <div className="sequencedStudyCard__subtitle">{responseCount} responses</div>
              {responseCount !== undefined && responseCount < ANONYMITY_THRESHOLD && (
                <Tooltip
                  title={
                    responseCount === 0
                      ? "This team hasn't participated in this survey."
                      : 'No detailed results available for this team as the number of respondents is below the anonymity threshold.'
                  }
                >
                  <FontAwesomeIcon
                    icon={faWarning}
                    style={{
                      color: theme.color.text.secondary,
                    }}
                  />
                </Tooltip>
              )}
            </Flex>
          )}
        </Flex>

        <StudyStatusBubble study={props.study} />
      </Flex>

      <Flex
        justify="space-between"
        align="center"
        style={{ marginBottom: `-${theme.variable.spacing.xs}` }}
      >
        <div className="sequencedStudyCard__note">
          {studyDateLabel} {DateTime.fromISO(studyDate).toLocaleString(DateTime.DATE_MED)}
        </div>
        <DevExPopover
          trigger={['click']}
          content={
            <DevExMenu
              items={[
                {
                  key: 'remove',
                  label: 'Remove from series',
                  onClick: () => {
                    trackEvent('series-analyzer:survey-actions:remove-from-series', {
                      seriesRef: props.study.sequenceRef,
                      surveyRef: props.study.ref,
                    });
                    const { ref } = props.study;
                    updateStudy({
                      studyRef: ref,
                      requestBody: {
                        sequenceRef: null,
                      },
                    }).then(() => {
                      queryClient.invalidateQueries('GET_STUDIES');
                      queryClient.removeQueries(['GET_STUDY', ref]);
                      queryClient.invalidateQueries('GET_STUDY_SEQUENCES');
                    });
                  },
                },
              ]}
              selectable={false}
            />
          }
          placement="bottom"
        >
          <DevExButton
            type="text"
            icon={<FontAwesomeIcon icon={faEllipsisV} />}
            onClick={(event) => {
              trackEvent('series-analyzer:survey-actions:open', {
                seriesRef: props.study.sequenceRef,
                surveyRef: props.study.ref,
              });
              event.stopPropagation();
            }}
            // GBAC RESTRICTION
            // only customer admins can update studies
            adminRequired
          />
        </DevExPopover>
      </Flex>
    </DevExCard>
  );
};
