import React from 'react';
import { Flex } from 'antd';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExExpandableAlert, DevExExpandableAlertType } from 'jf/components/DevExExpandableAlert';
import { DevExLink } from 'jf/components/DevExLink';
import { DevExLogo } from 'jf/components/DevExLogo';
import { DevExTag } from 'jf/components/DevExTag';
import { FlatTentacles } from 'jf/components/FlatTentacles';

import { onLoginWithJellyfish } from './SignInForm';

import DevExHeroImage from 'jf/images/devex-hero.png';

const styles = {
  page: css`
    flex: 1;
    padding: ${(props) => props.theme.variable.spacing.xxl};

    .page__header {
      margin-bottom: ${(props) => props.theme.variable.spacing.lg};

      .header__title {
        font-size: ${(props) => props.theme.variable.fontSize.xxl};
        font-family: ${(props) => props.theme.variable.fontFamily.secondary};
        font-weight: bold;
      }

      .header__alert {
        width: 480px;

        .ant-tag {
          border-radius: ${(props) => props.theme.variable.spacing.xs};
          padding: 2px;
        }
      }
    }
  `,
};

export const AuthAccessPage: React.FC = () => {
  const theme = useDevExTheme();

  return (
    <Flex vertical align="center" gap={theme.variable.fontSize.xxl} css={styles.page}>
      <FlatTentacles placement="topLeft" />

      <Flex vertical align="center" gap={theme.variable.spacing.md} className="page__header">
        <DevExLogo style={{ paddingBottom: theme.variable.spacing.lg }} />

        <DevExExpandableAlert
          title="It looks like you haven’t been granted access to DevEx yet."
          type={DevExExpandableAlertType.WARNING}
          className="header__alert"
        >
          Want to access your team’s developer experience? Ask your Jellyfish Admin to grant you the{' '}
          <DevExTag color="blue">DevEx Viewer</DevExTag> or{' '}
          <DevExTag color="red">DevEx Admin</DevExTag> role!
          <br />
          <br />
          Already been granted access?{' '}
          <DevExLink onClick={() => onLoginWithJellyfish()}>Refresh permissions</DevExLink>
        </DevExExpandableAlert>
      </Flex>

      <Flex wrap="wrap" align="center" justify="center" gap={theme.variable.spacing.xl}>
        <Flex vertical gap={theme.variable.spacing.lg} style={{ maxWidth: 620 }}>
          <div
            style={{
              fontSize: theme.variable.fontSize.xxl,
              fontWeight: 600,
              fontFamily: theme.variable.fontFamily.secondary,
              lineHeight: 1.2,
            }}
          >
            Improving <span style={{ color: theme.color.brand.logo }}>Developer Experience</span>{' '}
            just got easier
          </div>

          <div
            style={{ fontSize: theme.variable.fontSize.lg, lineHeight: theme.variable.lineHeight }}
          >
            With Jellyfish DevEx, you can quickly set up and distribute surveys that elevate
            engineers’ perspectives. We combine these qualitative insights with quantitative data to
            get you the complete picture of the health of your engineering org and track your
            improvements.
          </div>
        </Flex>

        <img src={DevExHeroImage} alt="" style={{ width: 580, height: 'fit-content', zIndex: 1 }} />
      </Flex>
    </Flex>
  );
};
