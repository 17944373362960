import React from 'react';
import { Flex } from 'antd';
import { css } from 'styled-components';

const styles = {
  jsonBlock: css`
    background: ${(props) => props.theme.color.background.default};
    padding-block: ${(props) => props.theme.variable.spacing.md};

    .jsonBlock__lines {
      color: ${(props) => props.theme.color.text.disabled};
      padding-inline: ${(props) => props.theme.variable.spacing.lg};
      margin: 0;
    }

    .jsonBlock__content {
      flex: 1;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
};

interface JSONBlockProps {
  object: Object[] | Object | undefined;
  truncate?: number;
}

export const JSONBlock: React.FC<JSONBlockProps> = (props) => {
  let jsonContent = JSON.stringify(props.object, null, 4);

  if (props.truncate && Array.isArray(props.object) && props.object.length > props.truncate) {
    // truncate array of objects
    const truncatedJsonContent = JSON.stringify(props.object.slice(0, props.truncate), null, 4);

    // add ellipsis for truncated content
    const truncatedLines = truncatedJsonContent.split('\n');
    truncatedLines.splice(-2, 1, '    },', '    ...');

    jsonContent = truncatedLines.join('\n');
  }

  return (
    <Flex css={styles.jsonBlock}>
      <pre className="jsonBlock__lines">
        {jsonContent.split('\n').map((_, i) => (
          <React.Fragment key={i}>
            {i + 1}
            <br />
          </React.Fragment>
        ))}
      </pre>
      <pre className="jsonBlock__content">{jsonContent}</pre>
    </Flex>
  );
};
