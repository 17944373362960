import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib';
import { css } from 'styled-components';

const CHECKBOX_SIZE = 18;

const checkboxSizeFactor = CHECKBOX_SIZE / 16; // compute scale relative to original size

const styles = {
  checkbox: css`
    .ant-checkbox-wrapper {
      &:focus-within,
      &:hover {
        .ant-checkbox .ant-checkbox-inner {
          box-shadow: 0 0 0 1px ${(props) => props.theme.color.border.hovered};
          outline: none !important;
          border-color: ${(props) => props.theme.color.border.hovered};
        }
      }

      .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${(props) => props.theme.color.border.active} !important;
        border-color: ${(props) => props.theme.color.border.active} !important;
      }

      &.ant-checkbox-wrapper-disabled {
        opacity: ${(props) => props.theme.variable.opacity.disabled};
        cursor: default;
        pointer-events: none;
      }

      .ant-checkbox .ant-checkbox-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${CHECKBOX_SIZE}px;
        height: ${CHECKBOX_SIZE}px;
        background-color: ${(props) => props.theme.color.background.raised};
        border-color: ${(props) => props.theme.color.border.primary};

        // transform checkmark to match new checkbox size
        :after {
          transform: scale(${checkboxSizeFactor}) rotate(45deg);
          position: relative;
          top: -6%;
          inset-inline-start: 0;
          border-color: ${(props) => props.theme.color.text.contrast};
        }
      }
    }
  `,
};

interface DevExInputProps extends Omit<CheckboxProps, 'onChange'> {
  onChange?: (checked: boolean) => void;
}

export const DevExCheckbox: React.FC<DevExInputProps> = (props) => {
  return (
    <div css={styles.checkbox}>
      <Checkbox
        {...props}
        onChange={(event) => props.onChange?.(event.target.checked)}
        onKeyDown={(event: any) => event.key === 'Enter' && props.onChange?.(!event.target.checked)}
      />
    </div>
  );
};
