import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';
import Markdown from 'react-markdown';
import { css } from 'styled-components';

import { DevExHeader } from 'jf/common/DevExHeader';
import { DevExPageLayout } from 'jf/common/DevExPageLayout';
import { DevExPageTitle } from 'jf/common/DevExPageTitle';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExText } from 'jf/components/DevExText';

import termsMd from './terms.md';

const styles = {
  terms: css`
    line-height: ${(props) => props.theme.variable.lineHeight};

    p,
    li {
      margin-bottom: ${(props) => props.theme.variable.spacing.md};
    }

    h2 {
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};
    }
  `,
};

export const TermsPage: React.FC = () => {
  const theme = useDevExTheme();

  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch(termsMd)
      .then((response) => response.text())
      .then(setMarkdown);
  }, []);

  return (
    <Flex vertical style={{ flex: 1 }} align="center">
      <DevExPageTitle>Terms of Service</DevExPageTitle>
      <DevExHeader>
        <Flex vertical align="center" style={{ lineHeight: theme.variable.lineHeight }}>
          Terms of Service
          <DevExText type="secondary" fontSize="xs">
            Last revised: July 3nd, 2024
          </DevExText>
        </Flex>
      </DevExHeader>

      <DevExPageLayout>
        <Markdown css={styles.terms}>{markdown}</Markdown>
      </DevExPageLayout>
    </Flex>
  );
};
