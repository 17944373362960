/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExpandSlackEntityRequest } from '../models/ExpandSlackEntityRequest';
import type { SlackEntity } from '../models/SlackEntity';
import type { SlackStatus } from '../models/SlackStatus';
import type { StudySlackTarget } from '../models/StudySlackTarget';
import type { StudySlackTargetRequest } from '../models/StudySlackTargetRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SlackTargetingClient {

    /**
     * Return Slack entities (users, channels, groups) from the Slack API.
     * @returns SlackEntity
     * @throws ApiError
     */
    public static getSlackEntities({
        filter,
    }: {
        /**
         * A case-insensitive search parameter for a user, group, or channel
         */
        filter: string,
    }): CancelablePromise<Array<SlackEntity>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/slack/entities',
            query: {
                'filter': filter,
            },
        });
    }

    /**
     * Expand submitted Slack entities into their component users
     * if any exist.
     *
     * * Channels and group IDs will result in a list of user IDs
     * being returned.
     * * User IDs will result in the same user ID being returned.
     * (Mainly for usage summary)
     * @returns StudySlackTarget
     * @throws ApiError
     */
    public static expandSlackEntity({
        requestBody,
    }: {
        requestBody: ExpandSlackEntityRequest,
    }): CancelablePromise<Array<StudySlackTarget>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/slack/entities/expand',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Also note that Slack has a status API:
     * https://api.slack.com/apis/slack-status
     * @returns SlackStatus
     * @throws ApiError
     */
    public static getSlackStatus(): CancelablePromise<SlackStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/slack/status',
        });
    }

    /**
     * View for interacting with study slack targets.
     * @returns StudySlackTarget
     * @throws ApiError
     */
    public static getStudySlackTargets({
        studyRef,
    }: {
        studyRef: string,
    }): CancelablePromise<Array<StudySlackTarget>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}/slack-targets',
            path: {
                'studyRef': studyRef,
            },
        });
    }

    /**
     * View for interacting with study slack targets.
     * @returns StudySlackTarget
     * @throws ApiError
     */
    public static createStudySlackTarget({
        studyRef,
        requestBody,
    }: {
        studyRef: string,
        requestBody: StudySlackTargetRequest,
    }): CancelablePromise<StudySlackTarget> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/study/{studyRef}/slack-targets',
            path: {
                'studyRef': studyRef,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * View for interacting with study slack targets.
     * @returns void
     * @throws ApiError
     */
    public static deleteStudySlackTargets({
        slackTargetId,
        studyRef,
    }: {
        /**
         * Which slack targets to delete.
         */
        slackTargetId: Array<string>,
        studyRef: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/study/{studyRef}/slack-targets',
            path: {
                'studyRef': studyRef,
            },
            query: {
                'slackTargetId': slackTargetId,
            },
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static sendStudyMessages({
        studyRef,
        inviteOnly,
    }: {
        studyRef: string,
        /**
         * Whether we should only send first-time invitations and not follow-up reminders.
         */
        inviteOnly?: boolean,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/study/{studyRef}/slack/send-messages',
            path: {
                'studyRef': studyRef,
            },
            query: {
                'inviteOnly': inviteOnly,
            },
        });
    }

}
