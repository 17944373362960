import React, { ReactNode, useLayoutEffect, useMemo, useState } from 'react';
import * as colors from '@ant-design/colors';
import { sum } from 'lodash-es';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';

const TITLE_HEIGHT = 24;
const LABEL_PADDING = 12;
const GRID_GAP = '1px';

const styles = {
  heatmap: css`
    display: flex;
    gap: ${LABEL_PADDING}px;
    line-height: 1.15;
  `,
  heatmapRight: css`
    display: flex;
    flex-direction: column;
    gap: ${LABEL_PADDING}px;
  `,
  heatmapTitle: css`
    font-weight: bold;
    font-size: ${(props) => props.theme.variable.fontSize.md};
    text-align: center;
    height: ${TITLE_HEIGHT}px;
  `,
  heatmapSubtitle: css`
    font-weight: bold;
    display: flex;
    justify-content: center;
    white-space: nowrap;

    &.heatmapSubtitle--footnote {
      font-style: italic;
      color: rgba(0, 0, 0, 0.45);
    }
  `,
  heatmapColumn: css`
    display: flex;
    flex-direction: column;
    gap: ${GRID_GAP};
  `,
  heatmapRow: css`
    display: flex;
    align-items: center;
    gap: ${GRID_GAP};
  `,
  cell: css`
    display: flex;
    justify-content: center;
    align-items: center;

    &.interactive {
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  `,
  label: css`
    display: flex;
    justify-content: 'flex-end';
    align-items: center;
    color: ${(props) => props.theme.color.text.primary};

    &.interactive {
      cursor: pointer;

      &:hover {
        color: ${colors.blue[6]};
      }
    }
  `,
};

const X_LABEL_CLASSNAME = 'heatmapXLabel';

const MIN_CELL_SIZE = 25;

const countDataForIndex = (index: number, data: number[][], symmetric: boolean) => {
  let count = 0;

  // count index column
  count += sum(data.map((row) => row[index]));

  if (symmetric) {
    // count index row (and avoid counting column again)
    count += sum(data[index].map((d, i) => (i === index ? 0 : d)));
  }

  return count;
};

type XLabelsProps = {
  cellSize: number;
  xLabels: ReactNode[];
  position: 'top' | 'bottom';
  onClick?: (xy: [number | undefined, number | undefined]) => void;
};

const XLabels: React.FC<XLabelsProps> = (props) => {
  const [xLabelHeight, setXLabelHeight] = useState<number>();

  // rotation angle for xAxis labels
  const labelAngle = props.cellSize >= 40 ? 30 : 60;

  const bottom = props.position === 'bottom';

  useLayoutEffect(() => {
    const xLabelElements = [
      ...document.getElementsByClassName(X_LABEL_CLASSNAME),
    ] as HTMLDivElement[];

    const maxLabelWidth = Math.max(
      ...xLabelElements.map((xLabelElement) => xLabelElement.clientWidth)
    );

    if (!isFinite(maxLabelWidth)) {
      return;
    }

    // this is some trig to get the height based on the rotated width
    const maxLabelHeightAfterRotation =
      Math.cos(((90 - labelAngle) * Math.PI) / 180) * maxLabelWidth;
    setXLabelHeight(maxLabelHeightAfterRotation + 4);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.xLabels]);

  return (
    <div
      css={styles.heatmapRow}
      style={{
        height: xLabelHeight,
        alignItems: bottom ? 'flex-start' : 'flex-end',
      }}
    >
      {props.xLabels.map((xLabel, x) => (
        <div
          key={x}
          style={{
            width: props.cellSize,
            display: 'flex',
            justifyContent: bottom ? 'flex-end' : 'flex-start',
            [bottom ? 'paddingRight' : 'paddingLeft']: props.cellSize / 2,
          }}
          onClick={() => props.onClick?.([x, undefined])}
        >
          <div
            className={`${X_LABEL_CLASSNAME} ${props.onClick ? 'interactive' : ''}`}
            css={styles.label}
            style={{
              transform: `rotate(-${labelAngle}deg)`,
              transformOrigin: bottom ? 'right' : 'left',
              whiteSpace: 'nowrap',
              [bottom ? 'marginTop' : 'marginBottom']: -6,
            }}
          >
            {xLabel}
          </div>
        </div>
      ))}
    </div>
  );
};

type HeatmapProps = {
  title?: string;
  xTitle?: string;
  yTitle?: string;
  xLabels: ReactNode[];
  yLabels?: ReactNode[]; // if yLabels is missing, we assume heatmap is symmetric with xLabels
  data: number[][];
  size?: number;
  cellSize?: number;
  onClick?: (xy: [number | undefined, number | undefined]) => void;
  style?: React.CSSProperties;
  rowStyle?: (y: number, area: 'label' | 'grid') => React.CSSProperties;
  cellRender?: (x: number, y: number) => ReactNode;
  cellStyle?: (x: number, y: number) => React.CSSProperties;
  labelLimit?: number;
  hiddenLabelsFootnote?: (count: number) => string;
  xLabelsPosition?: 'top' | 'bottom';
};

export const Heatmap: React.FC<HeatmapProps> = (_props: HeatmapProps) => {
  const props = { yLabels: _props.xLabels, ..._props };

  const theme = useDevExTheme();

  if (props.yLabels.length !== props.data.length) {
    console.error('Number of rows in heatmap data does not match number of labels.');
  }
  if (props.data[0] !== undefined) {
    if (props.xLabels.length !== props.data[0].length) {
      console.error('Number of columns in heatmap data does not match number of labels.');
    }
  }

  const xLabelsPosition = props.xLabelsPosition ?? 'bottom';

  // filter labels and data based on labelLimit
  const [xLabels, yLabels, data, filteredIndices] = useMemo(() => {
    if (!props.labelLimit) {
      return [props.xLabels, props.yLabels, props.data, []];
    }

    // sort labels by interactions counts
    const indexCounts = props.xLabels
      .map((_, index) => ({
        index,
        count: countDataForIndex(index, props.data, !_props.yLabels),
      }))
      .sort((a, b) => b.count - a.count);

    // determine what labels will make the cut
    const filteredIndices = indexCounts
      .slice(props.labelLimit)
      .map((ci) => ci.index)
      .sort();
    const indexFilter = (_, i) => !filteredIndices.includes(i);

    // filter labels and data for specific indices
    const data = props.data.map((d) => d.filter(indexFilter)).filter(indexFilter);

    return [
      props.xLabels.filter(indexFilter),
      props.yLabels.filter(indexFilter),
      data,
      filteredIndices,
    ];
  }, [props.labelLimit, props.xLabels, props.yLabels, _props.yLabels, props.data]);

  // compute cell size by (heatmap size - gaps) / box count
  const cellSize =
    props.cellSize ??
    Math.max(MIN_CELL_SIZE, ((props.size ?? 400) - data.length + 1) / data.length);

  let min = 0;
  let max = 0;
  for (const row of data) {
    for (const cell of row) {
      min = Math.min(min, cell);
      max = Math.max(max, cell);
    }
  }

  // reverse engineer the true data index based on the filtered indices
  // this will ensure we send the correct indices to the onClick callback
  const computeTrueIndex = (index?: number) => {
    if (index !== undefined) {
      for (const filteredIndex of filteredIndices) {
        if (filteredIndex <= index) {
          index++;
        }
      }
    }

    return index;
  };

  const onClick = (x?: number, y?: number) => {
    props.onClick?.([computeTrueIndex(x), computeTrueIndex(y)]);
  };

  const Cell: React.FC<{ x: number; y: number }> = ({ x, y }) => (
    <div
      css={styles.cell}
      style={{
        width: cellSize,
        height: cellSize,
        color: theme.color.text.contrast,
        backgroundColor: theme.color.brand.default,
        ...props.cellStyle?.(x, y),
      }}
      onClick={() => onClick(x, y)}
      className={props.onClick ? 'interactive' : ''}
    >
      {props.cellRender?.(x, y) ?? data[y][x]}
    </div>
  );

  const xLabelsLegend = (
    <XLabels
      cellSize={cellSize}
      xLabels={xLabels}
      position={xLabelsPosition}
      onClick={([x, y]) => onClick(x, y)}
    />
  );

  return (
    <div
      css={styles.heatmap}
      style={{
        alignItems: xLabelsPosition === 'top' ? 'flex-end' : 'flex-start',
        ...props.style,
      }}
    >
      <div>
        <div
          css={styles.heatmapRow}
          style={{
            paddingTop: props.title ? TITLE_HEIGHT + LABEL_PADDING : undefined,
            gap: LABEL_PADDING,
            whiteSpace: 'nowrap',
          }}
        >
          {/* Y Title */}
          {!!props.yTitle && (
            <div css={styles.heatmapSubtitle} style={{ rotate: '-90deg', width: 16 }}>
              {props.yTitle}
            </div>
          )}

          {/* Y Labels */}
          <div css={styles.heatmapColumn}>
            {yLabels.map((yLabel, y) => (
              <div
                key={y}
                css={styles.label}
                style={{
                  height: cellSize,
                  justifyContent: 'flex-end',
                  ...props.rowStyle?.(y, 'label'),
                }}
                onClick={() => onClick(undefined, y)}
                className={props.onClick ? 'interactive' : ''}
              >
                {yLabel}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Title */}
      <div css={styles.heatmapRight}>
        {!!props.title && <div css={styles.heatmapTitle}>{props.title}</div>}

        {/* X Labels - Top */}
        {xLabelsPosition === 'top' && xLabelsLegend}

        {/* XY Grid */}
        <div css={styles.heatmapColumn}>
          {yLabels.map((_, y) => (
            <div key={y} css={styles.heatmapRow} style={props.rowStyle?.(y, 'grid')}>
              {xLabels.map((_, x) => (
                <Cell key={x} x={x} y={y} />
              ))}
            </div>
          ))}
        </div>

        {/* X Labels - Bottom */}
        {xLabelsPosition === 'bottom' && xLabelsLegend}

        {/* X Title */}
        {!!props.xTitle && <div css={styles.heatmapSubtitle}>{props.xTitle}</div>}
        {filteredIndices.length > 0 && (
          <div css={styles.heatmapSubtitle} className={'heatmapSubtitle--footnote'}>
            {props.hiddenLabelsFootnote?.(filteredIndices.length) ??
              `*${filteredIndices.length} items hidden.`}
          </div>
        )}
      </div>
    </div>
  );
};
