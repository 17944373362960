import React from 'react';
import { Flex } from 'antd';
import { faAngleLeft, faRotateBack } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components';

import { PublicStudyClient, Study } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExButton } from 'jf/components/DevExButton';
import { ThumbsFeedback } from 'jf/components/ThumbsFeedback';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { SharePreviewButton } from './SharePreviewButton';
import { useStudyState } from './StudyStateContext';
import { StudyTitle } from './StudyTitle';

const styles = {
  description: css`
    font-size: ${(props) => props.theme.variable.fontSize.md};
    text-align: center;
    line-height: ${(props) => props.theme.variable.lineHeight};
    max-width: 500px;
  `,
};

export const StudyEnd: React.FC = () => {
  const theme = useDevExTheme();
  const navigate = useNavigate();

  const studyState = useStudyState();
  const { data: study } = useClientQuery(PublicStudyClient.getPublicStudy, {
    studyRef: studyState.refs.studyRef,
  });
  const isPreview = study?.status === Study.status.DRAFT;

  if (isPreview) {
    return (
      <Flex vertical align="center" gap={theme.variable.spacing.xl}>
        <StudyTitle>
          <span style={{ color: theme.color.offbrand.default }}>End of Preview</span>
        </StudyTitle>

        <div css={styles.description}>
          Thanks for previewing!
          <br />
          What would you like to do next?
        </div>

        <Flex gap={theme.variable.spacing.md}>
          {window.dx.user && (
            <DevExButton
              type="outline"
              size="large"
              icon={<FontAwesomeIcon icon={faAngleLeft} />}
              onClick={() => navigate(`/study/${studyState.refs.studyRef}/build`)}
            >
              Back to editing
            </DevExButton>
          )}
          <DevExButton
            type="outline"
            size="large"
            icon={<FontAwesomeIcon icon={faRotateBack} />}
            onClick={() => studyState.clear()}
          >
            Restart
          </DevExButton>
          <SharePreviewButton popupPlacement="bottom" />
        </Flex>
      </Flex>
    );
  }

  if (study?.status === Study.status.CLOSED) {
    return (
      <Flex vertical align="center" gap={theme.variable.spacing.xl}>
        <StudyTitle>Sorry, this survey has been closed.</StudyTitle>

        <Flex css={styles.description} vertical align="center">
          This survey is no longer accepting new responses.
        </Flex>
      </Flex>
    );
  }

  if (!studyState.saved || !studyState.submitted) {
    return (
      <Flex vertical align="center" gap={theme.variable.spacing.xl}>
        <StudyTitle>Something went wrong while submitting your response</StudyTitle>

        <Flex css={styles.description} vertical align="center" gap={theme.variable.spacing.md}>
          <div>Please try again at a later time.</div>
          <DevExButton
            type="primary"
            size="large"
            loading={studyState.saving || studyState.submitting}
            onClick={() => studyState.save()}
          >
            Resubmit response
          </DevExButton>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex vertical align="center" gap={theme.variable.spacing.xl}>
      <StudyTitle>
        Thank you for helping{' '}
        <span style={{ color: theme.color.offbrand.default }}>{study?.companyName}</span> understand
        your <span style={{ color: theme.color.brand.logo }}>Developer Experience</span>!
      </StudyTitle>

      <div css={styles.description}>Your survey response has been recorded.</div>

      <ThumbsFeedback title="Did you enjoy your survey experience?" eventName="survey-taker" />
    </Flex>
  );
};
