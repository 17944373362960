import React from 'react';
import { Flex, Select, SelectProps } from 'antd';
import { faEmptySet } from '@fortawesome/pro-thin-svg-icons';
import { DefaultOptionType } from 'antd/es/select';
import { escapeRegExp } from 'lodash-es';
import { css } from 'styled-components';

import { useDevExTheme } from '../common/themes/DevExTheme';

import { DevExEmpty } from './DevExEmpty';

const styles = {
  select: css`
    &&&&.ant-select {
      min-width: 140px;

      .ant-select-selector {
        background: ${(props) => props.theme.color.background.raised};
        transition: none;

        .ant-select-selection-item,
        .ant-select-selection-search input {
          font-size: ${(props) => props.theme.variable.fontSize.sm};
          color: ${(props) => props.theme.color.text.primary};
        }

        .select__placeholder {
          color: ${(props) => props.theme.color.text.placeholder};
          font-weight: 400;
          display: flex !important; // unset display: none
        }
      }

      .ant-select-arrow,
      .ant-select-clear {
        color: ${(props) => props.theme.color.text.tertiary};
      }

      &.ant-select-lg {
        .ant-select-selector {
          border-radius: 20px;

          .ant-select-selection-item,
          .ant-select-selection-search input {
            font-size: ${(props) => props.theme.variable.fontSize.md};
          }
        }
      }
    }
  `,
};

export type DevExSelectOption = DefaultOptionType;

export interface DevExSelectProps extends SelectProps {
  keepPlaceholder?: boolean;
}

export const DevExSelect: React.FC<DevExSelectProps> = ({ keepPlaceholder, ...props }) => {
  const theme = useDevExTheme();

  const placeholder = props.placeholder && (
    <Flex
      className="select__placeholder"
      gap={theme.variable.spacing.xs}
      align="center"
      style={{ display: 'none' }} // don't display placeholder by default to avoid it appearing in dropdown
    >
      {props.placeholder}
      {Array.isArray(props.value) ? !!props.value.length : !!props.value ? ':' : null}
    </Flex>
  );

  let options = [...(props.options ?? [])];

  // tack on placeholder before each option label, so it appears even when a value is selected
  if (placeholder && keepPlaceholder) {
    options = options.map((option) => ({
      ...option,
      label: (
        <Flex gap={theme.variable.spacing.xs}>
          {placeholder}
          {option.label}
        </Flex>
      ),
    }));
  }

  const filterOption = (searchQuery: string, option: DevExSelectOption) => {
    const regex = new RegExp(escapeRegExp(searchQuery), 'i');
    return !!option.label && regex.test(option.label.toString());
  };

  return (
    <div style={{ position: 'relative' }}>
      <Select
        css={styles.select}
        filterOption={filterOption}
        notFoundContent={<DevExEmpty icon={faEmptySet} label="No items found." />}
        {...props}
        value={props.value}
        onChange={(value, option) => {
          props.onChange?.(value, option);
        }}
        options={options}
        placeholder={placeholder}
        popupMatchSelectWidth={false}
      />
    </div>
  );
};
