import React from 'react';
import { Flex } from 'antd';
import { keyBy } from 'lodash-es';

import { NumericScore, StudyLibraryClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExText } from 'jf/components/DevExText';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { ScoreBlock } from '../ScoreBlock';

import { FreqDistHistogram } from './FreqDistHistogram';

type PromptScoreCardProps = {
  score: NumericScore;
};

export const PromptScoreCard: React.FC<PromptScoreCardProps> = (props) => {
  const theme = useDevExTheme();

  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);

  const promptsBySlug = keyBy(prompts, 'slug');
  const prompt = promptsBySlug[props.score.slug];

  return (
    <DevExCard>
      <Flex gap={theme.variable.spacing.md} justify="space-between">
        <Flex vertical gap={theme.variable.spacing.sm}>
          <div style={{ lineHeight: theme.variable.lineHeight }}>{prompt.text}</div>
        </Flex>
        <Flex vertical gap={theme.variable.spacing.sm}>
          <DevExText type="secondary">Score</DevExText>
          <ScoreBlock score={props.score.scaledAvg} size={24} />
        </Flex>
      </Flex>
      <FreqDistHistogram prompt={prompt} score={props.score} />
    </DevExCard>
  );
};
