import React from 'react';
import { Flex } from 'antd';
import {
  Dot,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
} from 'recharts';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { arrayOf } from 'jf/utils/arrayOf';

import { BenchmarkPercentile } from './BenchmarkSelect';

export const DEFAULT_CHART_MESSAGE =
  'This chart is for illustration only and does not reflect the true score distribution of topics and questions.';

// https://en.wikipedia.org/wiki/Normal_distribution
const normal = (x: number, mean = 0, stdev = 1) => {
  return (
    Math.pow(Math.E, -Math.pow(x - mean, 2) / (2 * Math.pow(stdev, 2))) /
    Math.sqrt(2 * Math.PI * Math.pow(stdev, 2))
  );
};

type BenchmarkPercentileChartProps = {
  percentile: BenchmarkPercentile | undefined;
};

const MEAN = 50;
const STDEV = 22;

const MAX = normal(MEAN, MEAN, STDEV); // maximum value that normal will return for the MEAN and STDEV
const MAX_POINT_COUNT = 10; // number of points we want to draw at the median
const POINT_RADIUS = 2;

// TODO: Move component to modal once it's created
export const BenchmarkPercentileChart: React.FC<BenchmarkPercentileChartProps> = (props) => {
  const theme = useDevExTheme();

  const data: { x: number; y: number }[] = [];

  for (const x of arrayOf(101)) {
    const xNormal = normal(x, MEAN, STDEV);

    // xNormal will be a very small number, but we can scale it to know how many points to draw
    const pointCount = Math.round(xNormal * (MAX_POINT_COUNT / MAX));

    for (const y of arrayOf(pointCount)) {
      data.push({
        x,
        y: y + 1,
      });
    }
  }

  const margin = parseInt(theme.variable.spacing.md);

  const percentileInt = props.percentile ? parseInt(props.percentile.slice(1)) : undefined;

  return (
    <Flex vertical align="center">
      <ResponsiveContainer width={'100%'} height={120}>
        <ScatterChart
          margin={{
            top: margin,
            right: margin,
            left: margin,
          }}
        >
          <XAxis
            type="number"
            dataKey="x"
            domain={[0, 100]}
            interval={0}
            tickCount={11}
            tick={{ fill: theme.color.text.tertiary }}
            stroke={theme.color.text.tertiary}
            tickMargin={parseInt(theme.variable.spacing.sm)}
          />
          <YAxis type="number" dataKey="y" hide />
          <Scatter
            data={data}
            shape={({ cx, cy, node: { x } }) => (
              <Dot
                cx={cx}
                cy={cy}
                fill={
                  percentileInt && x >= percentileInt
                    ? theme.color.brand.default
                    : theme.color.visualization.grid
                }
                r={POINT_RADIUS}
                display={x === percentileInt ? 'none' : 'default'} // hide points behind ReferenceLine
              />
            )}
          />
          <ReferenceLine
            segment={[
              { x: percentileInt, y: 1 },
              { x: percentileInt, y: MAX_POINT_COUNT + 1 },
            ]}
            stroke={theme.color.brand.default}
            strokeWidth={POINT_RADIUS * 2}
            strokeLinecap="round"
          />
        </ScatterChart>
      </ResponsiveContainer>
      <div style={{ color: theme.color.text.tertiary, fontSize: theme.variable.fontSize.xs }}>
        <i>{DEFAULT_CHART_MESSAGE}</i>
      </div>
    </Flex>
  );
};
