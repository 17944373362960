import React, { useEffect, useMemo, useState } from 'react';
import { Flex, Tooltip } from 'antd';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowUpFromSquare,
  faClipboardCheck,
  faLineChart,
  faShare,
  faSparkles,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import capitalize from 'lodash/capitalize';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { SlackTargetingClient, Study, StudyClient, StudySequenceClient } from 'jf/api';
import { startFullstory } from 'jf/common/DevEx';
import { DevExPageHeader, DevExPageHeaderTab } from 'jf/common/DevExPageHeader';
import { DevExPageTitle } from 'jf/common/DevExPageTitle';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { CopyLinkPopover } from 'jf/components/CopyLinkPopover';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExTag } from 'jf/components/DevExTag';
import { useLocalStorage } from 'jf/utils/useBrowserStorage';
import { useClientFlags } from 'jf/utils/useClientFlags';
import { useClientPerms } from 'jf/utils/useClientPerms';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import { ExportStudyModal } from './export/ExportStudyModal';
import { CloseStudyModal } from './CloseStudyModal';
import { SendSlackReminderModal } from './SendSlackReminderModal';
import {
  STARRED_TOPICS_SIDE_PANEL_COLLAPSED_WIDTH,
  STARRED_TOPICS_SIDE_PANEL_WIDTH,
  StarredTopicsSidePanel,
} from './StarredTopicsSidePanel';
import { useStudyParticipation } from './useStudyParticipation';

export const ANONYMITY_THRESHOLD = 3;

export const SHARE_LINK_EXPIRY_DAYS = 14;

const SHINY_REVEAL_TIMER = 6000;

export const makeStudyAnalyzerPath = (
  studyRef: string | undefined,
  teamRef: string | undefined,
  tabKey: string,
  params: Record<string, string> = {}
) => {
  const searchParams = new URLSearchParams(params);

  // persist scope_token in Survey Analyzer
  const scopeToken = new URLSearchParams(window.location.search).get('scope_token');
  if (scopeToken) {
    searchParams.append('scope_token', scopeToken);
  }

  return `/study/${studyRef}/analyze/${teamRef ? `${teamRef}/` : ''}${tabKey}?${searchParams}`;
};

export const StudyAnalyzerPage: React.FC = () => {
  const flags = useClientFlags();
  useEffect(() => startFullstory('survey-analyzer'), []);
  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef: string }>();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const exportStudyModal = useJFOverlay(ExportStudyModal);
  const closeStudyModal = useJFOverlay(CloseStudyModal);
  const sendSlackReminderModal = useJFOverlay(SendSlackReminderModal);
  const theme = useDevExTheme();
  const perms = useClientPerms();

  const [shareLink, setShareLink] = useState('');
  const [starredTopicsCollapsed, setStarredTopicsCollapsed] = useLocalStorage(
    'devex_actionItems_collapsed',
    false
  );

  const { data: slackTargets } = useClientQuery(
    SlackTargetingClient.getStudySlackTargets,
    { studyRef },
    { enabled: flags.slackTargeting }
  );

  // reveal / highlights button shiny on page load
  const [isRevealButtonShiny, setIsRevealButtonShiny] = useState(true);

  // timer to un-shiny the highlights/reveal button after SHINY_REVEAL_TIMER ms
  setTimeout(() => {
    setIsRevealButtonShiny(false);
  }, SHINY_REVEAL_TIMER);

  const tabKey = useMemo(() => {
    const TAB_KEY_REGEX = /\/([^/]*?)$/g;
    return TAB_KEY_REGEX.exec(location.pathname)?.[1];
  }, [location.pathname]);

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });
  const isOpen = study?.status === Study.status.OPEN;

  let tabKeys = ['insights', 'topics', 'questions', 'participation'];

  if (isOpen) {
    // removes topics tab
    tabKeys.splice(tabKeys.indexOf('topics'), 1);
  }
  if (isOpen) {
    // removes insights tab
    tabKeys.splice(tabKeys.indexOf('insights'), 1);
  }

  // persist scope_token when using tabs
  const scopeToken = searchParams.get('scope_token');

  const tabs: DevExPageHeaderTab[] = tabKeys.map((key) => ({
    key,
    label: capitalize(key),
    href: makeStudyAnalyzerPath(studyRef, teamRef, key),
  }));

  const { mutateAsync: shareStudy } = useClientMutation(StudyClient.shareStudy);

  const onClose = () => {
    trackEvent('survey-analyzer:close:start', { surveyRef: studyRef });
    closeStudyModal.open();
  };

  const onExport = () => {
    trackEvent('survey-analyzer:export:start', { surveyRef: studyRef });
    exportStudyModal.open();
  };

  const { data: participation } = useStudyParticipation({ studyRef, teamRef });
  const isCloseDisabled = participation && participation.uniqueResponses < 3;

  const { data: sequence } = useClientQuery(StudySequenceClient.getStudySequence, {
    sequenceRef: study?.sequenceRef,
  });

  // we show starred topics even if study is not in a sequence since there is a special state for that case
  const showStarredTopics =
    !!flags.actionItems && perms.role.customerAdmin && study?.status === 'CLOSED';

  return (
    <div
      style={{
        marginRight: showStarredTopics
          ? starredTopicsCollapsed
            ? STARRED_TOPICS_SIDE_PANEL_COLLAPSED_WIDTH
            : STARRED_TOPICS_SIDE_PANEL_WIDTH
          : 0,
        transition: 'margin-right 150ms ease',
      }}
    >
      <DevExPageTitle>{`Survey ${study?.name ?? ''}`}</DevExPageTitle>
      <DevExPageHeader
        title={
          study ? (
            <Flex gap={theme.variable.spacing.sm} align="center">
              <div>{study?.name}</div>

              {sequence && !scopeToken && (
                <DevExButton
                  icon={<FontAwesomeIcon icon={faLineChart} />}
                  type="text"
                  style={{ paddingInline: theme.variable.spacing.sm }}
                  onClick={() => {
                    const teamSection = teamRef ? `team/${teamRef}` : '';
                    navigate(`/series/${sequence.ref}/${teamSection}`);
                  }}
                >
                  {sequence.name}
                </DevExButton>
              )}

              {scopeToken === 'DEMO' && <DevExTag color="green">Example</DevExTag>}
            </Flex>
          ) : undefined
        }
        extra={
          study ? (
            study.status === Study.status.CLOSED ? (
              <>
                {flags.studyReveal && (
                  <DevExButton
                    size="large"
                    type="outline"
                    shiny={isRevealButtonShiny}
                    onClick={() => {
                      trackEvent('survey-reveal:open:click');
                      navigate(makeStudyAnalyzerPath(studyRef, undefined, 'reveal'));
                    }}
                    icon={<FontAwesomeIcon icon={faSparkles} />}
                  >
                    Highlights
                  </DevExButton>
                )}
                <DevExButton
                  size="large"
                  type="outline"
                  icon={<FontAwesomeIcon icon={faArrowUpFromSquare} />}
                  onClick={onExport}
                >
                  Export
                </DevExButton>
                {
                  // GBAC RESTRICTION
                  // only customer admins can create share links
                  perms.role.customerAdmin && (
                    <CopyLinkPopover
                      title="Share Results"
                      description={`The link above will grant public access to this ${teamRef ? 'team' : 'survey'}'s results to whomever you share it with. It will expire in ${SHARE_LINK_EXPIRY_DAYS} days.`}
                      link={shareLink}
                      placement="bottomRight"
                      onCopy={() =>
                        trackEvent('survey-analyzer:share-results:copy', { surveyRef: studyRef })
                      }
                    >
                      <DevExButton
                        size="large"
                        type="outline"
                        icon={<FontAwesomeIcon icon={faShare} />}
                        onClick={() => {
                          if (studyRef) {
                            trackEvent('survey-analyzer:share-results:start', {
                              surveyRef: studyRef,
                            });
                            shareStudy({
                              studyRef,
                              requestBody: {
                                teamRef,
                                expiryDays: SHARE_LINK_EXPIRY_DAYS,
                              },
                            }).then((response) => {
                              const queryParams = new URLSearchParams(window.location.search);
                              queryParams.append('scope_token', response.scopeToken);
                              setShareLink(
                                `${window.location.origin}${window.location.pathname}?${queryParams}`
                              );
                            });
                          }
                        }}
                      >
                        Share results
                      </DevExButton>
                    </CopyLinkPopover>
                  )
                }
              </>
            ) : (
              <>
                <Tooltip
                  title={
                    isCloseDisabled
                      ? 'To protect anonymity, we require at least 3 responses before a survey can be closed.'
                      : ''
                  }
                >
                  <div>
                    <DevExButton
                      size="large"
                      type="outline"
                      icon={<FontAwesomeIcon icon={faClipboardCheck} />}
                      onClick={onClose}
                      disabled={isCloseDisabled}
                    >
                      Close
                    </DevExButton>
                  </div>
                </Tooltip>

                {slackTargets?.length ? (
                  <DevExButton
                    size="large"
                    type="primary"
                    icon={<FontAwesomeIcon icon={faSlack} />}
                    onClick={() => {
                      trackEvent(`survey-analyzer:slack-reminder:open`);
                      sendSlackReminderModal.open();
                    }}
                  >
                    Send reminder
                  </DevExButton>
                ) : (
                  <CopyLinkPopover
                    title="Share Survey with Participants"
                    description="Copy the survey link above and share it with your target participants."
                    link={`${window.location.origin}/study/${studyRef}`}
                    placement="bottomRight"
                    onCopy={() =>
                      trackEvent('survey-analyzer:share-survey:copy', { surveyRef: studyRef })
                    }
                  >
                    <DevExButton
                      size="large"
                      type="primary"
                      icon={<FontAwesomeIcon icon={faShare} />}
                      onClick={() =>
                        trackEvent('survey-analyzer:share-survey:start', { surveyRef: studyRef })
                      }
                    >
                      Share survey
                    </DevExButton>
                  </CopyLinkPopover>
                )}
              </>
            )
          ) : undefined
        }
        tabs={tabs}
        tabKey={tabKey}
      />
      <Outlet />
      {showStarredTopics && (
        <StarredTopicsSidePanel
          sequenceRef={study?.sequenceRef}
          collapsed={starredTopicsCollapsed}
          onCollapse={setStarredTopicsCollapsed}
        />
      )}
    </div>
  );
};
