import React, { useEffect } from 'react';
import { isEqual } from 'lodash-es';

import { DevExMenuItem } from 'jf/components/DevExMenuItem';

import { StudyStep, useStudyBuilderState } from '../../StudyBuilderState';

export const StudyStepItem: React.FC<{
  step?: StudyStep;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
}> = (props) => {
  const state = useStudyBuilderState();

  const id = props.step && `study-step-${Object.values(props.step).join('#')}`;
  const isActive = isEqual(props.step, state.studyStep);

  // scroll entry into view
  useEffect(() => {
    if (isActive && id) {
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isActive]);

  return (
    <DevExMenuItem
      id={id}
      active={isActive}
      onClick={() => state.update({ studyStep: props.step })}
      style={{ pointerEvents: props.disabled ? 'none' : undefined, ...props.style }}
      className={props.className}
    >
      {props.children}
    </DevExMenuItem>
  );
};
