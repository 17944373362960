import { notify } from 'bugsnag';
import { snakeCase } from 'lodash-es';
import { QueryClient, useMutation, useQueries, useQuery, UseQueryOptions } from 'react-query';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';

import { notNull } from 'jf/utils/notNull';

import { ApiError, CancelablePromise } from '../api';

import { removeCookie } from './getCookie';
import { queryClient } from './queryClient';

const STALE_TIME = 1200000; // cache for 20 minutes

const handleApiError = (
  error: ApiError,
  searchParams: URLSearchParams,
  navigate: NavigateFunction
) => {
  const notifiableError = new Error(JSON.stringify(error.body));

  // if we get a 403 Forbidden, the user's authentication has expired
  if (error.status === 403) {
    window.dx.user = undefined;
    if (searchParams.get('scope_token')) {
      let message = 'An error occurred with the link you were provided.';

      if (error.body.detail === 'Token has expired.') {
        message = 'Sorry, the link you were provided has expired. Please request a new one!';
      } else if (
        error.body.detail === 'Authentication credentials were not provided.' ||
        error.body.detail === 'Token does not have sufficient scopes.' ||
        error.body.detail === 'You do not have permission to perform this action.'
      ) {
        message =
          'Sorry, the link you were provided has been invalidated. Please request a new one!';
        notify(notifiableError);
      }

      navigate(`/error?code=403&message=${message}`);
    } else {
      queryClient.removeQueries();
      if (error.body.detail?.includes('CSRF Failed')) {
        // hard reload to pickup a fresh CSRF token
        removeCookie('devex_csrftoken');
        window.location.href = '/auth/sign-in';
      } else {
        navigate('/auth/sign-in');
      }
    }
  }
  // if we get a 404 Not Found, the user tried to access something that doesn't exist / they don't have access to
  else if (error.status === 404) {
    navigate(`/error?code=404&message=Sorry, we couldn't find the data you were looking for.`);
  } else if (error.status === 400) {
    notify(notifiableError);
  }

  throw error;
};

// unwraps the generic inside of a CancelablePromise
// ex. CancelablePromise<Study> => Study
type CancelablePromiseGeneric<T> = T extends CancelablePromise<infer R> ? R : never;

// allow expected values to be explicitly undefined
type ExplicitlyNullable<T> = {
  [P in keyof T]: T[P] | undefined;
};

/**
 * Invoke an API client function via `useQuery`.
 * @param queryFn API client function
 * @param queryFnArg Function arguments *(Query will not fire while an argument is undefined)*
 */
export const useClientQuery = <
  QueryFn extends (arg: any) => CancelablePromise<any>, // ex. StudyClient.getStudy
  QueryFnArg extends Parameters<QueryFn>[0], // { studyRef: string }
  QueryFnReturn = CancelablePromiseGeneric<ReturnType<QueryFn>>, // Study
>(
  queryFn: QueryFn,
  // use spread operator so we can require the queryFnArg param only if it's needed
  ...[queryFnArg, options]: QueryFnArg extends object
    ? [ExplicitlyNullable<QueryFnArg>, UseQueryOptions<QueryFnReturn, ApiError>?]
    : [null?, UseQueryOptions<QueryFnReturn, ApiError>?]
) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const queryKey = makeQueryKey(queryFn, searchParams);

  const queryFnArgValues = Object.values(queryFnArg ?? {});

  return useQuery<QueryFnReturn, ApiError>(
    [queryKey, ...queryFnArgValues],
    () => queryFn(queryFnArg).catch((error) => handleApiError(error, searchParams, navigate)),
    {
      ...options,
      staleTime: STALE_TIME, // cache for 20 minutes
      enabled: queryFnArgValues.every(notNull) && options?.enabled !== false, // wait to fire query until there are no undefined args
    }
  );
};

/**
 * Invoke multiple API client functions via `useQueries`.
 * @param queryFn API client function
 * @param queryFnArgs Array of function arguments *(Query will not fire while an argument is undefined)*
 */
export const useClientQueries = <
  QueryFn extends (arg: any) => CancelablePromise<any>, // ex. StudyClient.getStudy
  QueryFnArg extends Parameters<QueryFn>[0], // { studyRef: string }
  QueryFnReturn = CancelablePromiseGeneric<ReturnType<QueryFn>>, // Study
>(
  queryFn: QueryFn,
  // use spread operator so we can require the queryFnArg param only if it's needed
  ...[queryFnArgs, options]: QueryFnArg extends object
    ? [QueryFnArg[], UseQueryOptions<QueryFnReturn, ApiError>?]
    : [null?, UseQueryOptions<QueryFnReturn, ApiError>?]
) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const queryKey = makeQueryKey(queryFn, searchParams);

  return useQueries(
    (queryFnArgs ?? []).map<UseQueryOptions<QueryFnReturn, ApiError>>((queryFnArg) => {
      const queryFnArgValues = Object.values(queryFnArg ?? {});
      return {
        queryKey: [queryKey, ...queryFnArgValues],
        queryFn: () =>
          queryFn(queryFnArg).catch((error) => handleApiError(error, searchParams, navigate)),
        ...options,
        staleTime: STALE_TIME, // cache for 20 minutes
        enabled: queryFnArgValues.every(notNull) && options?.enabled !== false, // wait to fire query until there are no undefined args
      };
    })
  );
};

/**
 * Invoke an API client function via `useMutation`.
 * @param mutationFn API client function
 */
export const useClientMutation = <
  MutationFn extends (arg: any) => CancelablePromise<any>, // ex. StudyClient.getStudy
  MutationFnArg extends Parameters<MutationFn>[0], // { studyRef: string }
  MutationFnReturn = CancelablePromiseGeneric<ReturnType<MutationFn>>, // Study
>(
  mutationFn: MutationFn
) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return useMutation<
    MutationFnReturn,
    ApiError,
    // fallback to void when there are no function arguments, so mutate/mutateSync act as expected
    MutationFnArg extends object ? MutationFnArg : void
  >((arg) => mutationFn(arg).catch((error) => handleApiError(error, searchParams, navigate)));
};

const makeQueryKey = (queryFn: Function, searchParams: URLSearchParams) => {
  const scopeToken = searchParams.get('scope_token');

  // compute query key (ex. StudyClient.getStudy => "GET_STUDY")
  // this key is used when invalidating a specific query cache
  let queryKey = snakeCase(queryFn.name).toUpperCase();

  // separate DEMO queries from actual queries
  // this prevents issues where the initech cache would not be the same as the company cache
  if (scopeToken === 'DEMO') {
    queryKey += ' _DEMO';
  }

  return queryKey;
};

/**
 * Prefetch an API client function via `queryClient`.
 * @param queryFn API client function
 * @param queryFnArg Function arguments
 */
export const prefetchClientQuery = <
  QueryFn extends (arg: any) => CancelablePromise<any>, // ex. StudyClient.getStudy
  QueryFnArg extends Parameters<QueryFn>[0], // { studyRef: string }
>(
  queryClient: QueryClient,
  queryFn: QueryFn,
  // use spread operator so we can require the queryFnArg param only if it's needed
  ...[queryFnArg]: QueryFnArg extends object ? [QueryFnArg] : [null?]
) => {
  const queryKey = makeQueryKey(queryFn, new URLSearchParams({}));

  const queryFnArgValues = Object.values(queryFnArg ?? {});

  return queryClient.prefetchQuery([queryKey, ...queryFnArgValues], () => queryFn(queryFnArg));
};
