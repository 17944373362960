import { Study, StudyScoreClient } from 'jf/api';
import { useClientQueries } from 'jf/utils/useClientQuery';

import { useSequenceStudies } from './useSequenceStudies';

type UseSequenceScoresProps = {
  sequenceRef: string | undefined;
  teamRef?: string;
  type?: Parameters<typeof StudyScoreClient.getStudyScores>[0]['type'];
};

export const useSequenceScores = (props: UseSequenceScoresProps) => {
  const closedStudies = useSequenceStudies(props.sequenceRef, Study.status.CLOSED);

  const studyScoresQueries = useClientQueries(
    StudyScoreClient.getStudyScores,
    closedStudies?.map((study) => ({ studyRef: study.ref, type: 'TOPIC' as 'TOPIC' })) ?? [],
    { enabled: !props.teamRef }
  );

  const studyScoresForTeamQueries = useClientQueries(
    StudyScoreClient.getStudyScoresForTeam,
    closedStudies?.map((study) => ({
      studyRef: study.ref,
      type: 'TOPIC' as 'TOPIC',
      teamRef: props.teamRef!,
    })) ?? [],
    { enabled: !!props.teamRef }
  );

  return props.teamRef ? studyScoresForTeamQueries : studyScoresQueries;
};
