import React from 'react';
import { Flex } from 'antd';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';

import { useScoreColors } from './useScoreColors';

const styles = {
  block: css`
    color: ${(props) => props.theme.color.text.dark};
    width: fit-content;
    height: fit-content;
    line-height: 1;
    box-sizing: content-box;
  `,
};

type ScoreBlockProps = {
  score?: number;
  size?: number;
};

export const ScoreBlock: React.FC<ScoreBlockProps> = (props) => {
  const { colorByScore } = useScoreColors();
  const theme = useDevExTheme();

  const backgroundColor = colorByScore(props.score ?? 50);
  const size = props.size ?? parseInt(theme.variable.fontSize.sm);

  return (
    <Flex
      css={styles.block}
      style={{
        backgroundColor,
        fontSize: size,
        minWidth: size,
        padding: Math.round(size / 4),
        borderRadius: Math.round(size / 4),
      }}
      align="center"
      justify="center"
    >
      {props.score ?? '—'}
    </Flex>
  );
};
