import React, { useEffect, useMemo, useState } from 'react';
import { Flex, Tooltip } from 'antd';
import {
  faBan,
  faCheck,
  faPlus,
  faTrash,
  faWandMagicSparkles,
} from '@fortawesome/pro-regular-svg-icons';
import { faBallotCheck, faList } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce, isEqual, keyBy } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { StarredTopicActionItem, StudyLibraryClient, StudySequenceClient } from 'jf/api';
import { DevExSidePanel } from 'jf/common/DevExSidePanel';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExBadge } from 'jf/components/DevExBadge';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExCheckbox } from 'jf/components/DevExCheckbox';
import { DevExCollapsible } from 'jf/components/DevExCollapsible';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExLink } from 'jf/components/DevExLink';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { DevExTextArea } from 'jf/components/DevExTextArea';
import { ReleaseTag } from 'jf/components/ReleaseTag';
import { arrayOf } from 'jf/utils/arrayOf';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import { StarredTopicStar } from './StarredTopicStar';

export const STARRED_TOPICS_SIDE_PANEL_WIDTH = 360;
export const STARRED_TOPICS_SIDE_PANEL_COLLAPSED_WIDTH = 280;

type StarredTopicsSidePanelProps = {
  sequenceRef: string | undefined;
  collapsed: boolean;
  onCollapse: (collapsed: boolean) => void;
};

export const StarredTopicsSidePanel: React.FC<StarredTopicsSidePanelProps> = (props) => {
  const theme = useDevExTheme();

  const [recommendedActionsByTopicSlug, setRecommendedActionsByTopicSlug] = useState<
    Record<string, string[]>
  >({});

  const { data: topics, isLoading: topicsLoading } = useClientQuery(StudyLibraryClient.getTopics);
  const { data: starredTopics, isLoading: starredTopicsLoading } = useClientQuery(
    StudySequenceClient.getStarredTopics,
    {
      sequenceRef: props.sequenceRef,
    }
  );

  const isLoading = topicsLoading || starredTopicsLoading;

  const topicsBySlug = useMemo(() => keyBy(topics, 'slug'), [topics]);

  const focusActionItem = (ref: string) => {
    setTimeout(() => {
      const element = document.getElementById(ref) as HTMLInputElement | undefined;
      if (element) {
        element.focus();
        element.setSelectionRange(element.value.length, element.value.length); // start cursor at the end
      }
    }, 0);
  };
  const navigate = useNavigate();

  const onRecommendActionItems = (topicSlug: string) => {
    trackEvent('survey-analyzer:starred-topics:generate-recommendations', {
      topicSlug,
    });
    setRecommendedActionsByTopicSlug((value) => ({
      ...value,
      [topicSlug]: topicsBySlug[topicSlug].actions,
    }));
  };

  useEffect(() => {
    if (!isLoading) {
      let state = 'non-empty';
      if (!props.sequenceRef) {
        state = 'no-series';
      } else if (starredTopics?.length === 0) {
        state = 'empty';
      }
      trackEvent('survey-analyzer:starred-topics:show', { state, isCollapsed: props.collapsed });
    }
  }, [isLoading]);

  return (
    <DevExSidePanel
      width={STARRED_TOPICS_SIDE_PANEL_WIDTH}
      css={css`
        position: fixed;
        right: 0px;
        bottom: 0;
        height: calc(100vh - 65px);
      `}
      collapse={{
        collapsed: props.collapsed,
        onCollapse: (collapsed) => {
          setRecommendedActionsByTopicSlug({});
          props.onCollapse(collapsed);
        },
        collapsedWidth: STARRED_TOPICS_SIDE_PANEL_COLLAPSED_WIDTH,
      }}
    >
      <Flex
        vertical
        gap={theme.variable.spacing.lg}
        style={{ padding: theme.variable.spacing.md, height: '100%', overflow: 'auto' }}
      >
        <Flex align="center" gap={theme.variable.spacing.sm}>
          <strong style={{ fontSize: theme.variable.fontSize.lg }}>Action Items</strong>
          <ReleaseTag stage="ALPHA" />
        </Flex>

        {isLoading &&
          arrayOf(3).map((i) => (
            <Flex key={i} vertical gap={theme.variable.spacing.sm}>
              <DevExSkeleton fontSize="md" noLineHeight width={120} />
              <DevExSkeleton fontSize="sm" width={['100%', '50%']} />
              <DevExSkeleton fontSize="sm" width={['100%', '25%']} />
              <DevExSkeleton fontSize="sm" width={['100%', '75%']} />
            </Flex>
          ))}

        {!isLoading && (starredTopics?.length === 0 || !props.sequenceRef) && (
          <Flex
            style={{ height: '100%' }}
            vertical
            justify="center"
            gap={theme.variable.spacing.md}
          >
            <DevExEmpty
              icon={!props.sequenceRef ? faList : faBallotCheck}
              label={
                !props.sequenceRef ? (
                  <>
                    This survey is not in a series. To start tracking action items, add this survey
                    to a series on the{' '}
                    <DevExLink onClick={() => navigate('/studies')}>Surveys page</DevExLink>.
                  </>
                ) : (
                  'You have no action items. Star a topic to start adding them!'
                )
              }
            />
          </Flex>
        )}

        {!isLoading &&
          starredTopics?.map((starredTopic) => {
            const topic = topicsBySlug[starredTopic.topicSlug];
            const recommendedActions = recommendedActionsByTopicSlug[starredTopic.topicSlug];
            return (
              <Flex key={topic.slug} vertical gap={theme.variable.spacing.sm}>
                <Flex gap={theme.variable.spacing.xs} justify="space-between" align="center">
                  <Flex
                    gap={theme.variable.spacing.xs}
                    style={{ fontSize: theme.variable.fontSize.md }}
                    align="center"
                  >
                    <StarredTopicStar
                      sequenceRef={props.sequenceRef}
                      topicSlug={topic.slug}
                      eventName="survey-analyzer:starred-topics:star-topic"
                    />
                    <strong>{topic.label}</strong>
                    <DevExBadge count={starredTopic.actionItems.length} />
                  </Flex>

                  {!props.collapsed && (
                    <Tooltip title="Recommend action items">
                      <DevExButton
                        type="text"
                        icon={<FontAwesomeIcon icon={faWandMagicSparkles} />}
                        onClick={() => onRecommendActionItems(topic.slug)}
                      />
                    </Tooltip>
                  )}
                </Flex>

                {!!recommendedActions?.filter((action) => !!action).length && (
                  <RecommendedActions
                    sequenceRef={props.sequenceRef!}
                    topicSlug={topic.slug}
                    recommendedActions={recommendedActions}
                    onChange={(newRecommendedActions) =>
                      setRecommendedActionsByTopicSlug({
                        [topic.slug]: newRecommendedActions,
                        ...recommendedActionsByTopicSlug,
                      })
                    }
                  />
                )}

                <DevExCollapsible
                  collapsed={props.collapsed}
                  style={{
                    marginTop: props.collapsed ? -parseInt(theme.variable.spacing.sm) : 0,
                    transition: 'margin-top 150ms ease',
                    paddingLeft: 1,
                  }}
                >
                  {[
                    ...starredTopic.actionItems,
                    {
                      ref: `UNSAVED_${topic.slug}`,
                      isCompleted: false,
                      text: '',
                    },
                  ].map((actionItem, actionItemIndex) => (
                    <ActionItem
                      key={actionItem.ref}
                      sequenceRef={props.sequenceRef!}
                      topicSlug={topic.slug}
                      actionItem={actionItem}
                      onEnter={() => {
                        // focus the next action item when Enter is pressed
                        const nextActionItem = starredTopic.actionItems[actionItemIndex + 1];
                        focusActionItem(nextActionItem?.ref ?? `UNSAVED_${topic.slug}`);
                      }}
                    />
                  ))}
                </DevExCollapsible>
              </Flex>
            );
          })}
      </Flex>
    </DevExSidePanel>
  );
};

type ActionItemProps = {
  sequenceRef: string;
  topicSlug: string;
  actionItem: StarredTopicActionItem;
  onEnter?: () => void;
};

const ActionItem: React.FC<ActionItemProps> = (props) => {
  const theme = useDevExTheme();
  const queryClient = useQueryClient();

  const [actionItem, setActionItem] = useState(props.actionItem);

  const isUnsaved = actionItem.ref.startsWith('UNSAVED_');

  const updateActionItemMutation = useClientMutation(
    StudySequenceClient.updateStarredTopicActionItem
  );
  const createActionItemMutation = useClientMutation(
    StudySequenceClient.createStarredTopicActionItem
  );
  const deleteActionItemMutation = useClientMutation(
    StudySequenceClient.deleteStarredTopicActionItem
  );

  const updateActionItem = (actionItem: StarredTopicActionItem) => {
    trackEvent('survey-analyzer:starred-topics:update-action-item', {
      topicSlug: props.topicSlug,
      text: actionItem.text,
      isCompleted: actionItem.isCompleted,
    });
    updateActionItemMutation
      .mutateAsync({
        sequenceRef: props.sequenceRef,
        topicSlug: props.topicSlug,
        actionItemRef: actionItem.ref,
        requestBody: { text: actionItem.text, isCompleted: actionItem.isCompleted },
      })
      .then(() => queryClient.invalidateQueries('GET_STARRED_TOPICS'));
  };

  const debounceUpdateActionItem = useMemo(() => debounce(updateActionItem, 500), []);

  const deleteActionItem = (actionItem: StarredTopicActionItem) => {
    trackEvent('survey-analyzer:starred-topics:delete-action-item', {
      topicSlug: props.topicSlug,
      text: actionItem.text,
      isCompleted: actionItem.isCompleted,
    });
    deleteActionItemMutation
      .mutateAsync({
        sequenceRef: props.sequenceRef,
        topicSlug: props.topicSlug,
        actionItemRef: actionItem.ref,
      })
      .then(() => queryClient.invalidateQueries('GET_STARRED_TOPICS'));
  };

  const debounceDeleteActionItem = useMemo(() => debounce(deleteActionItem, 500), []);

  const createActionItem = (actionItem: StarredTopicActionItem) => {
    trackEvent('survey-analyzer:starred-topics:create-action-item', {
      topicSlug: props.topicSlug,
      text: actionItem.text,
    });
    createActionItemMutation
      .mutateAsync({
        sequenceRef: props.sequenceRef,
        topicSlug: props.topicSlug,
        requestBody: {
          text: actionItem.text,
          isRecommendation: false,
        },
      })
      .then(() => {
        queryClient.invalidateQueries('GET_STARRED_TOPICS').then(() => {
          // reset input
          setActionItem((actionItem) => ({ ...actionItem, text: '' }));
          props.onEnter?.();
        });
      });
  };

  useEffect(() => {
    // fire queries dynamically when action item isUpdated
    if (
      !isEqual(actionItem, props.actionItem) &&
      !isUnsaved &&
      !deleteActionItemMutation.isLoading
    ) {
      if (actionItem.text) {
        // update existing action item
        debounceDeleteActionItem.cancel();
        debounceUpdateActionItem(actionItem);
      } else {
        // delete empty action item
        debounceDeleteActionItem(actionItem);
      }
    }
  }, [actionItem]);

  return (
    <Flex
      gap={theme.variable.spacing.sm}
      css={css`
        &:not(:hover) {
          .actionItem--delete {
            visibility: hidden;
          }
        }
      `}
    >
      <DevExCheckbox
        disabled={!actionItem.text}
        checked={actionItem.isCompleted}
        onChange={(checked) =>
          setActionItem((actionItem) => ({ ...actionItem, isCompleted: checked }))
        }
      />
      <DevExTextArea
        id={actionItem.ref}
        css={css`
          padding: 0;
          border: none;
          border-radius: 0 !important;
          resize: none !important;
          text-decoration: ${actionItem.isCompleted ? 'line-through' : 'none'};
          width: ${`${STARRED_TOPICS_SIDE_PANEL_WIDTH - 90}px`}; // ensure TextArea doesn't shrink text during collapsed transition
        `}
        autoSize={{ minRows: 1 }}
        placeholder="Create a new action item..."
        value={actionItem.text}
        onChange={(value) => setActionItem((actionItem) => ({ ...actionItem, text: value }))}
        onKeyDown={(event) => {
          // prevent new line characters
          if (event.key === 'Enter') {
            event.preventDefault();
            if (isUnsaved) {
              createActionItem(actionItem);
            } else {
              props.onEnter?.();
            }
          }
        }}
        disabled={createActionItemMutation.isLoading || deleteActionItemMutation.isLoading}
      />
      {isUnsaved && !!actionItem.text && (
        <DevExButton
          icon={<FontAwesomeIcon icon={faCheck} />}
          type="text"
          size="small"
          onClick={() => createActionItem(actionItem)}
        />
      )}
      {!isUnsaved && (
        <DevExButton
          icon={<FontAwesomeIcon icon={faTrash} />}
          type="text"
          size="small"
          onClick={() => deleteActionItem(actionItem)}
          className="actionItem--delete"
        />
      )}
    </Flex>
  );
};

type RecommendedActionsProps = {
  sequenceRef: string;
  topicSlug: string;
  recommendedActions: string[];
  onChange: (recommendedActions: string[]) => void;
};

const RecommendedActions: React.FC<RecommendedActionsProps> = (props) => {
  const theme = useDevExTheme();
  const queryClient = useQueryClient();

  // find the recommended action that is not an empty string
  // (we use an empty string to represent resolved actions so we can retain the current index & total count as user progresses through)
  const index = props.recommendedActions.findIndex((action) => !!action);
  const action = props.recommendedActions[index];

  const createActionItemMutation = useClientMutation(
    StudySequenceClient.createStarredTopicActionItem
  );

  return (
    <DevExCard
      style={{
        background: theme.color.status.discovery.background,
        padding: theme.variable.spacing.sm,
      }}
      bodyStyle={{
        gap: theme.variable.spacing.sm,
      }}
    >
      <div
        style={{
          fontSize: theme.variable.fontSize.xs,
          color: theme.color.status.discovery.text,
        }}
      >
        Recommended Actions ({index + 1} of {props.recommendedActions.length})
      </div>
      <div style={{ lineHeight: theme.variable.lineHeight }}>{action}</div>
      <Flex justify="end" gap={theme.variable.spacing.sm}>
        <Tooltip title="Reject recommendation">
          <DevExButton
            icon={<FontAwesomeIcon icon={faBan} />}
            size="small"
            type="text"
            onClick={() => {
              trackEvent('survey-analyzer:starred-topics:reject-recommendation', {
                topicSlug: props.topicSlug,
                text: action,
              });
              props.recommendedActions[index] = '';
              props.onChange(props.recommendedActions);
            }}
          />
        </Tooltip>
        <Tooltip title="Accept recommendation">
          <DevExButton
            icon={<FontAwesomeIcon icon={faPlus} />}
            size="small"
            type="text"
            onClick={() => {
              trackEvent('survey-analyzer:starred-topics:accept-recommendation', {
                topicSlug: props.topicSlug,
                text: action,
              });
              createActionItemMutation
                .mutateAsync({
                  sequenceRef: props.sequenceRef,
                  topicSlug: props.topicSlug,
                  requestBody: {
                    text: action,
                    isRecommendation: true,
                  },
                })
                .then(() => {
                  props.recommendedActions[index] = '';
                  props.onChange(props.recommendedActions);

                  queryClient.invalidateQueries('GET_STARRED_TOPICS');
                });
            }}
            disabled={createActionItemMutation.isLoading}
          />
        </Tooltip>
      </Flex>
    </DevExCard>
  );
};
