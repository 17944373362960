import React, { useRef } from 'react';
import { Flex } from 'antd';
import { motion } from 'framer-motion';
import { keyBy } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { css } from 'styled-components';

import { StudyLibraryClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { FreqDistBar } from '../insights/FreqDistBar';
import { ScoreBlock } from '../ScoreBlock';
import { useStudyScores } from '../useStudyScores';

import { usePageIndexTracker, useStudyRevealState } from './studyReveal';

const styles = {
  allTopics: css`
    width: 100vw;
    height: 300vh;
    background-color: ${(props) => props.theme.color.reveal.scores.background};

    .allTopics__header {
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};
      font-size: 64px;
      color: ${(props) => props.theme.color.reveal.scores.text};
      font-weight: bold;
      padding-top: ${(props) => props.theme.variable.spacing.xxl};
      padding-bottom: ${(props) => props.theme.variable.spacing.xl};
    }
  `,
};

export const AllTopicReveal: React.FC = () => {
  const theme = useDevExTheme();
  //fetch study data
  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef: string }>();
  const { data: topicScores } = useStudyScores({
    studyRef: studyRef,
    teamRef: teamRef,
    type: 'TOPIC',
  });
  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const sortedTopics = topicScores?.sort((a, b) => b.scaledAvg - a.scaledAvg);
  const topicsBySlug = keyBy(topics, 'slug');

  const targetRef = useRef(null);

  usePageIndexTracker(targetRef, 'all-topics');
  const studyRevealState = useStudyRevealState();

  return (
    <div ref={targetRef}>
      <div
        style={{
          height: '50vh',
          backgroundColor: theme.color.reveal.scores.background,
        }}
      />
      <div css={styles.allTopics}>
        <Flex
          align="center"
          justify="center"
          style={{ position: 'sticky', top: theme.variable.spacing.lg }}
        >
          <Flex vertical>
            {studyRevealState.pageSlug === 'all-topics' && (
              <>
                <motion.div
                  className="allTopics__header"
                  initial={{ opacity: 0, x: 50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  Your Topics
                </motion.div>
                <Flex vertical gap={theme.variable.spacing.lg}>
                  {sortedTopics?.map((score, i) => (
                    <Flex key={i} justify="center" gap={theme.variable.spacing.sm}>
                      <Flex vertical justify="center">
                        <motion.div
                          key={i}
                          initial={{ opacity: 0, x: 50 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.5, delay: i * 0.1 + 0.8 }}
                          style={{
                            width: '350px',
                            fontSize: theme.variable.fontSize.xl,
                            color: theme.color.reveal.scores.text,
                          }}
                        >
                          <div> {topicsBySlug[score.slug]?.label} </div>
                        </motion.div>
                      </Flex>
                      <Flex vertical align="flex-end" justify="flex-end">
                        <motion.div
                          key={i}
                          initial={{ opacity: 0, x: 50 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.5, delay: i * 0.1 + 0.8 }}
                          style={{ width: '150px' }}
                        >
                          <div>
                            <ScoreBlock score={score.scaledAvg} size={30} />
                          </div>
                        </motion.div>
                      </Flex>
                      <Flex vertical justify="center">
                        <motion.div
                          key={i}
                          initial={{ opacity: 0, x: 50 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.5, delay: i * 0.1 + 0.8 }}
                          style={{ width: '500px' }}
                        >
                          <div>
                            <FreqDistBar freqDist={score.freqDist} />
                          </div>
                        </motion.div>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </div>
      <div
        style={{
          height: '10vh',
          backgroundColor: theme.color.reveal.scores.background,
        }}
      />
    </div>
  );
};
