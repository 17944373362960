import React, { ReactNode } from 'react';
import { Flex } from 'antd';
import { css } from 'styled-components';

const styles = {
  header: css`
    position: relative;
    border-bottom: 1px solid ${(props) => props.theme.color.border.primary};
    padding: ${(props) => props.theme.variable.spacing.lg};
    width: 100%;
    z-index: 500;

    .header__left,
    .header__right {
      position: absolute;
      top: ${(props) => props.theme.variable.spacing.lg};
    }

    .header__left {
      left: ${(props) => props.theme.variable.spacing.lg};
    }

    .header__right {
      right: ${(props) => props.theme.variable.spacing.lg};
    }

    .header__title-container {
      min-height: 40px;
      overflow: hidden;

      .header__title {
        font-family: ${(props) => props.theme.variable.fontFamily.secondary};
        font-size: ${(props) => props.theme.variable.fontSize.xl};
        font-weight: 500;
        overflow: hidden;
      }
    }

    .header__subtitle {
      padding-top: ${(props) => props.theme.variable.spacing.md};
      font-size: ${(props) => props.theme.variable.fontSize.xs};
    }
  `,
};

type DevExHeaderProps = {
  left?: ReactNode;
  right?: ReactNode;
  style?: React.CSSProperties;
  subtitle?: ReactNode;
};

export const DevExHeader: React.FC<DevExHeaderProps> = (props) => {
  return (
    <Flex justify="center" align="center" css={styles.header} style={props.style}>
      <div className="header__left">{props.left}</div>
      <Flex vertical align="center" className="header__title-container">
        <div className="header__title">{props.children}</div>
        {!!props.subtitle && <div className="header__subtitle">{props.subtitle}</div>}
      </Flex>
      <div className="header__right">{props.right}</div>
    </Flex>
  );
};
