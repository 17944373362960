import { useState } from 'react';
import React from 'react';
import { keyBy } from 'lodash-es';
import { useQueryClient } from 'react-query';

import { SlackTargetingClient, StudySlackTarget, UpdatedStudyRequest } from 'jf/api';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

type _StudyUpdaterContext = {
  studyRequest?: UpdatedStudyRequest;
  setStudyRequest: React.Dispatch<React.SetStateAction<UpdatedStudyRequest>>;
};

const StudyUpdaterContext = React.createContext<_StudyUpdaterContext>({
  setStudyRequest: () => {},
});

export const StudyUpdaterProvider: React.FC = (props) => {
  const [studyRequest, setStudyRequest] = useState<UpdatedStudyRequest>();

  return (
    <StudyUpdaterContext.Provider value={{ studyRequest, setStudyRequest }}>
      {props.children}
    </StudyUpdaterContext.Provider>
  );
};

export const useStudySlackTargetUpdater = (studyRef: string | undefined) => {
  const queryClient = useQueryClient();

  const { data: slackTargets } = useClientQuery(SlackTargetingClient.getStudySlackTargets, {
    studyRef,
  });

  const { mutateAsync: createSlackTarget } = useClientMutation(
    SlackTargetingClient.createStudySlackTarget
  );
  const { mutateAsync: deleteSlackTargets } = useClientMutation(
    SlackTargetingClient.deleteStudySlackTargets
  );

  const update = async (newSlackTargets: StudySlackTarget[]) => {
    if (!slackTargets) {
      return;
    }

    const slackTargetsById = keyBy(slackTargets, 'id');
    const newSlackTargetsById = keyBy(newSlackTargets, 'id');

    const queries: Promise<any>[] = [];

    // create new targets
    for (const newSlackTarget of newSlackTargets) {
      if (!slackTargetsById[newSlackTarget.id]) {
        queries.push(
          createSlackTarget({
            studyRef: studyRef!,
            requestBody: newSlackTarget,
          })
        );
      }
    }

    // delete removed targets
    const deletedSlackTargets = slackTargets.filter((target) => !newSlackTargetsById[target.id]);
    queries.push(
      deleteSlackTargets({
        studyRef: studyRef!,
        slackTargetId: deletedSlackTargets.map((target) => target.id),
      })
    );

    if (queries.length) {
      return Promise.allSettled(queries).then(() =>
        queryClient.refetchQueries(['GET_STUDY_SLACK_TARGETS', studyRef])
      );
    }
  };

  return { update };
};
