/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdatedStudyRequest = {
    name?: string;
    notes?: string;
    welcomeMessage?: string;
    flags?: Array<string>;
    sequencedPrompts?: Array<string>;
    sequenceRef?: string | null;
    /**
     * * `DRAFT` - DRAFT
     * * `OPEN` - OPEN
     * * `CLOSED` - CLOSED
     * * `DELETED` - DELETED
     */
    status?: UpdatedStudyRequest.status;
    targetResponses?: number | null;
};

export namespace UpdatedStudyRequest {

    /**
     * * `DRAFT` - DRAFT
     * * `OPEN` - OPEN
     * * `CLOSED` - CLOSED
     * * `DELETED` - DELETED
     */
    export enum status {
        DRAFT = 'DRAFT',
        OPEN = 'OPEN',
        CLOSED = 'CLOSED',
        DELETED = 'DELETED',
    }


}

