import React from 'react';
import { faStar as faStarRegular } from '@fortawesome/pro-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { css } from 'styled-components';

const styles = {
  star: css`
    cursor: pointer;
    color: ${(props) => props.theme.color.star.border.default};

    &:hover {
      transform: scale(1.05);
      color: ${(props) => props.theme.color.star.border.hovered};
    }

    &.star--active {
      color: ${(props) => props.theme.color.star.text.default};

      &:hover {
        color: ${(props) => props.theme.color.star.text.hovered};
      }
    }
  `,
};

type DevExStarProps = {
  active: boolean;
  onChange: (active: boolean) => void;
  className?: string;
};

export const DevExStar: React.FC<DevExStarProps> = (props) => {
  return (
    <FontAwesomeIcon
      icon={props.active ? faStarSolid : faStarRegular}
      onClick={(event) => {
        event.stopPropagation();
        props.onChange(!props.active);
      }}
      css={styles.star}
      className={classNames(props.className, { 'star--active': props.active })}
    />
  );
};
