import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SlackTargetingClient, Study, StudyClient } from 'jf/api';
import { DevExPageLayout } from 'jf/common/DevExPageLayout';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { useClientFlags } from 'jf/utils/useClientFlags';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { makeStudyAnalyzerPath } from '../StudyAnalyzerPage';
import { TeamCascader } from '../TeamCascader';

import { ParticipationBySegmentCard } from './ParticipationBySegmentCard';
import { ParticipationCard } from './ParticipationCard';
import { SlackDisconnectedModal } from './SlackDisconnectedModal';

export const ParticipationTab: React.FC = () => {
  const flags = useClientFlags();
  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef: string }>();
  const navigate = useNavigate();
  const slackDisconnectedModal = useJFOverlay(SlackDisconnectedModal);
  const { data: slackStatus } = useClientQuery(SlackTargetingClient.getSlackStatus);

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });
  const { data: slackTargets } = useClientQuery(
    SlackTargetingClient.getStudySlackTargets,
    { studyRef: studyRef },
    { enabled: flags.slackTargeting }
  );

  const isClosed = study?.status === Study.status.CLOSED;
  const showTeams = isClosed;

  useEffect(() => {
    // check if Slack is disconnected on a Slack-targeted survey
    if (
      study?.status === Study.status.OPEN &&
      slackTargets?.length &&
      slackStatus?.connected === false
    ) {
      slackDisconnectedModal.open();
    }
  }, [study, slackTargets, slackStatus]);

  return (
    <DevExPageLayout>
      {showTeams && (
        <TeamCascader
          studyRef={studyRef}
          value={teamRef}
          onChange={(teamRef) =>
            navigate(makeStudyAnalyzerPath(studyRef, teamRef, 'participation'))
          }
          allowClear
        />
      )}
      <ParticipationCard studyRef={studyRef} teamRef={teamRef} />
      {study && <ParticipationBySegmentCard studyRef={studyRef} teamRef={teamRef} />}
    </DevExPageLayout>
  );
};
