import { _FOUNDATIONS } from 'jf/common/themes/foundations';

export const invertDevExThemeColor = (color: string, themeKey: string) => {
  if (color[0] !== '#') {
    return _FOUNDATIONS.neutral[1];
  }
  const [r, g, b, a] = [0, 2, 4, 6].map(
    (i) => (parseInt(color.slice(i + 1, i + 3), 16) || 255) / 255
  );
  let lightness = (r + g + b) / 3;
  if (themeKey === 'dark') {
    lightness = lightness * a; // shift to 0 with no opacity
  } else {
    lightness = lightness + (1 - lightness) * (1 - a); // shift 1 with no opacity
  }
  return lightness > 0.55 ? _FOUNDATIONS.neutral[15] : _FOUNDATIONS.neutral[1];
};
