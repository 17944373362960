import React from 'react';
import { Flex } from 'antd';
import { faLightbulbOn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyBuilderState } from '../StudyBuilderState';

import { StudyStepsPanel } from './studyStepsPanel/StudyStepsPanel';
import { CustomizableDemographic } from './CustomizableDemographic';
import { CustomizableIntroduction } from './CustomizableIntroduction';
import { CustomizablePrompt } from './CustomizablePrompt';
import { CustomizableVoting } from './CustomizableVoting';
import { TopicHygienePanel } from './TopicHygienePanel';

export const CustomizeStudyStep: React.FC = () => {
  const theme = useDevExTheme();
  const state = useStudyBuilderState();

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: state.studyRef });

  return (
    <Flex style={{ flex: 1 }}>
      <StudyStepsPanel />

      <Flex
        align="center"
        justify="center"
        style={{ paddingInline: theme.variable.spacing.xl, flex: 1 }}
      >
        <div style={{ maxWidth: 800, flex: 1, paddingBlock: theme.variable.spacing.xl }}>
          {state.studyStep.type === 'INTRO' && <CustomizableIntroduction />}
          {state.studyStep.type === 'DEMOGRAPHIC' && <CustomizableDemographic />}
          {state.studyStep.type === 'PROMPT' && (
            <CustomizablePrompt promptSlug={state.studyStep.key} />
          )}
          {state.studyStep.type === 'VOTING' && <CustomizableVoting />}
        </div>
      </Flex>

      {study && (
        <TopicHygienePanel
          icon={<FontAwesomeIcon icon={faLightbulbOn} />}
          title="Insights"
          promptSlugs={state.promptSlugs}
          collapsible
        />
      )}
    </Flex>
  );
};
