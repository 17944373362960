import React from 'react';
import { Flex } from 'antd';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Team } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';

import { teamCardStyles } from './ChildTeamCard';

type ParentTeamCardProps = {
  team: Team;
  onAdd?: () => void;
};

export const ParentTeamCard: React.FC<ParentTeamCardProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <Flex gap={theme.variable.spacing.xs} style={{ width: '50%' }}>
      <DevExCard
        size="small"
        title={props.team.value}
        css={teamCardStyles.teamCard}
        style={{ background: theme.color.background.sunken }}
      />

      <DevExButton
        type="text"
        icon={<FontAwesomeIcon icon={faPlus} />}
        style={{
          // use visibility to keep card widths aligned
          visibility: !props.onAdd ? 'hidden' : undefined,
        }}
        onClick={() => props.onAdd?.()}
      />
    </Flex>
  );
};
