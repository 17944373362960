/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Location, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { trackPage } from 'jf/analytics/Analytics';

import { refreshOpenAPIHeaders } from './DevEx';
import { DevExOutlet } from './DevExOutlet';
import { JFOverlayController } from './useJFOverlay';

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    position: relative;
    margin: 0;
    max-width: 100vw;

    background: ${(props) => props.theme.color.background.default};
    color: ${(props) => props.theme.color.text.primary};
    line-height: 1;
    font-size: ${(props) => props.theme.variable.fontSize.sm};
  }

  * {
    &::-webkit-scrollbar {
      width: 18px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.color.scrollbar.thumb.default};
      border: 5px solid transparent;
      border-radius: 20px;
      background-clip: content-box;
      opacity: 0;
      
      &:hover {
        background-color: ${(props) => props.theme.color.scrollbar.thumb.hovered};
      }
    }

    &::-webkit-scrollbar-corner {
      background: none;
    }
  }

  &:has(#survey-reveal) {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  body {
    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.color.scrollbar.track.default};
    }
  }

  body,
  button,
  input {
    font-family: ${(props) => props.theme.variable.fontFamily.primary};
  }

  p {
    margin: 0;
  }

  .ant-tooltip {
    filter: drop-shadow(0 0 ${(props) => props.theme.variable.spacing.md} ${(props) => props.theme.color.shadow.default});

    .ant-tooltip-arrow::before, .ant-tooltip-inner {
      background-color: ${(props) => props.theme.color.tooltip.background};
      color: ${(props) => props.theme.color.tooltip.text};
      box-shadow: none;
    }
  }
`;

const PendoAttachment = styled.div`
  position: fixed;
  right: ${(props) => props.theme.variable.spacing.xl};
  bottom: ${(props) => props.theme.variable.spacing.xl};
  z-index: 999;
  // width and height required for pendo to see this element as "visible"
  width: 1px;
  height: 1px;
`;

type DevExWrapperProps = {
  showNav?: boolean; // show DevExNavBar
  reqAuth?: boolean; // require authentication to see page
  reqNoAuth?: boolean; // require unauthentication to see page
};

export const DevExWrapper: React.FC<DevExWrapperProps> = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState<Location<any>>(location);

  const scopeToken = searchParams.get('scope_token');

  useEffect(() => {
    // ensure ScopeToken gets added/removed from Authorization header when needed
    refreshOpenAPIHeaders();
  }, [scopeToken]);

  const redirectPath = useMemo(() => {
    const authenticated = !!window.dx.user || scopeToken;

    const reqAuthViolated = !!props.reqAuth && !authenticated;
    const reqNoAuthViolated = !!props.reqNoAuth && authenticated;

    const isNotSetup = window.dx.user?.isSetup === false;
    const isNotOnboarded =
      window.dx.user?.isOnboarded === false || window.dx.user?.company.isOnboarded === false;

    const reqSetup = !!props.reqAuth && isNotSetup;
    const reqOnboarding = !!props.reqAuth && !reqSetup && isNotOnboarded;

    const reqSetupViolated = reqSetup && location.pathname !== '/auth/set-password';
    const reqOnboardingViolated = reqOnboarding && location.pathname !== '/auth/onboarding';

    if (reqAuthViolated) {
      return '/auth/sign-in';
    } else if (reqNoAuthViolated) {
      return '/studies';
    } else if (reqSetupViolated) {
      return '/auth/set-password';
    } else if (location.pathname === '/auth/set-password') {
      // avoid redirecting away from Reset Password flow
      return undefined;
    } else if (reqOnboardingViolated) {
      return '/auth/onboarding';
    }
  }, [location]);

  useEffect(() => redirectPath && navigate(redirectPath), [redirectPath]);

  useEffect(() => {
    const url = window.location.origin + location.pathname + location.search;
    const prevUrl = window.location.origin + prevLocation.pathname + prevLocation.search;

    // only track the pageEvent if the URL has changed
    if (url !== prevUrl) {
      trackPage({
        referrer: prevUrl,
        url: url,
        path: location.pathname,
        queryString: location.search,
      });
    }

    setPrevLocation(location);
  }, [location]);

  if (redirectPath) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Jellyfish DevEx</title>
      </Helmet>

      <GlobalStyle />

      <PendoAttachment data-pendo="attachment-bottom-right" />

      <JFOverlayController>
        <DevExOutlet reqAuth={props.reqAuth} showNav={props.showNav} />
      </JFOverlayController>
    </>
  );
};
