import React, { useState } from 'react';
import { Flex } from 'antd';
import { Outlet } from 'react-router-dom';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExDivider } from 'jf/components/DevExDivider';
import { DevExLogo } from 'jf/components/DevExLogo';
import { FlatTentacles } from 'jf/components/FlatTentacles';

import AndrewKorbelHeadshot from 'jf/images/andrew-korbel.png';
import BarChartIcon from 'jf/icons/BarChart.svg';
import ConversationIcon from 'jf/icons/Conversation.svg';
import SpeechIcon from 'jf/icons/Speech.svg';
import ClariLogo from 'jf/images/clari-logo.svg';

export const authStyles = {
  page: css`
    padding: ${(props) => props.theme.variable.spacing.xl};
    flex: 1;

    > * {
      flex: 1;
    }

    .page__advert {
      max-width: 620px;
      padding: ${(props) => props.theme.variable.spacing.xl};
      overflow: hidden;
      border: none;

      .card__body {
        font-family: ${(props) => props.theme.variable.fontFamily.secondary};
        line-height: ${(props) => props.theme.variable.lineHeight};
        padding-bottom: ${(props) => props.theme.variable.spacing.xxl};

        .advert__title {
          font-size: ${(props) => props.theme.variable.fontSize.xl};

          span {
            color: ${(props) => props.theme.color.offbrand.default};
          }
        }

        .advert__feature {
          gap: ${(props) => props.theme.variable.spacing.md};
          align-items: center;

          svg {
            font-size: 36px;
            color: ${(props) => props.theme.color.offbrand.default};
          }
        }
      }
    }
  `,
  form: css`
    padding-block: ${(props) => props.theme.variable.spacing.lg};
    padding-inline: ${(props) => props.theme.variable.spacing.xl};
    width: 380px;

    .card__body {
      gap: ${(props) => props.theme.variable.spacing.lg};

      .card__header {
        text-align: center;
        padding-bottom: ${(props) => props.theme.variable.spacing.md};

        .header__title {
          font-family: ${(props) => props.theme.variable.fontFamily.secondary};
          font-size: ${(props) => props.theme.variable.fontSize.xl};
          font-weight: 500;
          line-height: ${(props) => props.theme.variable.lineHeight};
        }
      }

      .card__message {
        font-size: ${(props) => props.theme.variable.fontSize.xs};
        line-height: ${(props) => props.theme.variable.lineHeight};
        color: ${(props) => props.theme.color.text.secondary};
        margin-top: ${(props) => `-${props.theme.variable.spacing.xs}`};

        &.message--error {
          color: ${(props) => props.theme.color.status.error.text};
        }
      }

      button {
        justify-content: center;
      }

      label {
        font-size: ${(props) => props.theme.variable.fontSize.xs};
      }

      .card__footer {
        font-size: ${(props) => props.theme.variable.fontSize.xs};
        line-height: ${(props) => props.theme.variable.lineHeight};
        color: ${(props) => props.theme.color.text.secondary};
        text-align: center;
        padding-top: ${(props) => props.theme.variable.spacing.md};
      }
    }
  `,
};

export const EMAIL_PLACEHOLDER = 'name@company.com';

type _AuthFormContext = {
  email: string;
  setEmail: (email: string) => void;
};

export const AuthFormContext = React.createContext<_AuthFormContext>({
  email: '',
  setEmail: () => {},
});

export const AuthPage: React.FC = () => {
  const theme = useDevExTheme();
  const [email, setEmail] = useState('');

  // don't show advert at /auth/set-password
  const showAdvert = !window.dx.user;

  return (
    <Flex justify="center" gap={theme.variable.spacing.xxl} css={authStyles.page}>
      <Flex vertical align="center">
        <DevExLogo style={{ paddingBlock: theme.variable.spacing.xl }} />

        <AuthFormContext.Provider value={{ email, setEmail }}>
          <Outlet />
        </AuthFormContext.Provider>
      </Flex>

      {showAdvert && (
        <DevExCard className="page__advert">
          <FlatTentacles
            placement="bottomRight"
            absolute
            css={css`
              svg {
                bottom: -200px !important;
              }
            `}
          />

          <div className="advert__title">
            Improving <span>Developer Experience</span>
            <br /> just got easier!
          </div>

          <div style={{ paddingBottom: theme.variable.spacing.lg }}>
            Start with Jellyfish DevEx in minutes! It's free — no contract required.
          </div>

          <Flex className="advert__feature">
            <SpeechIcon />
            Surveys that get to the root of your engineers' sentiment
          </Flex>

          <Flex className="advert__feature">
            <BarChartIcon />
            Easily make sense of signals through custom segmenting
          </Flex>

          <Flex className="advert__feature">
            <ConversationIcon />
            Actionable insights to improve developer experience
          </Flex>

          <DevExDivider />

          <ClariLogo width={100} />

          <div>
            “Jellyfish DevEx has helped us identify that some of our platform documentation has been
            lacking. We now have an effort to create more documentation as we build to help other
            developers onboard"
          </div>

          <Flex align="center" gap={theme.variable.spacing.md}>
            <img
              src={AndrewKorbelHeadshot}
              alt="Andrew Korbel"
              style={{ borderRadius: theme.variable.borderRadius, height: 64 }}
            />
            <Flex vertical>
              <strong style={{ fontWeight: 600 }}>Andrew Korbel</strong>
              <div>VP R&D Operations & Chief of Staff</div>
              <div>Clari</div>
            </Flex>
          </Flex>
        </DevExCard>
      )}
    </Flex>
  );
};
