import React from 'react';
import { Flex } from 'antd';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';

type CircledFontAwesomeIconProps = {
  icon: IconDefinition;
  style?: React.CSSProperties;
  iconSize?: number;
};

const DEFAULT_SIZE = 44;

export const CircledFontAwesomeIcon: React.FC<CircledFontAwesomeIconProps> = (props) => {
  const theme = useDevExTheme();
  const size = props.iconSize ? props.iconSize : DEFAULT_SIZE;
  return (
    <Flex
      align="center"
      justify="center"
      style={{
        padding: size / 2,
        borderRadius: 100,
        background: theme.color.background.sunken,
        ...props.style,
      }}
    >
      <FontAwesomeIcon icon={props.icon} style={{ fontSize: size, width: size, height: size }} />
    </Flex>
  );
};
