/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Segmented, SegmentedProps } from 'antd';
import { css } from 'styled-components';

const styles = {
  segmented: css`
    background-color: ${(props) => props.theme.color.background.sunken};
    transition: none;

    .ant-segmented-thumb {
      background-color: ${(props) => props.theme.color.background.raised};
    }

    .ant-segmented-item {
      transition-duration: 0ms;
    }

    .ant-segmented-item.ant-segmented-item-selected {
      color: ${(props) => props.theme.color.text.primary};
      background-color: ${(props) => props.theme.color.background.raised};
    }

    .ant-segmented-item:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled) {
      color: ${(props) => props.theme.color.text.secondary} !important;
    }

    .ant-segmented-item.ant-segmented-item-disabled {
      color: ${(props) => props.theme.color.text.disabled};
    }
  `,
};

export type DevExSegmentedOption<T = string> = {
  value: T;
  label: string;
  disabled?: boolean;
};

interface DevExSegmentedProps<T extends string>
  extends Omit<SegmentedProps, 'options' | 'onChange'> {
  ref?: React.Ref<HTMLDivElement>; // Segmented is being whiny about this type not being legacy
  options: DevExSegmentedOption<T>[];
  onChange: (value: T) => void;
}

export const DevExSegmented = <T extends string>(props: DevExSegmentedProps<T>) => {
  // autoselect first option
  useEffect(() => {
    if (props.options.length) {
      props.onChange?.(props.options[0].value);
    }
  }, [props.options.length]);

  return <Segmented css={styles.segmented} {...props} />;
};
