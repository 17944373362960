import React, { useEffect } from 'react';
import { useState } from 'react';
import { Flex, Tooltip } from 'antd';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import {
  faLock,
  faMagnifyingGlass,
  faUsersSlash,
  IconDefinition,
} from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { MetricsClient, RosterClient, StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExFeedback } from 'jf/components/DevExFeedback';
import { DevExLink } from 'jf/components/DevExLink';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { ThumbsFeedback } from 'jf/components/ThumbsFeedback';
import { onLoginWithJellyfish } from 'jf/pages/auth/SignInForm';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { MetricCard } from './MetricCard';

export type MetricTopicSlug = Parameters<typeof MetricsClient.getTeamMetrics>[0]['topicSlug'];

type RelatedMetricsProps = {
  studyRef: string;
  teamRef?: string;
  topicSlug: MetricTopicSlug;
};

export const TopicMetrics: React.FC<RelatedMetricsProps> = (props) => {
  const theme = useDevExTheme();
  const location = useLocation();

  const isSignedIn = !!window.dx.user;
  const onSignIn = () => {
    trackEvent('survey-analyzer:topic-metric:sign-in');
    onLoginWithJellyfish(location.pathname);
  };

  const { isLoading } = useClientQuery(
    MetricsClient.getTeamMetrics,
    {
      studyRef: props.studyRef,
      topicSlug: props.topicSlug,
      ...(props.teamRef ? { teamRef: props.teamRef } : {}),
    },
    { enabled: isSignedIn }
  );

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: props.studyRef });
  const closeDate = new Date(study?.closeDate!);
  const closeDateStr = closeDate.toLocaleString('default', { month: 'long', year: 'numeric' });

  return (
    <Flex vertical gap={theme.variable.spacing.sm}>
      <>
        {isLoading ? (
          <DevExSkeleton width={180} fontSize="sm" />
        ) : (
          <Flex
            style={{ color: theme.color.text.secondary }}
            align="center"
            gap={theme.variable.spacing.sm}
          >
            <div style={{ fontWeight: '600' }}>Related Metrics</div>
            <Tooltip
              title={`Metrics captured ${closeDateStr}, the last full month before this survey closed. Click a card to explore the metric in Jellyfish.`}
              placement="top"
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{
                  color: theme.color.text.secondary,
                }}
              />
            </Tooltip>
          </Flex>
        )}
        {!isSignedIn ? (
          <DevExEmpty
            icon={faLock}
            style={{
              paddingTop: theme.variable.spacing.sm,
              paddingBottom: theme.variable.spacing.sm,
            }}
          >
            <div style={{ alignSelf: 'center', color: theme.color.text.tertiary }}>
              You need to be <DevExLink onClick={onSignIn}>signed in</DevExLink> to view Jellyfish
              data
            </div>
          </DevExEmpty>
        ) : (
          <MetricCardSection {...props} />
        )}
      </>
    </Flex>
  );
};

type MetricCardSectionProps = {} & RelatedMetricsProps;

const MetricCardSection: React.FC<MetricCardSectionProps> = ({ studyRef, topicSlug, teamRef }) => {
  const {
    data: teamMetrics,
    isLoading: isMetricsLoading,
    error,
  } = useClientQuery(MetricsClient.getTeamMetrics, {
    studyRef: studyRef,
    topicSlug: topicSlug,
    ...(teamRef ? { teamRef } : {}),
  });

  const { data: teamData, isLoading: isTeamLoading } = useClientQuery(RosterClient.getTeam, {
    teamRef,
  });
  const theme = useDevExTheme();
  const hasExpandedView = teamMetrics && teamMetrics?.keyMetrics?.length > 2;

  const [isExpanded, setIsExpanded] = useState(false);

  // Show all metrics if the view has been expanded to "Show more" by the user
  // otherwise only the first two metrics will be shown
  const metricsToShow =
    hasExpandedView && !isExpanded ? teamMetrics?.keyMetrics?.slice(0, 2) : teamMetrics?.keyMetrics;
  const hasMetrics = teamMetrics && teamMetrics.keyMetrics?.length > 0 && error?.status !== 400;

  const getEmptyStateDetails = (): {
    label: string;
    icon: IconDefinition;
    children?: React.ReactNode;
  } => {
    if (teamRef && !teamData?.isEmp) {
      return {
        label: 'This DevEx team is not defined in Jellyfish.',
        icon: faUsersSlash,
        children: (
          <div style={{ alignSelf: 'center', color: theme.color.text.secondary }}>
            <DevExFeedback
              prompt="Want to tell us more about this DevEx-only team?"
              placeholder="Share more details about this DevEx-only team"
              eventName={`survey-analyzer:no-team:feedback`}
              eventContext={{ teamRef }}
            />
          </div>
        ),
      };
    }

    return {
      label: "We couldn't find any metrics related to this topic",
      icon: faMagnifyingGlass,
      children: (
        <div style={{ alignSelf: 'center', color: theme.color.text.secondary }}>
          <DevExFeedback
            prompt="Which metrics would you like to see here?"
            placeholder="What's on your mind?"
            eventName={`survey-analyzer:no-metric:feedback`}
            eventContext={{ topicSlug }}
          />
        </div>
      ),
    };
  };

  const emptyStateDetails = getEmptyStateDetails();

  const isLoading = isMetricsLoading || isTeamLoading;
  const isMetricCardSectionVisible = !((teamRef && !teamData?.isEmp) || !hasMetrics);

  useEffect(() => {
    if (!isLoading) {
      trackEvent('survey-analyzer:topic-metric:show', {
        visible: isMetricCardSectionVisible,
      });
    }
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <DevExSkeleton height={'160px'} />
      ) : (
        <>
          {!isMetricCardSectionVisible ? (
            <DevExCard>
              <DevExEmpty
                icon={emptyStateDetails.icon}
                label={emptyStateDetails.label}
                iconSize={32}
                style={{
                  paddingTop: theme.variable.spacing.sm,
                  paddingBottom: theme.variable.spacing.sm,
                }}
              >
                {!!emptyStateDetails?.children && emptyStateDetails.children}
              </DevExEmpty>
            </DevExCard>
          ) : (
            <div
              style={{
                gap: theme.variable.spacing.md,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
              }}
            >
              {(metricsToShow || []).map((metric) => (
                <MetricCard key={metric.slug} metric={metric} topicSlug={topicSlug} />
              ))}
            </div>
          )}
          {!!hasExpandedView && (
            <Flex
              style={{
                alignSelf: 'flex-end',
                gap: theme.variable.spacing.sm,
                color: theme.color.text.secondary,
                cursor: 'pointer',
              }}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              Show {isExpanded ? 'less' : 'more'}
            </Flex>
          )}
        </>
      )}
      {hasMetrics && !isLoading && (
        <ThumbsFeedback title={'Do you find these metrics useful?'} eventName={'topic-metrics'} />
      )}
    </>
  );
};
