import React, { useEffect, useState } from 'react';
import { Flex, Tooltip } from 'antd';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { SlackTargetingClient, StudyClient, StudySlackTarget } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExExpandableAlert, DevExExpandableAlertType } from 'jf/components/DevExExpandableAlert';
import { DevExModal } from 'jf/components/DevExModal';
import { DevExTag } from 'jf/components/DevExTag';
import { DevExText } from 'jf/components/DevExText';
import { formatISO } from 'jf/utils/formatISO';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import { SlackTargetEditor } from '../studyBuilder/targetRespondents/SlackTargetEditor';
import { useStudySlackTargetUpdater } from '../studyBuilder/targetRespondents/useStudySlackTargetUpdater';

import { useStudyParticipation } from './useStudyParticipation';

export const SendSlackReminderModal: React.FC = () => {
  const modal = useJFOverlay(SendSlackReminderModal);
  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef?: string }>();
  const theme = useDevExTheme();
  const studySlackTargetUpdater = useStudySlackTargetUpdater(studyRef);
  const queryClient = useQueryClient();

  const [unsavedSlackTargets, setUnsavedSlackTargets] = useState<StudySlackTarget[]>();

  const [isEditing, setIsEditing] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const { data: slackTargets } = useClientQuery(SlackTargetingClient.getStudySlackTargets, {
    studyRef,
  });

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });

  const { data: participation } = useStudyParticipation({ studyRef, teamRef });

  const { mutateAsync: sendStudyMessages } = useClientMutation(
    SlackTargetingClient.sendStudyMessages
  );

  const onSend = async (inviteOnly: boolean) => {
    setIsSending(true);

    if (isEditing && unsavedSlackTargets) {
      await studySlackTargetUpdater.update(unsavedSlackTargets);
    }

    await sendStudyMessages({ studyRef: studyRef!, inviteOnly });
    queryClient.invalidateQueries(['GET_STUDY', studyRef]);

    trackEvent(`survey-analyzer:slack-reminder:send`, {
      responseRate: (study && slackTargets?.length && participation
        ? participation.uniqueResponses / slackTargets.length
        : 0
      ).toFixed(1),
    });

    setIsSending(false);
    modal.close();
  };

  useEffect(() => {
    if (!unsavedSlackTargets) {
      setUnsavedSlackTargets(slackTargets);
    }
  }, [slackTargets]);

  useEffect(() => {
    setIsEditing(false);
    setIsSending(false);
    setUnsavedSlackTargets(slackTargets);
  }, [modal.isOpened]);

  const sentAt = study?.sentAt && DateTime.fromISO(study.sentAt);
  const sentAgoMinutes = sentAt ? DateTime.now().diff(sentAt, 'minute').minutes : 0;
  const sendDisabled = sentAgoMinutes < 60;

  const newTargetCount = (unsavedSlackTargets?.length ?? 0) - (slackTargets?.length ?? 0);

  return (
    <DevExModal
      open={modal.isOpened}
      onCancel={() => modal.close()}
      title="Send reminder"
      width={600}
      footer={
        <Flex vertical align="end" gap={theme.variable.spacing.sm}>
          <DevExText type="secondary" fontSize="xs">
            Last sent {formatISO(study?.sentAt, DateTime.DATETIME_MED)}
          </DevExText>

          <Flex gap={theme.variable.spacing.sm}>
            {newTargetCount > 0 && (
              <DevExButton type="outline" disabled={isSending} onClick={() => onSend(true)}>
                Send to {newTargetCount} new target{newTargetCount === 1 ? '' : 's'} only
              </DevExButton>
            )}
            <Tooltip
              title={sendDisabled ? 'You must wait at least an hour to send a new reminder.' : ''}
            >
              <DevExButton
                type="primary"
                loading={isSending}
                onClick={() => onSend(false)}
                disabled={sendDisabled}
              >
                {newTargetCount > 0 ? 'Send to all' : 'Send'}
              </DevExButton>
            </Tooltip>
          </Flex>
        </Flex>
      }
    >
      <Flex vertical gap={theme.variable.spacing.md}>
        {sentAgoMinutes < 1440 && (
          <DevExExpandableAlert
            title="The last Slack message was sent less than 24 hours ago. We recommend waiting before sending a new reminder."
            type={DevExExpandableAlertType.WARNING}
          />
        )}

        <div>Send a reminder in Slack to target respondents that have not yet responded.</div>

        {!isEditing ? (
          <Flex wrap="wrap" gap={theme.variable.spacing.sm}>
            {slackTargets?.map((target) => (
              <DevExTag key={target.id} color="purple">
                {target.displayName}
              </DevExTag>
            ))}
            <DevExButton
              size="small"
              type="text"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => setIsEditing(true)}
            >
              Add target
            </DevExButton>
          </Flex>
        ) : (
          unsavedSlackTargets && (
            <SlackTargetEditor
              slackTargets={unsavedSlackTargets}
              onChange={setUnsavedSlackTargets}
              preventDeletion
              eventCategory="survey-analyzer"
            />
          )
        )}
      </Flex>
    </DevExModal>
  );
};
