import React from 'react';
import { Flex } from 'antd';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';

type DevExColumnDashProps = {
  centered?: boolean;
};

export const DevExDash: React.FC<DevExColumnDashProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <Flex
      style={{
        display: props.centered ? 'flex' : 'inline-flex',
        color: theme.color.text.tertiary,
      }}
      justify={props.centered ? 'center' : undefined}
    >
      &mdash;
    </Flex>
  );
};
