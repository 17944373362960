import React, { useEffect } from 'react';
import { Flex } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import { css } from 'styled-components';

import { DevExIncidentBanner } from './DevExIncidentBanner';
import { DevExNavBar, NAV_BAR_HEIGHT } from './DevExNavBar';

const styles = {
  outlet: css`
    overflow: hidden;

    &:has(#survey-reveal) {
      overflow: unset;
    }
  `,
};

type DevExOutletProps = {
  reqAuth?: boolean;
  showNav?: boolean;
};

export const DevExOutlet: React.FC<DevExOutletProps> = (props) => {
  const { pathname } = useLocation();

  // scroll to top when changing page
  useEffect(() => window.scrollTo(0, 0), [pathname]);

  return (
    <React.Suspense fallback={[]}>
      <div css={styles.outlet}>
        {props.showNav && <DevExNavBar key="navbar" />}
        <Flex
          vertical
          style={{
            minHeight: '100vh',
            paddingTop: props.showNav ? NAV_BAR_HEIGHT : 0,
          }}
        >
          {props.reqAuth && <DevExIncidentBanner />}
          <Outlet />
        </Flex>
      </div>
    </React.Suspense>
  );
};
