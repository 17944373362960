import React, { useMemo } from 'react';
import { Flex } from 'antd';
import { groupBy, mapValues } from 'lodash-es';
import { DateTime } from 'luxon';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExRechartsTooltipContent } from 'jf/components/DevExRechartsTooltipContent';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';

import { useStudyParticipation } from '../useStudyParticipation';

const CHART_SPAN_DAYS = 12;

type ParticipationDatum = {
  value?: number;
  dateTimeMillis: number;
  dateTime: DateTime;
};

type ParticipationChartProps = {
  studyRef: string | undefined;
  teamRef?: string;
};

export const ParticipationChart: React.FC<ParticipationChartProps> = (props) => {
  const theme = useDevExTheme();

  const { data: participation } = useStudyParticipation({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
  });

  const data = useMemo(() => {
    if (!participation) {
      return undefined;
    }

    const responseDateTimes = participation.responseTimes.map((iso) => DateTime.fromISO(iso));
    // count responses in hourly buckets
    const responseCountsByHour = mapValues(
      groupBy(responseDateTimes, (dateTime) => dateTime.startOf('hour').toISO()!),
      (dateTimes) => dateTimes.length
    );

    // day the study opened
    const openDateTime = DateTime.fromISO(participation.openDate);
    // day after last response was received (or today if it is yet to close)
    const closeDateTimeReference =
      participation.closeDate && responseDateTimes.length
        ? responseDateTimes.at(-1)!
        : DateTime.now();
    const closeDateTime = closeDateTimeReference.startOf('day').plus({ days: 1 });

    // end chart the day after closeDateTime
    const endDateTime = closeDateTime.plus({ days: 1 });

    // start chart the day before openDateTime, but limit range to CHART_SPAN_DAYS
    const minStartDateTime = openDateTime.minus({ days: 1 });
    const maxStartDateTime = endDateTime.minus({ days: CHART_SPAN_DAYS });
    const startDateTime = DateTime.max(minStartDateTime, maxStartDateTime);

    // count responses that fall outside of chart bounds
    const excludedResponseCount = responseDateTimes.filter(
      (dateTime) => dateTime < startDateTime
    ).length;

    const data: ParticipationDatum[] = [];
    const currentHour = DateTime.now().startOf('hour');

    // keep running incrementors of hour and response count
    let chartHour = startDateTime;
    let chartResponseCount = excludedResponseCount;

    // iterate through hours between startDateTime and endDateTime
    while (chartHour <= endDateTime) {
      // add responses in this hour (if any) to the running count
      const hourResponseCount = responseCountsByHour[chartHour.toISO()!];
      chartResponseCount += hourResponseCount ?? 0;

      // determine if we want to plot a data point for this hour
      const plotDataPoint =
        hourResponseCount || // plot if new responses were gained this hour
        chartHour.hour % 6 === 0 || // plot every 6 hours for labels and tooltips
        chartHour.valueOf() === currentHour.valueOf(); // plot current time

      if (plotDataPoint) {
        // use null value for data points that fall before launchDateTime or after current time
        const nullValue =
          chartHour < openDateTime || chartHour > currentHour || chartHour > closeDateTime;

        data.push({
          value: nullValue ? undefined : chartResponseCount,
          dateTimeMillis: chartHour.valueOf(),
          dateTime: chartHour,
        });
      }

      // increment hour
      chartHour = chartHour.plus({ hours: 1 });
    }

    return data;
  }, [participation]);

  const empty = participation && !participation.responseTimes.length;
  const openDateTime = participation && DateTime.fromISO(participation.openDate);

  return (
    <Flex>
      {data ? (
        <ResponsiveContainer width={'100%'} height={200}>
          <AreaChart data={data} margin={{ left: 0, top: 0, right: 0, bottom: 0 }}>
            <CartesianGrid vertical={false} stroke={theme.color.visualization.grid} />
            <RechartsTooltip
              content={(tooltipProps) => (
                <DevExRechartsTooltipContent
                  {...tooltipProps}
                  formatLabel={(dateTimeMillis) =>
                    dateTimeMillis &&
                    DateTime.fromMillis(dateTimeMillis).toLocaleString(DateTime.DATETIME_FULL)
                  }
                  formatValue={(value) => `${value} responses`}
                />
              )}
            />
            <XAxis
              dataKey="dateTimeMillis"
              domain={['auto', 'auto']}
              scale="time"
              type="number"
              axisLine={false}
              tickLine={false}
              tick={{ fill: theme.color.text.secondary }}
              interval={0} // render every label through tickFormatter
              tickFormatter={(dateTimeMillis) => {
                const dateTime = DateTime.fromMillis(dateTimeMillis);
                // render a label in center of each weekday
                if (dateTime.weekday < 6 && dateTime.hour === 12) {
                  return dateTime.toFormat('MMM d');
                }
                return '';
              }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fill: theme.color.text.secondary }}
              allowDecimals={false}
              tickCount={4}
              width={empty ? 0 : parseInt(theme.variable.spacing.lg)}
            />
            {
              // create a ReferenceLine for when the study was opened
              openDateTime && (
                <ReferenceLine
                  x={openDateTime.valueOf()}
                  stroke={theme.color.text.tertiary}
                  strokeDasharray="3 3"
                />
              )
            }
            {
              // create a ReferenceLine for when the study was "closed" (last data point)
              !!participation?.closeDate && (
                <ReferenceLine
                  x={data.filter((datum) => datum.value).at(-1)?.dateTimeMillis}
                  stroke={theme.color.text.tertiary}
                  strokeDasharray="3 3"
                />
              )
            }
            {
              // create a ReferenceArea to shade in each weekend day
              data.map(
                (datum, i) =>
                  datum.dateTime.hour === 0 &&
                  datum.dateTime.weekday > 5 && (
                    <ReferenceArea
                      key={i}
                      x1={datum.dateTimeMillis}
                      x2={DateTime.fromMillis(datum.dateTimeMillis).plus({ days: 1 }).valueOf()}
                      fill={theme.color.visualization.grid}
                    />
                  )
              )
            }
            <Area
              type="stepAfter"
              dataKey="value"
              stroke={theme.color.visualization.palette[1].data}
              fillOpacity={0.2}
              fill={theme.color.visualization.palette[1].data}
              animationDuration={1000}
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <DevExSkeleton height={200} />
      )}
    </Flex>
  );
};
