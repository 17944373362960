import React, { useMemo } from 'react';
import { keyBy } from 'lodash-es';

import { StudyLibraryClient } from 'jf/api';
import { QuestionCardVerbatims, ScoredPrompt } from 'jf/pages/study/question/QuestionCardVerbatims';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyBuilderState } from '../StudyBuilderState';

export const CustomizableVoting: React.FC = () => {
  const state = useStudyBuilderState();

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);

  const topicsBySlug = useMemo(() => (topics ? keyBy(topics, 'slug') : undefined), [topics]);
  const promptsBySlug = useMemo(() => (prompts ? keyBy(prompts, 'slug') : undefined), [prompts]);

  const scoredPrompts = useMemo(() => {
    if (!topicsBySlug || !promptsBySlug || !state.promptSlugs) {
      return [];
    }

    const scoredPrompts: ScoredPrompt[] = [];

    for (let i = 0; i < state.promptSlugs.length; i++) {
      const promptSlug = state.promptSlugs[i];

      const prompt = promptsBySlug[promptSlug];
      const topic = prompt && topicsBySlug[prompt.topicSlug];

      // caution: prompt and topic will be intermittently undefined due to race conditions
      if (prompt && topic) {
        scoredPrompts.push({
          ...prompt,
          score: [1, 3, 5][i % 3],
          topicLabel: topic.label,
          choiceLabels: [],
        });
      }
    }

    return scoredPrompts;
  }, [topicsBySlug, promptsBySlug, state.promptSlugs]);

  const stepNumber = state.promptSlugs.length + 1;

  return (
    <QuestionCardVerbatims
      disabled
      number={stepNumber}
      scoredPrompts={scoredPrompts}
      initialVerbatims={{
        [scoredPrompts[0]?.topicSlug]: '',
      }}
    />
  );
};
