import React, { useEffect, useRef, useState } from 'react';
import { Flex, InputRef } from 'antd';
import { useQueryClient } from 'react-query';

import { trackEvent } from 'jf/analytics/Analytics';
import { StudySequence, StudySequenceClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExModal } from 'jf/components/DevExModal';
import { useClientMutation } from 'jf/utils/useClientQuery';

type DeleteStudySequenceModalProps = {
  sequence: StudySequence;
};

export const DeleteStudySequenceModal: React.FC<DeleteStudySequenceModalProps> = (props) => {
  const modal = useJFOverlay(DeleteStudySequenceModal);
  const theme = useDevExTheme();
  const queryClient = useQueryClient();

  const inputRef = useRef<InputRef>(null);
  const [input, setInput] = useState('');

  const { mutateAsync: deleteSequence, isLoading: isDeleting } = useClientMutation(
    StudySequenceClient.deleteStudySequence
  );

  const onCancel = () => {
    if (!isDeleting) {
      modal.close();
    }
  };

  const onOk = () => {
    trackEvent('survey-list:series-actions:delete', {
      seriesRef: props.sequence.ref,
    });
    deleteSequence({ sequenceRef: props.sequence.ref })
      .then(() => {
        queryClient.invalidateQueries('GET_STUDIES');
        queryClient.invalidateQueries('GET_STUDY_SEQUENCES');
      })
      .finally(() => {
        modal.close(true);
      });
  };

  // reset input after reopening modal
  useEffect(() => {
    if (modal.isOpened) {
      setInput('');
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  }, [modal.isOpened]);

  // ensure repeating spaces are displayed
  const safeSequenceName = props.sequence.name.replace(/ /g, '\u00A0');

  return (
    <DevExModal
      open={modal.isOpened}
      onCancel={onCancel}
      onOk={onOk}
      title="Delete Series"
      footer={
        <>
          <DevExButton onClick={onCancel} disabled={isDeleting}>
            Cancel
          </DevExButton>
          <DevExButton
            type="primary"
            onClick={onOk}
            loading={isDeleting}
            disabled={input !== props.sequence.name}
          >
            Delete
          </DevExButton>
        </>
      }
      width={500}
    >
      <Flex vertical gap={theme.variable.spacing.sm}>
        <div>
          Once you delete this series, you will not be able to recover it. It will be deleted
          forever.
        </div>
        <em>Deleting a series will not delete the surveys it contains.</em>
        <div>Please type "{safeSequenceName}" to confirm.</div>
        <DevExInput ref={inputRef} value={input} onChange={setInput} />
      </Flex>
    </DevExModal>
  );
};
