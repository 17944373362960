import React, { useEffect, useState } from 'react';
import { sortBy } from 'lodash-es';
import { useNavigate, useParams } from 'react-router-dom';

import { RosterClient, SlackTargetingClient, StudyClient, StudySlackTarget, Team } from 'jf/api';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { CATCH_ALL_TEAM_REF } from '../study/question/QuestionCardDemographic';

export type StudyBuilderStep =
  | 'choose-study'
  | 'customize-study'
  | 'configure-teams'
  | 'target-respondents'
  | 'publish-study';

export const STUDY_BUILDER_STEPS: StudyBuilderStep[] = [
  'choose-study',
  'customize-study',
  'configure-teams',
  'target-respondents',
  'publish-study',
];

export type StudyStep =
  | {
      type: 'INTRO' | 'DEMOGRAPHIC' | 'VOTING';
    }
  | { type: 'PROMPT'; key: string };

type StudyBuilderState = {
  step: StudyBuilderStep; // step in Study Builder
  studyRef?: string;
  creatingStudy: boolean; // CreateStudy query firing
  updatingStudy: boolean; // UpdateStudy query firing
  studyStep: StudyStep; // step in Study
  // editable study attributes
  name: string;
  promptSlugs: string[];
  welcomeMessage: string;
  loaded: boolean; // identifies when editable study attributes have been populated
  teams: Team[];
  slackTargetingEnabled: boolean;
  slackTargets: StudySlackTarget[];
};

const DEFAULT_STUDY_BUILDER_STATE: StudyBuilderState = {
  step: 'choose-study',
  creatingStudy: false,
  updatingStudy: false,
  studyStep: { type: 'INTRO' },
  name: '',
  promptSlugs: [],
  welcomeMessage: '',
  loaded: false,
  teams: [],
  slackTargetingEnabled: false,
  slackTargets: [],
};

type _StudyBuilderStateContext = StudyBuilderState & {
  update: (state: Partial<StudyBuilderState>) => void;
};

const StudyBuilderStateContext = React.createContext<_StudyBuilderStateContext>({
  ...DEFAULT_STUDY_BUILDER_STATE,
  update: () => {},
});

export const StudyBuilderStateProvider: React.FC = (props) => {
  const { studyRef, step: stepParam } = useParams<{ studyRef: string; step: string }>();
  const navigate = useNavigate();
  const { data: slackStatus } = useClientQuery(SlackTargetingClient.getSlackStatus);

  const [state, setState] = useState<StudyBuilderState>({
    ...DEFAULT_STUDY_BUILDER_STATE,
    step: studyRef ? 'customize-study' : 'choose-study',
  });
  const [stepParamLoaded, setStepParamLoaded] = useState(false);

  const updateState = (partialState: Partial<StudyBuilderState>) => {
    setState((state) => ({ ...state, ...partialState }));
  };

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });
  const { data: teams } = useClientQuery(RosterClient.getTeams);
  const { data: slackTargets } = useClientQuery(SlackTargetingClient.getStudySlackTargets, {
    studyRef,
  });

  const context: _StudyBuilderStateContext = {
    ...state,
    studyRef,
    update: updateState,
  };

  useEffect(() => {
    // populate editable states when study first loads
    if (study && !state.loaded) {
      updateState({
        loaded: true,
        name: study.name,
        promptSlugs: study.prompts.map((prompt) => prompt.slug),
        welcomeMessage: study.welcomeMessage,
      });
    }
  }, [study]);

  useEffect(() => {
    // populate editable teams
    if (teams) {
      updateState({
        teams: sortBy(teams, 'value')
          .filter((team) => team.ref !== CATCH_ALL_TEAM_REF)
          .map((team) => ({ ...team })),
      });
    }
  }, [teams]);

  useEffect(() => {
    // populate editable study slack targets
    if (slackTargets && slackStatus) {
      updateState({
        slackTargetingEnabled: slackStatus.connected,
        slackTargets,
      });
    }
  }, [slackTargets, slackStatus]);

  useEffect(() => {
    // if step param is provided, go to that step
    if (stepParam) {
      updateState({ step: stepParam as StudyBuilderStep });
    }
    setStepParamLoaded(true);
  }, [stepParam]);

  useEffect(() => {
    // make sure step param is updated when changing steps
    if (stepParamLoaded && studyRef && state.step !== stepParam) {
      navigate(`/study/${studyRef}/build/${state.step}`);
    }
  }, [stepParamLoaded, state.step]);

  return (
    <StudyBuilderStateContext.Provider value={context}>
      {props.children}
    </StudyBuilderStateContext.Provider>
  );
};

export const useStudyBuilderState = () => React.useContext(StudyBuilderStateContext);
