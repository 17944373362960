import { useSearchParams } from 'react-router-dom';

/**
 * Get user's permissions.
 */
export const useClientPerms = () => {
  const [searchParams] = useSearchParams();

  const scopeToken = searchParams.get('scope_token');

  const isCustomerAdmin = !!window.dx.user?.isCustomerAdmin && scopeToken !== 'DEMO';

  return {
    role: {
      customerAdmin: isCustomerAdmin,
    },
  };
};
