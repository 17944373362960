import { Study, StudyVerbatimClient } from 'jf/api';
import { useClientQueries } from 'jf/utils/useClientQuery';

import { useSequenceStudies } from './useSequenceStudies';

type UseSequenceVerbatimsProps = {
  sequenceRef: string | undefined;
  teamRef?: string;
};

export const useSequenceVerbatims = (props: UseSequenceVerbatimsProps) => {
  const closedStudies = useSequenceStudies(props.sequenceRef, Study.status.CLOSED);

  const sequenceVerbatimsQueries = useClientQueries(
    StudyVerbatimClient.getTopicVerbatims,
    closedStudies?.map((study) => ({ studyRef: study.ref })) ?? [],
    { enabled: !props.teamRef }
  );

  const sequenceVerbatimsQueriesForTeam = useClientQueries(
    StudyVerbatimClient.getTopicVerbatimsForTeam,
    closedStudies?.map((study) => ({ studyRef: study.ref, teamRef: props.teamRef! })) ?? [],
    { enabled: !!props.teamRef }
  );

  return props.teamRef ? sequenceVerbatimsQueriesForTeam : sequenceVerbatimsQueries;
};
