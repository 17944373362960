import { useFlags } from 'launchdarkly-react-client-sdk';

/**
 * Get DevEx feature flags.
 */
export const useClientFlags = () => {
  const flags = useFlags();

  const getFlag = (key: string) => !!flags[key];

  return {
    studyReveal: getFlag('bakunawaDevexSurveyRevealQ324'),
    metricCards: getFlag('bakunawaDevexMetricsCardsQ324'),
    actionItems: getFlag('bakunawaDevexActionItemsQ424'),
    slackTargeting: getFlag('bakunawaDevexSlackTargetingQ424'),
    globalTeamsPage: getFlag('bakunawaDevexGlobalTeamsPageQ125'),
  };
};
