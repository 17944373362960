/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Flex } from 'antd';
import { useNavigate } from 'react-router-dom';

import { AuthClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExLink } from 'jf/components/DevExLink';
import { useClientMutationError } from 'jf/utils/useClientMutationError';
import { useClientMutation } from 'jf/utils/useClientQuery';

import { AuthFormContext, authStyles, EMAIL_PLACEHOLDER } from './AuthPage';

export const ResetPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const theme = useDevExTheme();
  const { email, setEmail } = useContext(AuthFormContext);

  const createAccount = useClientMutation(AuthClient.createAccount);
  const createAccountError = useClientMutationError();

  const validated = !!email;

  const onSendRecoveryLink = () => {
    // hitting createAccount will send the user a link to authenticate via email and then set a new password
    createAccount
      .mutateAsync({
        requestBody: { email },
      })
      .catch(createAccountError.handle);
  };

  // reset error if email is changed
  useEffect(() => {
    if (!createAccount.isIdle) {
      createAccountError.reset();
    }
  }, [email]);

  return (
    <DevExCard css={authStyles.form}>
      <Flex vertical gap={theme.variable.spacing.xs} className="card__header">
        <div className="header__title">Reset password</div>
      </Flex>

      <Flex vertical gap={theme.variable.spacing.lg}>
        <Flex vertical gap={theme.variable.spacing.md}>
          <Flex vertical gap={theme.variable.spacing.sm}>
            <label>Email</label>
            <DevExInput
              placeholder={EMAIL_PLACEHOLDER}
              type="email"
              value={email}
              onChange={setEmail}
              onKeyUp={(event) => event.key === 'Enter' && onSendRecoveryLink()}
            />

            {!!createAccountError.message && (
              <div className="card__message message--error">{createAccountError.message}</div>
            )}
          </Flex>
        </Flex>

        <Flex vertical gap={theme.variable.spacing.sm}>
          <DevExButton
            type="primary"
            onClick={onSendRecoveryLink}
            disabled={!validated}
            loading={createAccount.isLoading}
          >
            Send recovery link
          </DevExButton>
        </Flex>

        {createAccount.isSuccess && (
          <div className="card__message">
            An email has been sent to <em>{email}</em> to recover your password.
          </div>
        )}

        <div className="card__footer">
          Remember your password?{' '}
          <DevExLink onClick={() => navigate('/auth/sign-in')}>Sign in</DevExLink>
        </div>
      </Flex>
    </DevExCard>
  );
};
