import React from 'react';
import { Flex } from 'antd';
import { faListRadio, faPlus, faPoll } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from 'styled-components';

import { StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExDivider } from 'jf/components/DevExDivider';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { VERBATIMS_PROMPT_TEXT } from 'jf/pages/study/question/QuestionCardVerbatims';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyBuilderState } from '../../StudyBuilderState';
import { DEFAULT_TEAM_DEMOGRAPHIC_TEXT } from '../CustomizableDemographic';
import { DEFAULT_WELCOME_MESSAGE } from '../CustomizableIntroduction';

import { PromptsModal } from './PromptsModal';
import { PromptSteps } from './PromptSteps';
import { StudyStepItem } from './StudyStepItem';

const styles = {
  studyStepsPanel: css`
    width: 400px;
    min-width: 400px;
    border-right: 1px solid ${(props) => props.theme.color.border.primary};

    .studyStepsPanel__block {
      padding: ${(props) => props.theme.variable.spacing.sm};
      display: flex;
      flex-direction: column;
      gap: ${(props) => props.theme.variable.spacing.xs};

      &:not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.color.border.primary};
      }

      &.block--scrollable {
        padding-block: 0;
        flex: 1 1 0;
        overflow: auto;

        .block__scroll {
          padding-block: ${(props) => props.theme.variable.spacing.sm};
          overflow: auto;
        }
      }

      .block__heading {
        padding: ${(props) => props.theme.variable.spacing.sm};
        padding-bottom: ${(props) => props.theme.variable.spacing.xs};
        font-weight: 600;
        font-size: ${(props) => props.theme.variable.fontSize.md};
      }
    }
  `,
};

export const StudyStepsPanel: React.FC = () => {
  const theme = useDevExTheme();
  const state = useStudyBuilderState();
  const promptsModal = useJFOverlay(PromptsModal);

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: state.studyRef });

  return (
    <Flex vertical css={styles.studyStepsPanel}>
      <div className="studyStepsPanel__block">
        <DevExButton
          icon={<FontAwesomeIcon icon={faPlus} />}
          type="text"
          onClick={() => promptsModal.open()}
          disabled={!study}
          style={{ justifyContent: 'center' }}
        >
          Add question
        </DevExButton>
      </div>

      <div className="studyStepsPanel__block">
        {state.loaded ? (
          <StudyStepItem step={{ type: 'INTRO' }}>
            <span>{state.welcomeMessage || DEFAULT_WELCOME_MESSAGE}</span>
          </StudyStepItem>
        ) : (
          <StudyStepItem disabled>
            <DevExSkeleton width={['100%', '90%']} fontSize="sm" />
          </StudyStepItem>
        )}
      </div>

      <div className="studyStepsPanel__block">
        {state.loaded ? (
          <StudyStepItem step={{ type: 'DEMOGRAPHIC' }}>
            <Flex gap={theme.variable.spacing.sm} align="center">
              <FontAwesomeIcon icon={faListRadio} style={{ fontSize: 18 }} />
              <span>{DEFAULT_TEAM_DEMOGRAPHIC_TEXT}</span>
            </Flex>
          </StudyStepItem>
        ) : (
          <StudyStepItem disabled>
            <DevExSkeleton width={'50%'} fontSize="sm" />
          </StudyStepItem>
        )}
      </div>

      <div className="studyStepsPanel__block block--scrollable">
        <Flex vertical gap={theme.variable.spacing.sm} className="block__scroll">
          <PromptSteps />

          {state.loaded && (
            <>
              <DevExDivider style={{ margin: 0 }} />
              <StudyStepItem step={{ type: 'VOTING' }} className="promptMenuItems__item">
                <Flex gap={theme.variable.spacing.sm} align="center">
                  <FontAwesomeIcon icon={faPoll} style={{ fontSize: 18 }} />
                  <span>{VERBATIMS_PROMPT_TEXT}</span>
                </Flex>
              </StudyStepItem>
            </>
          )}
        </Flex>
      </div>
    </Flex>
  );
};
