import React from 'react';
import { Flex } from 'antd';
import { faBullseyeArrow, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExDash } from 'jf/components/DevExColumnDash';

type BenchmarkCellProps = {
  benchmark?: number;
  style?: React.CSSProperties;
};

export const BenchmarkCell: React.FC<BenchmarkCellProps> = ({ benchmark, style }) => {
  const theme = useDevExTheme();

  if (benchmark === undefined) {
    return <DevExDash centered />;
  }

  return (
    <Flex justify="center" gap={theme.variable.spacing.xs} style={style}>
      <span>
        {benchmark === 0 || benchmark < 0 ? '' : '+'}
        {benchmark}
      </span>
      <div>
        {benchmark >= 0 && (
          <FontAwesomeIcon
            icon={faBullseyeArrow}
            color={theme.color.visualization.polarity.positive.high.text}
          />
        )}
        {benchmark < 0 && (
          <FontAwesomeIcon
            icon={faCircleXmark}
            color={theme.color.visualization.polarity.negative.high.text}
          />
        )}
      </div>
    </Flex>
  );
};
