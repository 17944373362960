import React from 'react';
import { Tooltip } from 'antd';
import { faShieldHalved } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sum } from 'lodash-es';
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExRechartsTooltipContent } from 'jf/components/DevExRechartsTooltipContent';
import { DevExTag } from 'jf/components/DevExTag';
import { computeTextWidth } from 'jf/utils/computeTextWidth';

const truncate = (str: string, max: number) =>
  str.length > max ? `${str.slice(0, max - 1)}...` : str;

const LABEL_LIMIT = 40;

type SegmentParticipationCardProps = {
  segmentationLabel: string;
  data:
    | {
        label: string;
        value: number;
      }[]
    | undefined;
};

export const SegmentParticipationCard: React.FC<SegmentParticipationCardProps> = (props) => {
  const theme = useDevExTheme();
  const borderRadius = parseInt(theme.variable.borderRadius);

  const barGap = 4;
  const barSize = 12;

  const data = props.data?.toSorted((a, b) => b.value - a.value) ?? [];

  const maxLabelWidth = Math.max(
    ...data.map((datum) =>
      computeTextWidth(
        datum.label,
        `${theme.variable.fontSize.xs} ${theme.variable.fontFamily.primary}`
      )
    )
  );

  // compute how many responses are in groups that meet the anonymity threshold
  const responses = sum(data.map((datum) => datum.value));
  const responsesOverThreshold = sum(data.map((datum) => datum.value).filter((v) => v >= 3));
  const percentageOverThreshold = `${((responsesOverThreshold / responses) * 100).toFixed(1)}%`;

  return (
    <DevExCard
      title={`By ${props.segmentationLabel}`}
      extra={
        <Tooltip
          title={`${percentageOverThreshold} of participants fall into ${props.segmentationLabel} groups that meet the anonymity threshold.`}
        >
          <DevExTag color={theme.color.visualization.palette[1].data}>
            <FontAwesomeIcon icon={faShieldHalved} /> {percentageOverThreshold}
          </DevExTag>
        </Tooltip>
      }
    >
      <ResponsiveContainer
        width="100%"
        // use dynamic height to get the barGap and barSize we want (smh Recharts)
        height={data.length * (barGap + barSize)}
        // prevent weird bug where scrollbar would appear on hover for no reason
        style={{ overflow: 'hidden' }}
      >
        <BarChart
          data={data}
          layout="vertical"
          barCategoryGap={barGap}
          margin={{ top: 0, right: 24, bottom: 0, left: 0 }}
        >
          <RechartsTooltip
            cursor={{ fill: theme.color.visualization.palette[1].data, opacity: 0.2 }}
            content={(tooltipProps) => (
              <DevExRechartsTooltipContent
                {...tooltipProps}
                formatValue={(value) => `${value} responses`}
              />
            )}
          />
          <XAxis hide type="number" domain={[0, data[0]?.value ?? 0]} />
          <YAxis
            type="category"
            dataKey="label"
            axisLine={false}
            tickLine={false}
            tick={{
              fill: theme.color.text.secondary,
              fontSize: theme.variable.fontSize.xs,
            }}
            tickFormatter={(label) =>
              // truncate label and use non-line-breaking spaces to prevent overflow (smh Recharts)
              truncate(label, LABEL_LIMIT).replace(/ /g, '\u00A0')
            }
            interval={0} // prevent label clipping
            width={maxLabelWidth + 8}
          />
          <ReferenceArea
            x1={0}
            x2={3}
            fill={theme.color.visualization.palette[1].data}
            opacity={0.4}
          />
          <Bar dataKey="value" radius={[0, borderRadius, borderRadius, 0]}>
            <LabelList
              dataKey="value"
              position="right"
              fill={theme.color.text.primary}
              fontSize={theme.variable.fontSize.xs}
            />
            {data.map((datum, i) => (
              <Cell
                key={i}
                fill={theme.color.visualization.palette[1].data}
                opacity={datum.value >= 3 ? 1 : 0.5}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </DevExCard>
  );
};
