import React, { ReactNode } from 'react';
import { TooltipProps } from 'recharts';
import { css } from 'styled-components';

import { DevExCard } from 'jf/components/DevExCard';

const styles = {
  tooltip: css`
    background-color: ${(props) => props.theme.color.tooltip.background};
    color: ${(props) => props.theme.color.tooltip.text};
    padding: ${(props) => props.theme.variable.spacing.sm};
    border: none;
    filter: drop-shadow(
      0 0 ${(props) => props.theme.variable.spacing.md}
        ${(props) => props.theme.color.shadow.default}
    );

    .card__body {
      gap: ${(props) => props.theme.variable.spacing.xs};
    }
  `,
};

interface DevExRechartsTooltipContentProps extends TooltipProps<any, any> {
  formatLabel?: (label: any) => ReactNode;
  formatValue?: (value: any) => ReactNode;
}

export const DevExRechartsTooltipContent: React.FC<DevExRechartsTooltipContentProps> = (props) => {
  const label = props.label;
  const value = props.payload?.[0]?.value;

  if (!value) {
    return null;
  }

  return (
    <DevExCard css={styles.tooltip}>
      <strong>{props.formatLabel?.(label) ?? label}</strong>
      <div>{props.formatValue?.(value) ?? value}</div>
    </DevExCard>
  );
};
