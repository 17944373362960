import React, { useEffect } from 'react';

import { startFullstory } from 'jf/common/DevEx';
import { JFOverlayController } from 'jf/common/useJFOverlay';

import { StudyBuilderPage } from './StudyBuilderPage';
import { StudyBuilderStateProvider } from './StudyBuilderState';
import { StudyUpdaterProvider } from './useStudyUpdater';

export const StudyBuilderController: React.FC = () => {
  useEffect(() => startFullstory('survey-builder'), []);

  return (
    <StudyBuilderStateProvider>
      <StudyUpdaterProvider>
        <JFOverlayController>
          <StudyBuilderPage />
        </JFOverlayController>
      </StudyUpdaterProvider>
    </StudyBuilderStateProvider>
  );
};
