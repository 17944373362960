import React from 'react';
import { Button, ButtonProps, Flex } from 'antd';
import classNames from 'classnames';
import { css } from 'styled-components';

import { useDevExTheme } from '../common/themes/DevExTheme';

const styles = {
  button: css`
    &&&.ant-btn {
      height: 100%;
      flex: 1;
      min-width: 0;
      background: none;
      border-color: ${(props) => props.theme.color.border.primary};
      color: ${(props) => props.theme.color.text.secondary};

      .ant-flex {
        font-size: ${(props) => props.theme.variable.fontSize.md};
        line-height: 1;

        svg {
          font-size: ${(props) => props.theme.variable.fontSize.xl};
        }

        span {
          font-size: ${(props) => props.theme.variable.fontSize.xs};
        }
      }

      &:hover,
      &:focus,
      &.selected {
        color: ${(props) => props.theme.color.text.primary};
        border-color: ${(props) => props.theme.color.border.hovered};
        outline: none;
      }

      &.selected {
        border-color: ${(props) => props.theme.color.border.active};
      }

      &:disabled {
        color: ${(props) => props.theme.color.text.secondary};
        border-color: ${(props) => props.theme.color.border.primary};
      }

      &.selected {
        background-color: ${(props) => props.theme.color.background.active};
      }
    }
  `,
};

interface DevExToggleButtonProps extends ButtonProps {
  toggled?: boolean;
}

export const DevExToggleButton: React.FC<DevExToggleButtonProps> = ({ toggled, ...props }) => {
  const theme = useDevExTheme();

  const className = classNames({
    selected: toggled,
  });

  return (
    <Button css={styles.button} className={className} {...props}>
      <Flex vertical gap={theme.variable.spacing.sm} align="center" justify="center">
        {props.children}
      </Flex>
    </Button>
  );
};
