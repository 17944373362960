import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';

import { getNestedObjectKeys } from 'jf/utils/getNestedObjectKeys';

import { DevExThemeColors } from '../themes/DevExTheme';

import { _FOUNDATIONS } from './foundations';

/**
 * Converts opacity on scale 0 - 99 to alpha on hex scale 00 - FF.
 * @example
 * 30 => 4d
 */
const opacityToAlpha = (opacity: number) => {
  return Math.round(255 * (opacity / 100))
    .toString(16)
    .padStart(2, '0')
    .toUpperCase();
};

/**
 * Resolve a theme color from a functional syntax. Includes the ability to handle opacification.
 * @example
 * "red-5" => "#FF5763"
 * "red-5 50%" => "#FF576380"
 */
export const resolveColorVariable = (colorVariable: string) => {
  let [color, opacity] = colorVariable.split(' ');

  const [colorName, colorIndex] = color.split('-');
  let hex = _FOUNDATIONS[colorName]?.[colorIndex];

  if (!hex) {
    throw new Error(`Could not resolve foundation color "${color}".`);
  }

  if (opacity) {
    const opacityMatch = /^(\d{1,2})%$/g.exec(opacity);

    if (!opacityMatch) {
      throw new Error(`Could not resolve opacity "${opacity}".`);
    }

    const alpha = opacityToAlpha(parseInt(opacityMatch[1]));
    hex += alpha;
  }

  return hex;
};

/**
 * Resolve functional color syntax of theme.
 */
export const resolveThemeColors = (theme: DevExThemeColors): DevExThemeColors => {
  const resolvedTheme = cloneDeep(theme);
  for (const nestedKey of getNestedObjectKeys(theme)) {
    set(resolvedTheme, nestedKey, resolveColorVariable(get(theme, nestedKey)));
  }
  return resolvedTheme;
};
