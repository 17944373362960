import React, { useRef } from 'react';
import { Flex } from 'antd';
import { useParams } from 'react-router-dom';
import { css } from 'styled-components';

import { DevExThemeProvider, useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExSegmentedOption } from 'jf/components/DevExSegmented';

import { SegmentScoreHeatmap } from '../insights/SegmentScoreHeatmap';
import { useStudySegments } from '../useStudySegments';

import { usePageIndexTracker } from './studyReveal';

const styles = {
  HeatMap: css`
    width: 100vw;
    height: 500vh;
    background-color: ${(props) => props.theme.color.reveal.scores.background};

    .heatMap__header {
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};
      font-size: 64px;
      color: ${(props) => props.theme.color.reveal.scores.text};
      font-weight: bold;
      padding-top: ${(props) => props.theme.variable.spacing.xxl};
      padding-bottom: ${(props) => props.theme.variable.spacing.xl};
    }
  `,
};

export const RevealTeamHeatMap: React.FC = () => {
  const theme = useDevExTheme();
  //fetch study data
  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef: string }>();

  const studySegments = useStudySegments({ studyRef, teamRef })?.filter(
    (option) => option.key.startsWith('teamSegment') && option.options.length > 1
  );

  let segmentOptions: DevExSegmentedOption[] = [];
  if (studySegments) {
    segmentOptions = studySegments.map((segment) => ({ value: segment.key, label: segment.name }));
  }

  const targetRef = useRef(null);

  usePageIndexTracker(targetRef, 'heat-map');

  const segment = segmentOptions[segmentOptions.length - 1];
  return (
    <div ref={targetRef}>
      {segment && (
        <div>
          <div css={styles.HeatMap}>
            <Flex
              align="center"
              justify="center"
              style={{
                position: 'sticky',
                top: theme.variable.spacing.xl,
                bottom: theme.variable.spacing.xl,
              }}
            >
              <Flex vertical>
                <div className="heatMap__header">How do we differ across {segment.label}s?</div>
                {
                  <DevExThemeProvider themeKey="light">
                    <DevExCard bodyStyle={{ padding: '20px' }}>
                      <div style={{ padding: theme.variable.spacing.sm, margin: '0 auto' }}>
                        <SegmentScoreHeatmap
                          studyRef={studyRef}
                          teamRef={teamRef}
                          segmentKey={segment.value}
                        />
                      </div>
                    </DevExCard>
                  </DevExThemeProvider>
                }
              </Flex>
            </Flex>
          </div>
          <div
            style={{
              height: '50vh',
              backgroundColor: theme.color.reveal.scores.background,
            }}
          />
        </div>
      )}
    </div>
  );
};
