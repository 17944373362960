import React, { useState } from 'react';
import { Flex } from 'antd';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faBook as faBookRegular,
  faObjectsAlignBottom as faObjectsAlignBottomRegular,
  faQuestionSquare as faQuestionSquareRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBook as faBookThin,
  faObjectsAlignBottom as faObjectsAlignBottomThin,
  faQuestionSquare as faQuestionSquareThin,
} from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { StudyLibraryClient, StudyTemplate } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { CircledFontAwesomeIcon } from 'jf/components/CircledFontAwesomeIcon';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExLink } from 'jf/components/DevExLink';
import { SimpleTransition } from 'jf/components/SimpleTransition';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { TOPIC_HYGIENE_PANEL_WIDTH, TopicHygienePanel } from '../customizeStudy/TopicHygienePanel';
import { useStudyCreator } from '../useStudyCreator';

const STUDY_TEMPLATE_ICONS: Record<string, { thin: IconDefinition; regular: IconDefinition }> = {
  beta_baseline: {
    thin: faObjectsAlignBottomThin,
    regular: faObjectsAlignBottomRegular,
  },
  beta_comprehensive: {
    thin: faBookThin,
    regular: faBookRegular,
  },
  unknown: {
    thin: faQuestionSquareThin,
    regular: faQuestionSquareRegular,
  },
};

export const StudyTemplates: React.FC = () => {
  const theme = useDevExTheme();
  const studyCreator = useStudyCreator();

  const [activeTemplate, setActiveTemplate] = useState<StudyTemplate>();
  const [showActiveTemplate, setShowActiveTemplate] = useState(false);

  const { data: studyTemplates } = useClientQuery(StudyLibraryClient.getStudyTemplates);
  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);

  const getStudyTemplateIcons = (slug: string) => {
    return STUDY_TEMPLATE_ICONS[slug] ?? STUDY_TEMPLATE_ICONS.unknown;
  };

  const getTopicsInTemplate = (template: StudyTemplate) => {
    const topics: Record<string, boolean> = {};

    for (const promptSlug of template.promptSlugs) {
      const prompt = prompts?.find((prompt) => prompt.slug === promptSlug);
      if (prompt) {
        topics[prompt.topicSlug] = true;
      }
    }

    return Object.keys(topics).length;
  };

  return (
    <>
      <div style={{ fontSize: theme.variable.fontSize.lg }}>
        Select the template you'd like to use
      </div>

      {studyTemplates?.map((template) => {
        const icons = getStudyTemplateIcons(template.slug);
        const active = showActiveTemplate && activeTemplate?.name === template.name;

        return (
          <Flex
            key={template.name}
            vertical
            align="end"
            style={{ width: '100%' }}
            gap={theme.variable.spacing.sm}
          >
            <DevExCard
              style={{ width: '100%' }}
              onClick={() => {
                trackEvent('survey-creator:templates:clone', {
                  slug: template.slug,
                  name: template.name,
                  description: template.description,
                });

                studyCreator.create({
                  name: `New ${template.name} Survey`,
                  sequencedPrompts: template.promptSlugs,
                  flags: template.flags,
                });
              }}
            >
              <Flex align="center" gap={theme.variable.spacing.md}>
                <CircledFontAwesomeIcon icon={icons.thin} iconSize={32} />
                <Flex vertical gap={theme.variable.spacing.md}>
                  <div style={{ fontSize: theme.variable.fontSize.md, fontWeight: 'bold' }}>
                    {template.name}
                  </div>
                  <div>{template.description}</div>
                  <div
                    style={{ color: theme.color.text.tertiary }}
                  >{`${template.promptSlugs.length} questions | ${getTopicsInTemplate(template)} topics`}</div>
                </Flex>
              </Flex>
            </DevExCard>

            <DevExLink
              onClick={() => {
                if (active) {
                  setShowActiveTemplate(false);
                } else {
                  setActiveTemplate(template);
                  setShowActiveTemplate(true);
                }
              }}
            >
              {active ? 'Hide details' : 'Show details'}
            </DevExLink>
          </Flex>
        );
      })}

      <div
        css={css`
          position: fixed;
          right: 0;
          bottom: 0;
        `}
      >
        <SimpleTransition
          name="template-hygiene"
          timeout={300}
          in={showActiveTemplate}
          cssInit={css`
            transform: translateX(0);
          `}
          cssExit={css`
            transform: translateX(${`${TOPIC_HYGIENE_PANEL_WIDTH}px`});
          `}
        >
          <TopicHygienePanel
            icon={
              activeTemplate && (
                <FontAwesomeIcon icon={getStudyTemplateIcons(activeTemplate.slug).regular} />
              )
            }
            title={activeTemplate?.name}
            promptSlugs={activeTemplate?.promptSlugs ?? []}
            css={css`
              height: calc(100vh - 139px);
            `}
          />
        </SimpleTransition>
      </div>
    </>
  );
};
