import React, { useMemo } from 'react';
import { Flex, Tooltip } from 'antd';
import { faCommentLines } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomComponent } from 'antd/es/_util/type';
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExDash } from 'jf/components/DevExColumnDash';
import { DevExTable, DevExTableColumn } from 'jf/components/DevExTable';
import { VERBATIMS_PROMPT_TEXT } from 'jf/pages/study/question/QuestionCardVerbatims';
import { compareNullableNumbers } from 'jf/utils/compareNullableNumbers';
import { useClientFlags } from 'jf/utils/useClientFlags';
import { useClientPerms } from 'jf/utils/useClientPerms';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { BenchmarkPercentile } from '../benchmark/BenchmarkSelect';
import { ScoreBlock } from '../ScoreBlock';
import { StarredTopicStar } from '../StarredTopicStar';
import { makeStudyAnalyzerPath } from '../StudyAnalyzerPage';
import { TopicDataRecord, useSortedTopicData } from '../topicDrawer/useSortedTopicData';

import { BenchmarkCell } from './BenchmarkCell';
import { FreqDistBar } from './FreqDistBar';
import { TopicScorePopover } from './TopicScorePopover';
import { TrendCell } from './TrendCell';

type KeyedTopicDataRecord = TopicDataRecord & { key: string };

type TopicScoreTableProps = {
  studyRef: string | undefined;
  teamRef?: string;
  benchmarkPercentile?: BenchmarkPercentile;
};

export const TopicScoreTable: React.FC<TopicScoreTableProps> = (props) => {
  const flags = useClientFlags();
  const theme = useDevExTheme();
  const navigate = useNavigate();
  const perms = useClientPerms();

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: props.studyRef });

  const alphabeticalTopicData = useSortedTopicData({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    benchmarkPercentile: props.benchmarkPercentile,
    sortBy: 'topic.label',
  });

  const showStarredTopics = !!flags.actionItems && perms.role.customerAdmin && !!study?.sequenceRef;

  const data: KeyedTopicDataRecord[] | undefined = useMemo(
    () =>
      alphabeticalTopicData?.map((datum) => ({
        ...datum,
        key: datum.topic.slug, // TopicTableRow requires this key to be the topic slug
      })),
    [alphabeticalTopicData]
  );

  const columns: DevExTableColumn<KeyedTopicDataRecord>[] = [
    {
      key: 'topic',
      title: 'Topic',
      render: (record) => {
        return (
          <Flex align="center" gap={theme.variable.spacing.xs}>
            {showStarredTopics && (
              <StarredTopicStar
                sequenceRef={study?.sequenceRef}
                topicSlug={record.topic.slug}
                className={'row__star'}
                eventName="survey-analyzer:table:star-topic"
              />
            )}
            {record.topic.label}
          </Flex>
        );
      },
      sorter: (a, b) => a.topic.label.localeCompare(b.topic.label),
      width: '220px',
    },
    {
      key: 'priority',
      title: (
        <Tooltip
          title={`Priority is based on the number of votes received by respondents when asked, "${VERBATIMS_PROMPT_TEXT}". When topics have the same number of votes, priority is based on score.`}
        >
          Priority
        </Tooltip>
      ),
      render: (record) => (record.priority ? <span>#{record.priority}</span> : <DevExDash />),
      sorter: (a, b, order) => compareNullableNumbers(a.priority, b.priority, order),
      align: 'end',
      width: '70px',
      defaultSortOrder: 'ascend',
    },
    {
      key: 'score',
      title: 'Score',
      render: (record) => (
        <Flex justify="end">
          <ScoreBlock score={record.score.scaledAvg} />
        </Flex>
      ),
      sorter: (a, b) => a.score.scaledAvg - b.score.scaledAvg,
      width: '60px',
    },
    {
      key: 'scoreTrend',
      title: (
        <Tooltip
          title={
            !study?.sequenceRef
              ? 'Add this survey to a series to see how your scores have evolved.'
              : ''
          }
        >
          Trend
        </Tooltip>
      ),

      render: (record) => <TrendCell trend={record.scoreTrend} />,
      sorter: (a, b, order) => compareNullableNumbers(a.scoreTrend, b.scoreTrend, order),
      width: '60px',
    },
    ...(props.benchmarkPercentile
      ? [
          {
            key: 'scoreBenchmark',
            title: 'Benchmark',
            render: (record) => (
              <Flex justify="end">
                <BenchmarkCell benchmark={record.calculatedBenchmark} />
              </Flex>
            ),
            sorter: (a, b) => a.calculatedBenchmark - b.calculatedBenchmark,
            width: '60px',
          },
        ]
      : []),
    {
      key: 'prompts',
      title: 'Questions',
      render: (record) => record.promptScores.length,
      sorter: (a, b) => a.promptScores.length - b.promptScores.length,
      align: 'right',
      width: '90px',
    },
    {
      key: 'sentiment',
      title: 'Sentiment',
      render: (record) => <FreqDistBar freqDist={record.freqDist} />,
      sorter: (a, b) => a.score.scaledAvg - b.score.scaledAvg,
    },
    {
      key: 'comments',
      title: 'Comments',
      render: (record) => {
        const commentCount = record.comments.length;
        return commentCount ? (
          <Flex style={{ display: 'inline-flex' }} gap={theme.variable.spacing.xs} align="center">
            {commentCount}
            <FontAwesomeIcon icon={faCommentLines} />
          </Flex>
        ) : (
          <DevExDash />
        );
      },
      sorter: (a, b) => a.comments.length - b.comments.length,
      align: 'end',
      width: '90px',
    },
  ];

  const TopicTableRow: CustomComponent = (rowProps) => {
    const topicSlug = rowProps['data-row-key'];

    const row = (
      <tr
        css={css`
          cursor: pointer;

          // hide inactive stars when not hovering
          &:not(:hover) {
            .row__star:not(.star--active) {
              display: none;
            }
          }
        `}
        onClick={() => {
          trackEvent('survey-analyzer:table:click', {
            surveyRef: props.studyRef,
            topicSlug,
          });
          navigate(
            makeStudyAnalyzerPath(props.studyRef, props.teamRef, 'topics', { topic: topicSlug })
          );
        }}
      >
        {rowProps.children}
      </tr>
    );

    return (
      <TopicScorePopover topicSlug={topicSlug} studyRef={props.studyRef} teamRef={props.teamRef}>
        {row}
      </TopicScorePopover>
    );
  };

  return (
    <DevExTable
      data-cy="topics-table"
      columns={columns}
      dataSource={data}
      rowKey="key"
      components={{ body: { row: TopicTableRow } }}
    />
  );
};
