import React, { useState } from 'react';
import { Flex } from 'antd';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faClipboard, faRectangleVerticalHistory } from '@fortawesome/pro-thin-svg-icons';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { CreatedStudyRequest, Study, StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { CircledFontAwesomeIcon } from 'jf/components/CircledFontAwesomeIcon';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExLoader } from 'jf/components/DevExLoader';
import { DevExModal } from 'jf/components/DevExModal';
import { DevExRadioButtons } from 'jf/components/DevExRadioButtons';
import { sortByISO } from 'jf/utils/sortByISO';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import {
  STUDIES_TABLE_FILTER_OPTIONS,
  StudiesTable,
  StudiesTableFilter,
} from '../studies/StudiesTable';

const AddExistingStudySection: React.FC<{ sequenceRef: string; onClose: () => void }> = ({
  sequenceRef,
  onClose: closeModal,
}) => {
  const theme = useDevExTheme();

  const { isLoading } = useClientQuery(StudyClient.getStudies);
  const { mutateAsync: updateStudy } = useClientMutation(StudyClient.updateStudy);
  const queryClient = useQueryClient();

  const [selectedFilter, setSelectedFilter] = useState(StudiesTableFilter.ALL);

  return (
    <Flex vertical gap={theme.variable.spacing.md}>
      <div>Click on the survey you would like to add.</div>
      <Flex vertical gap={theme.variable.spacing.md}>
        <DevExRadioButtons
          items={STUDIES_TABLE_FILTER_OPTIONS}
          onChange={(filter: StudiesTableFilter) => {
            setSelectedFilter(filter);
          }}
          value={selectedFilter}
        />
        {isLoading ? (
          <DevExLoader />
        ) : (
          <StudiesTable
            filter={selectedFilter}
            onRowClick={(study) => {
              trackEvent('series-analyzer:add-survey-modal:add-existing-survey', {
                seriesRef: sequenceRef,
                surveyRef: study.ref,
              });
              updateStudy({
                studyRef: study.ref,
                requestBody: {
                  sequenceRef,
                },
              }).then(() => {
                queryClient.invalidateQueries('GET_STUDIES');
                queryClient.removeQueries(['GET_STUDY', study.ref]);
                queryClient.invalidateQueries('GET_STUDY_SEQUENCES');
                // invalidates starred topics in case we have to default starred topics
                // this only happens if the study to be added is closed
                if (study.status === Study.status.CLOSED) {
                  queryClient.invalidateQueries('GET_STARRED_TOPICS');
                }
              });
              closeModal();
            }}
            isStudyDisabled={(study: Study) => study.sequenceRef !== null}
            columns={['name', 'status', 'last_updated']}
            disabledRowTooltipText={`This survey is already in a series.`}
          />
        )}
      </Flex>
    </Flex>
  );
};

export const AddStudyToSequenceModal: React.FC<{ sequenceRef: string }> = (props) => {
  const modal = useJFOverlay(AddStudyToSequenceModal);
  const theme = useDevExTheme();
  const navigate = useNavigate();
  const { data: studies } = useClientQuery(StudyClient.getStudies);

  const [hasSelectedExisting, setHasSelectedExisting] = useState(false);
  const queryClient = useQueryClient();
  const { mutateAsync: createStudy } = useClientMutation(StudyClient.createStudy);

  const onClose = () => {
    modal.close();
    setHasSelectedExisting(false);
  };

  return (
    <DevExModal
      title="Add a survey to this series"
      open={modal.isOpened}
      onCancel={onClose}
      footer={null}
      width={720}
      styles={{ body: { overflowY: 'auto', maxHeight: '500px' } }}
    >
      {hasSelectedExisting ? (
        <AddExistingStudySection sequenceRef={props.sequenceRef} onClose={onClose} />
      ) : (
        <Flex vertical gap={theme.variable.spacing.md}>
          <div>What would you like to do?</div>
          <div
            style={{
              gap: theme.variable.spacing.md,
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
            }}
          >
            <AddStudyOptionCard
              icon={faRectangleVerticalHistory}
              title="Add an existing survey"
              onClick={() => {
                trackEvent('series-analyzer:add-survey-modal:start-add-existing-survey', {
                  seriesRef: props.sequenceRef,
                });
                setHasSelectedExisting(true);
              }}
            />
            <AddStudyOptionCard
              title="Create a new survey"
              icon={faClipboard}
              onClick={() => {
                trackEvent('series-analyzer:add-survey-modal:create-new-survey', {
                  seriesRef: props.sequenceRef,
                });

                // duplicate last study if applicable
                const closedSequenceStudies = studies!.filter(
                  (study) =>
                    study.sequenceRef === props.sequenceRef && study.status === Study.status.CLOSED
                );
                const lastStudy = sortByISO(closedSequenceStudies, 'closeDate').at(-1);

                const newStudyRequest: CreatedStudyRequest = lastStudy
                  ? {
                      name: `Duplicate of ${lastStudy.name}`,
                      welcomeMessage: lastStudy.welcomeMessage,
                      sequencedPrompts: lastStudy.prompts.map((prompt) => prompt.slug),
                      flags: lastStudy.flags,
                      sequenceRef: props.sequenceRef,
                    }
                  : {
                      name: `New Blank Survey`,
                      sequencedPrompts: [],
                      flags: ['voting_enabled'],
                      sequenceRef: props.sequenceRef,
                    };

                createStudy({
                  requestBody: newStudyRequest,
                }).then(({ ref }) => {
                  modal.close();
                  queryClient.invalidateQueries('GET_STUDIES');
                  queryClient.invalidateQueries('GET_STUDY_SEQUENCES');
                  navigate(`/study/${ref}/build`);
                });
              }}
            />
          </div>
        </Flex>
      )}
    </DevExModal>
  );
};

const AddStudyOptionCard: React.FC<{
  onClick: () => void;
  title: string;
  icon: IconDefinition;
}> = ({ icon, title, onClick }) => {
  const theme = useDevExTheme();

  return (
    <DevExCard onClick={onClick}>
      <Flex vertical gap={theme.variable.spacing.md}>
        <Flex justify="center">
          <CircledFontAwesomeIcon icon={icon} />
        </Flex>
        <div
          style={{
            fontSize: theme.variable.fontSize.md,
            lineHeight: theme.variable.lineHeight,
            textAlign: 'center',
          }}
        >
          {title}
        </div>
      </Flex>
    </DevExCard>
  );
};
