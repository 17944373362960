import React from 'react';
import { Flex } from 'antd';

import { Study, StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExExpandableAlert, DevExExpandableAlertType } from 'jf/components/DevExExpandableAlert';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { DEFAULT_TEAM_DEMOGRAPHIC_TEXT } from '../customizeStudy/CustomizableDemographic';
import { useStudyBuilderState } from '../StudyBuilderState';

import { TeamsEditor } from './teamsEditor/TeamsEditor';

export const ConfigureTeamsStep: React.FC = () => {
  const theme = useDevExTheme();
  const state = useStudyBuilderState();

  const { data: studies } = useClientQuery(StudyClient.getStudies);

  const openSurveyCount = studies?.filter((study) => study.status === Study.status.OPEN).length;

  return (
    <Flex
      vertical
      style={{ fontSize: theme.variable.fontSize.md, lineHeight: theme.variable.lineHeight }}
      gap={theme.variable.spacing.lg}
    >
      <div>
        When asked <em>"{DEFAULT_TEAM_DEMOGRAPHIC_TEXT}"</em>, respondents will be able to choose
        from the leaf teams below. Update this list to include all valid teams at your company.
      </div>

      {!!openSurveyCount && (
        <DevExExpandableAlert
          title={
            <div>
              <strong>NOTE:</strong> Changes here will affect {openSurveyCount} open survey
              {openSurveyCount === 1 ? '' : 's'}.
            </div>
          }
          type={DevExExpandableAlertType.WARNING}
        />
      )}

      <TeamsEditor teams={state.teams} onChange={(teams) => state.update({ teams })} />
    </Flex>
  );
};
