import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Flex, Tooltip } from 'antd';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { faMessage } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash-es';
import { Duration } from 'luxon';
import { useQueryClient } from 'react-query';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { SlackTargetingClient, StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { CircledFontAwesomeIcon } from 'jf/components/CircledFontAwesomeIcon';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExText } from 'jf/components/DevExText';
import { useClientFlags } from 'jf/utils/useClientFlags';
import { useClientPerms } from 'jf/utils/useClientPerms';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import { SendSlackReminderModal } from '../SendSlackReminderModal';
import { useStudyParticipation } from '../useStudyParticipation';

import { ParticipationChart } from './ParticipationChart';

const formatAvgTimeSec = (avgTimeSec: number) => {
  if (avgTimeSec === 0) {
    return '—';
  }
  return Duration.fromMillis(avgTimeSec * 1000).toFormat(avgTimeSec >= 3600 ? 'hh:mm:ss' : 'mm:ss');
};

const formatResponseRate = (responseRate: number) => {
  if (responseRate === 0) {
    return '—';
  }
  return `${(responseRate * 100).toFixed(1)}%`;
};

type ParticipationStatProps = {
  label: ReactNode;
  stat: number | ReactNode | undefined;
};

const ParticipationStat: React.FC<ParticipationStatProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <Flex vertical gap={theme.variable.spacing.sm}>
      <DevExText type="secondary">{props.label}</DevExText>
      <DevExText fontSize="xl" fontFamily="secondary" loading={!props.stat} loadingWidth={40}>
        {props.stat}
      </DevExText>
    </Flex>
  );
};

type ParticipationChartProps = {
  studyRef: string | undefined;
  teamRef?: string;
};

export const ParticipationCard: React.FC<ParticipationChartProps> = (props) => {
  const theme = useDevExTheme();
  const queryClient = useQueryClient();
  const perms = useClientPerms();
  const flags = useClientFlags();
  const sendSlackReminderModal = useJFOverlay(SendSlackReminderModal);

  const [targetResponses, setTargetResponses] = useState<number | null>(null);

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: props.studyRef });
  const { data: participation } = useStudyParticipation({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
  });
  const { data: slackTargets } = useClientQuery(
    SlackTargetingClient.getStudySlackTargets,
    { studyRef: props.studyRef },
    { enabled: flags.slackTargeting }
  );

  const slackTargetingEnabled = !!slackTargets?.length;

  const { mutateAsync: updateStudy } = useClientMutation(StudyClient.updateStudy);

  const debounceUpdateTargetResponses = useMemo(
    () =>
      debounce((targetResponses: number | null) => {
        trackEvent('survey-analyzer:participation:set-target-responses', {
          targetResponses,
        });
        updateStudy({ studyRef: props.studyRef!, requestBody: { targetResponses } }).then(() =>
          queryClient.invalidateQueries(['GET_STUDY', props.studyRef])
        );
      }, 500),
    []
  );

  const onSendReminder = () => {
    trackEvent(`survey-analyzer:slack-reminder:open`);
    sendSlackReminderModal.open();
  };

  useEffect(() => {
    if (study && slackTargets) {
      if (slackTargetingEnabled) {
        setTargetResponses(slackTargets.length);
      } else if (!targetResponses && study.targetResponses) {
        setTargetResponses(study.targetResponses);
      }
    }
  }, [study, slackTargets]);

  let shareLink = '';

  if (participation?.responseTimes.length === 0) {
    shareLink = `${window.location.origin}/study/${props.studyRef}`;
  }

  return (
    <DevExCard title="Participation">
      <Flex gap={theme.variable.spacing.lg}>
        <ParticipationStat
          label="Responses"
          stat={participation?.responseTimes.length === 0 ? '—' : participation?.uniqueResponses}
        />
        <ParticipationStat
          label={'Target Respondents'}
          stat={
            study &&
            participation &&
            (slackTargetingEnabled ? (
              targetResponses
            ) : (
              <DevExInput
                onChange={(value) => {
                  const newValue = value ? parseInt(value) : null;
                  setTargetResponses(newValue);
                  debounceUpdateTargetResponses(newValue);
                }}
                value={targetResponses?.toString() ?? ''}
                placeholder="0"
                disabled={!perms.role.customerAdmin}
                embedded
                css={css`
                  width: 80px;
                  height: 28px !important;
                `}
              />
            ))
          }
        />
        <ParticipationStat
          label={'Response Rate'}
          stat={
            participation &&
            study &&
            formatResponseRate(
              targetResponses ? participation.uniqueResponses / targetResponses : 0
            )
          }
        />
        <ParticipationStat
          label={
            <Tooltip title="Average response time includes numeric rating questions only.">
              <Flex gap={theme.variable.spacing.xs}>
                Average Response Time
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Flex>
            </Tooltip>
          }
          stat={participation && formatAvgTimeSec(participation.avgTimeSec)}
        />
      </Flex>
      {participation?.responseTimes.length === 0 ? (
        <>
          <Flex vertical align="center" gap={theme.variable.spacing.md}>
            <CircledFontAwesomeIcon icon={faMessage} />
            <div style={{ fontSize: theme.variable.fontSize.xl }}>No responses... yet!</div>
          </Flex>
          {slackTargets?.length ? (
            <Flex justify="center">
              <DevExButton
                type="primary"
                icon={<FontAwesomeIcon icon={faSlack} />}
                onClick={onSendReminder}
              >
                Send reminder
              </DevExButton>
            </Flex>
          ) : (
            <Flex vertical align="center" gap={theme.variable.spacing.md}>
              <div>Copy the survey link below and share it with your target respondents.</div>
              <Flex gap={theme.variable.spacing.sm}>
                <DevExInput value={shareLink} style={{ width: 500 }} />
                <DevExButton
                  type="primary"
                  copyValue={shareLink}
                  onClick={() =>
                    trackEvent('survey-analyzer:share-survey:copy', { surveyRef: props.studyRef })
                  }
                >
                  Copy
                </DevExButton>
              </Flex>
            </Flex>
          )}
        </>
      ) : (
        <ParticipationChart studyRef={props.studyRef} teamRef={props.teamRef} />
      )}
    </DevExCard>
  );
};
