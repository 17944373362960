import React from 'react';

import { DevExEnv, getDevExEnv } from 'jf/utils/getDevExEnv';

import { DevExTag } from './DevExTag';

type ReleaseTagProps = {
  stage: 'ALPHA' | 'BETA';
};

export const ReleaseTag: React.FC<ReleaseTagProps> = (props) => {
  const env = getDevExEnv();

  // hide release tags in Demo
  if (env.key === DevExEnv.DEMO) {
    return null;
  }

  return <DevExTag color="yellow">{props.stage}</DevExTag>;
};
