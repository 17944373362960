import React, { ComponentProps, ReactNode, useState } from 'react';
import { Flex, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { faClipboardList, faGear, faUser, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { capitalize } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { css } from 'styled-components';

import { trackEvent, usePageVisibilityTracker } from 'jf/analytics/Analytics';
import { AuthClient } from 'jf/api';
import { DevExDivider } from 'jf/components/DevExDivider';
import { DevExTag } from 'jf/components/DevExTag';
import { DevExText } from 'jf/components/DevExText';
import { useClientFlags } from 'jf/utils/useClientFlags';
import { useClientPerms } from 'jf/utils/useClientPerms';

import { DevExButton } from '../components/DevExButton';
import { DevExPopover } from '../components/DevExPopover';
import { useClientMutation, useClientQuery } from '../utils/useClientQuery';

import { useDevExTheme } from './themes/DevExTheme';

import ThemeIcon from './ThemeIcon.svg';
import JFLogoNoText from 'jf/images/jf-logo-no-text.svg';

export const NAV_BAR_HEIGHT = '64px';
const LOGO_SIZE = 28;

const styles = {
  navBar: css`
    position: fixed;
    top: 0;
    left: 0;
    height: ${NAV_BAR_HEIGHT};
    width: 100%;
    z-index: 1000;
    background: ${(props) => props.theme.color.background.default};
    box-shadow: 0 0 0 1px ${(props) => props.theme.color.border.primary};
    padding: ${(props) => props.theme.variable.spacing.md};

    &.navBar--warning {
      box-shadow: 0 0 0 1px ${(props) => props.theme.color.status.warning.border};

      &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        content: '';
        background: ${(props) => props.theme.color.status.warning};
        z-index: -1;
      }
    }

    .navBar__logo {
      svg {
        font-size: ${LOGO_SIZE}px;
        color: ${(props) => props.theme.color.brand.logo};
      }

      .logo__text {
        display: flex;
        align-items: center;
        gap: ${(props) => props.theme.variable.spacing.sm};
        font-size: 24px;
        font-family: ${(props) => props.theme.variable.fontFamily.secondary};
        font-weight: bold;
        transition: opacity 150ms ease;
      }
    }
  `,
};

const NavBarButton: React.FC<ComponentProps<typeof DevExButton>> = (props) => (
  <DevExButton
    {...props}
    style={{ paddingInline: 8, ...props.style }}
    onClick={(event) => {
      (document.activeElement as HTMLElement).blur(); // remove focus from nav button
      props.onClick?.(event);
    }}
  />
);

type NavItem = {
  icon: ReactNode;
  text: ReactNode;
  path: string;
  eventName: string; // navigation:<eventName>:navigate
};

const NAV_ITEMS: NavItem[] = [
  {
    icon: <FontAwesomeIcon icon={faClipboardList} />,
    text: 'Surveys',
    path: '/studies',
    eventName: 'surveys',
  },
];

export const DevExNavBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const perms = useClientPerms();
  const flags = useClientFlags();

  const theme = useDevExTheme();

  const [loggingOut, setLoggingOut] = useState(false);

  usePageVisibilityTracker();

  const showNavActions = !!window.dx.user;

  const { data: apiAuthTokens } = useClientQuery(AuthClient.getApiAuthTokens, null, {
    enabled: showNavActions,
  });

  const { mutateAsync: logout } = useClientMutation(AuthClient.logout);
  const { mutateAsync: createApiAuthToken } = useClientMutation(AuthClient.createApiAuthToken);

  const onLogout = () => {
    setLoggingOut(true);

    logout() // logout user on server
      .then(() => {
        window.dx.user = undefined; // remove user from local window
        setLoggingOut(false);
        queryClient.removeQueries(); // clear the query cache
        navigate('/auth/sign-in'); // forward to login page
      })
      .catch(() => {
        setLoggingOut(false);
      });
  };

  const apiAuthTokenCreated = apiAuthTokens?.some((token) => token.userId === window.dx.user?.id);

  const getApiAuthToken = () => {
    trackEvent(`settings:api-key:${apiAuthTokenCreated ? 'refresh' : 'generate'}`);
    return createApiAuthToken().then((token) => {
      queryClient.invalidateQueries('GET_API_AUTH_TOKENS');
      return token.key;
    });
  };

  const onNavItemNavigate = (navItem: NavItem) => {
    trackEvent(`navigation:${navItem.eventName}:navigate`);
    navigate(navItem.path);
  };

  const onToggleTheme = () => {
    const newThemeKey = theme.key === 'dark' ? 'light' : 'dark';
    trackEvent('settings:color-theme:toggle', { newThemeKey });
    setTimeout(() => theme.update(newThemeKey), 150);
  };

  const isOrthogUserNonOrthog =
    window.dx.user?.email?.includes('@jellyfish.co') &&
    window.dx.user?.company.slug !== 'orthogonal-networks' &&
    window.dx.user?.company.slug !== 'initech';

  const navItems = [...NAV_ITEMS];
  if (flags.globalTeamsPage) {
    navItems.push({
      icon: <FontAwesomeIcon icon={faUsers} />,
      text: 'Teams',
      path: '/teams',
      eventName: 'teams',
    });
  }

  return (
    <Flex
      css={styles.navBar}
      gap={theme.variable.spacing.lg}
      className={classNames({
        'navBar--warning': isOrthogUserNonOrthog,
      })}
    >
      <Flex align="center" gap={theme.variable.spacing.sm} className={'navBar__logo'}>
        <Icon component={JFLogoNoText} />
        <span className="logo__text">DevEx</span>
      </Flex>

      {showNavActions && (
        <Flex align="center" justify="space-between" style={{ flex: 1 }}>
          <Flex gap={theme.variable.spacing.sm}>
            {navItems.map((item) => {
              const isActive = location.pathname === item.path;
              const activeStyle = {
                background: theme.color.background.active,
                color: theme.color.text.primary,
              };
              return (
                <NavBarButton
                  key={item.path}
                  icon={item.icon}
                  onClick={() => onNavItemNavigate(item)}
                  style={isActive ? activeStyle : undefined}
                >
                  {item.text}
                </NavBarButton>
              );
            })}
          </Flex>

          <Flex gap={theme.variable.spacing.sm} align="center">
            {isOrthogUserNonOrthog && (
              <Tooltip title="You are on a customer instance.">
                <DevExTag color="yellow">
                  <Flex align="center" justify="center" gap={theme.variable.spacing.xs}>
                    <WarningOutlined />
                    <div style={{ fontWeight: 'bold' }}>{window.dx.user?.company.name}</div>
                  </Flex>
                </DevExTag>
              </Tooltip>
            )}

            <Tooltip title={`${capitalize(theme.key)} Mode`}>
              <NavBarButton
                icon={
                  <Icon
                    component={ThemeIcon}
                    style={{
                      transform: theme.key === 'dark' ? undefined : 'rotateZ(180deg)',
                      transition: 'transform 150ms ease',
                    }}
                  />
                }
                onClick={onToggleTheme}
              />
            </Tooltip>

            {
              // GBAC RESTRICTION
              // only customer admins can create API keys
              perms.role.customerAdmin && (
                <DevExPopover
                  content={
                    <DevExButton type="primary" copyValue={getApiAuthToken}>
                      {apiAuthTokenCreated ? 'Refresh' : 'Generate'} API key
                    </DevExButton>
                  }
                  trigger="click"
                  placement="bottom"
                >
                  <NavBarButton icon={<FontAwesomeIcon icon={faGear} />} />
                </DevExPopover>
              )
            }

            <DevExPopover
              content={
                <Flex vertical style={{ fontSize: theme.variable.fontSize.sm }}>
                  <Flex vertical gap={theme.variable.spacing.sm}>
                    <DevExText>{window.dx.user?.email}</DevExText>
                    <DevExText type="secondary">{window.dx.user?.company.name}</DevExText>
                  </Flex>

                  <DevExDivider />

                  <DevExButton
                    type="primary"
                    onClick={onLogout}
                    loading={loggingOut}
                    style={{ justifyContent: 'center' }}
                  >
                    Sign out
                  </DevExButton>
                </Flex>
              }
              trigger="click"
              placement="bottom"
            >
              <NavBarButton icon={<FontAwesomeIcon icon={faUser} />} />
            </DevExPopover>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
