import React from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { css } from 'styled-components';

const styles = {
  textArea: css`
    &.ant-input {
      background: ${(props) => props.theme.color.background.default};
      border-color: ${(props) => props.theme.color.border.primary};
      color: ${(props) => props.theme.color.text.primary};
      border-radius: ${(props) => props.theme.variable.borderRadius};
      line-height: ${(props) => props.theme.variable.lineHeight};
      transition: none;

      &::placeholder {
        color: ${(props) => props.theme.color.text.tertiary};
      }

      &:focus {
        border-color: ${(props) => props.theme.color.border.hovered};
      }
    }

    .ant-input-suffix {
      .ant-input-data-count {
        font-size: ${(props) => props.theme.variable.fontSize.xs};
        color: ${(props) => props.theme.color.text.tertiary};
      }
    }
  `,
};

interface DevExTextAreaProps extends Omit<TextAreaProps, 'onChange'> {
  onChange?: (value: string) => void;
}

export const DevExTextArea: React.FC<DevExTextAreaProps> = (props) => {
  return (
    <Input.TextArea
      css={styles.textArea}
      {...props}
      onChange={(event) => props.onChange?.(event.target.value)}
    />
  );
};
