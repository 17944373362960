import React, { ReactNode } from 'react';
import { Flex } from 'antd';
import { IconDefinition } from '@fortawesome/pro-thin-svg-icons';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';

import { CircledFontAwesomeIcon } from './CircledFontAwesomeIcon';

const styles = {
  empty: css`
    .empty__label {
      color: ${(props) => props.theme.color.text.tertiary};
      text-align: center;
      line-height: ${(props) => props.theme.variable.lineHeight};
    }
  `,
};

type DevExEmptyProps = {
  icon: IconDefinition;
  label?: ReactNode;
  style?: React.CSSProperties;
  iconSize?: number;
};

export const DevExEmpty: React.FC<DevExEmptyProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <Flex vertical css={styles.empty} style={props.style} gap={theme.variable.spacing.md}>
      <Flex justify="center">
        <CircledFontAwesomeIcon
          icon={props.icon}
          style={{ color: theme.color.text.tertiary }}
          iconSize={props.iconSize}
        />
      </Flex>
      {!!props.label && <div className={'empty__label'}>{props.label}</div>}
      {props.children}
    </Flex>
  );
};
