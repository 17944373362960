export const THEME_VARIABLES = {
  variable: {
    spacing: {
      xs: '4px',
      sm: '8px',
      md: '16px',
      lg: '24px',
      xl: '48px',
      xxl: '96px',
    },
    borderRadius: '8px',
    fontSize: {
      xxs: '12px', // condensed chart labels
      xs: '14px', // chart labels / footnotes / tag text
      sm: '16px', // body text / default buttons
      md: '18px', // large text / large buttons / tabs
      lg: '20px', // card headers
      xl: '28px', // page headers
      xxl: '52px', // page statements
    },
    lineHeight: 1.5,
    fontFamily: {
      primary: '"Source Sans Pro", "Segoe UI", "Droid Sans", Tahoma, Arial, sans-serif',
      secondary: '"Roobert", sans-serif',
    },
    opacity: {
      disabled: 0.5,
    },
  },
};
