import React from 'react';
import { Flex } from 'antd';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';

import JFLogoDark from 'jf/images/jf-dev-ex-logo-text-dark-mode.svg';
import JFLogoLight from 'jf/images/jf-dev-ex-logo-text-light-mode.svg';

const DEFAULT_HEIGHT = '40px';

type DevExLogoProps = {
  style?: React.CSSProperties;
  height?: string;
};

export const DevExLogo: React.FC<DevExLogoProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <Flex align="center" style={props.style}>
      {theme.key === 'light' ? (
        <JFLogoLight height={props.height ?? DEFAULT_HEIGHT} />
      ) : (
        <JFLogoDark height={props.height ?? DEFAULT_HEIGHT} />
      )}
    </Flex>
  );
};
