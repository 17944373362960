/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Flex } from 'antd';
import Icon from '@ant-design/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AuthClient } from 'jf/api';
import { refreshOpenAPIHeaders } from 'jf/common/DevEx';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExDivider } from 'jf/components/DevExDivider';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExLink } from 'jf/components/DevExLink';
import { DevExEnv, getDevExEnv } from 'jf/utils/getDevExEnv';
import { useClientMutationError } from 'jf/utils/useClientMutationError';
import { useClientMutation } from 'jf/utils/useClientQuery';

import { AuthFormContext, authStyles, EMAIL_PLACEHOLDER } from './AuthPage';

import JFLogoNoText from 'jf/images/jf-logo-no-text.svg';

export const onLoginWithJellyfish = async (next?: string) => {
  const env = getDevExEnv();

  let empAddress = 'http://localhost:8000';

  switch (env.key) {
    case DevExEnv.STAGING:
      empAddress = `https://${window.dx.jf_env}.thejellyfish.net`;
      break;
    case DevExEnv.PROD:
    case DevExEnv.DEMO:
    case DevExEnv.DEV:
      empAddress = env.appOrigin;
      break;
  }

  let ssoPath = `${empAddress}/account/devex/sso`;

  // 'next' must be a relative url so we don't allow an open redirect
  if (next && next.startsWith('/')) {
    // The `next` parameter here is used to convey the redirect destination
    // that the user will be sent to after they exit the SSO flow.
    const searchParams = new URLSearchParams({ next });
    ssoPath += `?${searchParams.toString()}`;
  }

  window.location.href = ssoPath;
};

export const SignInForm: React.FC = () => {
  const theme = useDevExTheme();
  const navigate = useNavigate();
  const { bakunawaDevexFreeLoginQ224: showFreeLogin } = useFlags();
  const { email, setEmail } = useContext(AuthFormContext);

  const [password, setPassword] = useState('');

  const login = useClientMutation(AuthClient.login);
  const loginError = useClientMutationError();

  const validated = !!(email && password);

  const onLoginWithEmail = (next?: string) => {
    login
      .mutateAsync({
        requestBody: {
          username: email,
          password,
        },
      })
      .then((user) => {
        window.dx.user = user;
        refreshOpenAPIHeaders();

        // 'next' must be a relative url so we don't allow an open redirect
        if (next && next.startsWith('/')) {
          // Use href to navigate to bypass the react router,
          // otherwise we can only navigate to react app locations.

          // The redirect here is important for users authenticating via their
          // email address and password. SSO users will encounter a backend
          // redirect that isn't otherwise available to users.
          window.location.href = next;
        } else {
          navigate('/');
        }
      })
      .catch(loginError.handle);
  };

  // reset error if email/password is changed
  useEffect(() => {
    if (!login.isIdle) {
      loginError.reset();
    }
  }, [email, password]);

  const [searchParams] = useSearchParams();

  const next = searchParams.get('next') || undefined;

  return (
    <DevExCard css={authStyles.form}>
      <div className="card__header">
        <div className="header__title">Sign In</div>
      </div>

      <Flex vertical gap={theme.variable.spacing.lg}>
        {showFreeLogin && (
          <>
            <Flex vertical gap={theme.variable.spacing.md}>
              <Flex vertical gap={theme.variable.spacing.sm}>
                <label>Email</label>
                <DevExInput
                  placeholder={EMAIL_PLACEHOLDER}
                  type="email"
                  value={email}
                  onChange={setEmail}
                />
              </Flex>

              <Flex vertical gap={theme.variable.spacing.sm}>
                <label>Password</label>
                <DevExInput
                  placeholder="••••••••••••"
                  type="password"
                  value={password}
                  onChange={setPassword}
                  onKeyUp={(event) => event.key === 'Enter' && onLoginWithEmail(next)}
                />

                {!!loginError.message && (
                  <div className="card__message message--error">{loginError.message}</div>
                )}

                <DevExLink
                  style={{ fontSize: theme.variable.fontSize.xs, alignSelf: 'end' }}
                  onClick={() => navigate('/auth/reset-password')}
                >
                  Forgot your password?
                </DevExLink>
              </Flex>
            </Flex>

            <Flex vertical gap={theme.variable.spacing.sm}>
              <DevExButton
                type="primary"
                onClick={() => onLoginWithEmail(next)}
                disabled={!validated}
                loading={login.isLoading}
              >
                Sign in
              </DevExButton>
            </Flex>

            <DevExDivider style={{ margin: 0 }}>or</DevExDivider>
          </>
        )}

        <DevExButton
          type={showFreeLogin ? 'outline' : 'primary'}
          icon={<Icon component={JFLogoNoText} />}
          onClick={() => onLoginWithJellyfish(next)}
        >
          Sign in with Jellyfish
        </DevExButton>

        {showFreeLogin && (
          <div className="card__footer">
            Don't have an account?{' '}
            <DevExLink onClick={() => navigate('/auth/create-account')}>Create one now</DevExLink>
          </div>
        )}
      </Flex>
    </DevExCard>
  );
};
