import React from 'react';
import { Flex } from 'antd';
import { faArrowToRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { css } from 'styled-components';

import { useLivePropState } from 'jf/utils/useLivePropState';

const styles = {
  sidePanel: css`
    background: ${(props) => props.theme.color.background.default};
    box-shadow: 0 0 0 1px ${(props) => props.theme.color.border.primary};
    transition: width 150ms ease;

    .sidePanel__collapse {
      border-top: 1px solid ${(props) => props.theme.color.border.primary};
      display: flex;
      padding-block: ${(props) => props.theme.variable.spacing.sm};
      padding-inline: ${(props) => props.theme.variable.spacing.md};

      &:hover,
      &:focus {
        background-color: ${(props) => props.theme.color.background.hovered};
        cursor: pointer;
      }

      svg {
        font-size: 16px;
        transition: transform 150ms ease;
      }
    }

    &.sidePanel--collapsed {
      .sidePanel__collapse {
        svg {
          transform: rotateY(180deg);
        }
      }
    }
  `,
};

type DevExSidePanelProps = {
  width: number;
  className?: string;
  collapse?: {
    collapsed: boolean;
    onCollapse: (collapsed: boolean) => void;
    collapsedWidth: number;
  };
};

export const DevExSidePanel: React.FC<DevExSidePanelProps> = (props) => {
  const [collapsed, setCollapsed] = useLivePropState(
    props.collapse?.collapsed ?? false,
    props.collapse?.onCollapse
  );

  return (
    <Flex
      vertical
      justify="space-between"
      css={styles.sidePanel}
      style={{
        width: collapsed ? props.collapse?.collapsedWidth : props.width,
      }}
      className={classNames(props.className, { 'sidePanel--collapsed': collapsed })}
    >
      {props.children}

      {!!props.collapse && (
        <Flex
          align="center"
          justify="flex-start"
          className={'sidePanel__collapse'}
          onClick={() => setCollapsed(!collapsed)}
        >
          <FontAwesomeIcon icon={faArrowToRight} />
        </Flex>
      )}
    </Flex>
  );
};
