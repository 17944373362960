import { keyBy } from 'lodash-es';

import {
  NumericScore,
  NumericScoreWithTeamAndSegment,
  NumericScoreWithTeamOrSegment,
  StudyLibraryClient,
  TopicVerbatimWithTeamRef,
} from 'jf/api';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { getTopicPriorityBySlug } from '../insights/getTopicPriorityBySlug';
import { useStudyScores } from '../useStudyScores';
import { useStudyScoresBySegment } from '../useStudyScoresBySegment';
import { useStudyVerbatims } from '../useStudyVerbatims';

type UseTopicResultsProps = {
  studyRef: string | undefined;
  topicSlug: string | undefined;
  teamRef?: string;
  segmentRef?: string;
};

/**
 * Compile the score, prompt scores, and verbatims for a topic in a given study.
 * These results can be filtered by a given `teamRef` and `segmentRef`.
 */
export const useTopicResults = (props: UseTopicResultsProps) => {
  const segmentFilter = !!props.segmentRef;

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);
  const { data: topicScores } = useStudyScores({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    type: 'TOPIC',
  });
  const { data: promptScores } = useStudyScores({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    type: 'PROMPT',
  });
  const { data: topicScoresBySegment } = useStudyScoresBySegment({
    studyRef: segmentFilter ? props.studyRef : undefined, // don't fetch if we don't need it
    teamRef: props.teamRef,
    type: 'TOPIC',
  });
  const { data: promptScoresBySegment } = useStudyScoresBySegment({
    studyRef: segmentFilter ? props.studyRef : undefined, // don't fetch if we don't need it
    teamRef: props.teamRef,
    type: 'PROMPT',
  });
  const { data: topicVerbatims } = useStudyVerbatims({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
  });

  let topicScore: NumericScore | undefined = undefined;
  let topicPromptScores: NumericScoreWithTeamOrSegment[] | undefined = undefined;
  let verbatims: TopicVerbatimWithTeamRef[] | undefined = undefined;
  let priority: number | undefined = undefined;

  if (
    !topics ||
    !prompts ||
    !topicScores ||
    !promptScores ||
    (segmentFilter && !topicScoresBySegment) ||
    (segmentFilter && !promptScoresBySegment) ||
    !topicVerbatims ||
    !props.topicSlug
  ) {
    return {
      score: topicScore,
      promptScores: topicPromptScores,
      votes: verbatims,
      verbatims,
      priority,
    };
  }

  const promptsBySlug = keyBy(prompts, 'slug');

  const topicPriorityBySlug = getTopicPriorityBySlug(topics, topicScores, topicVerbatims);
  priority = topicPriorityBySlug[props.topicSlug];

  const isSelectedSegment = (score: NumericScoreWithTeamAndSegment) =>
    score.segment?.ref === props.segmentRef;

  const isSpecifiedTopic = (score: NumericScore) => score.slug === props.topicSlug;

  const isIncludedPrompt = (score: NumericScore) =>
    promptsBySlug[score.slug].topicSlug === props.topicSlug;

  if (segmentFilter) {
    topicScore = topicScoresBySegment!.filter(isSelectedSegment).find(isSpecifiedTopic);
    topicPromptScores = promptScoresBySegment!.filter(isSelectedSegment).filter(isIncludedPrompt);
  } else {
    topicScore = topicScores.find(isSpecifiedTopic);
    topicPromptScores = promptScores.filter(isIncludedPrompt);
  }

  promptScores.sort((a, b) => a.scaledAvg - b.scaledAvg);

  verbatims = [];
  if (!props.segmentRef) {
    verbatims = topicVerbatims.filter((verbatim) => verbatim.topicSlug === props.topicSlug);
  }

  return {
    score: topicScore,
    promptScores: topicPromptScores,
    votes: verbatims,
    verbatims: verbatims.filter((verbatim) => verbatim.responseText),
    priority,
  };
};
