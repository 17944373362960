import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';
import { DateTime } from 'luxon';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { BenchmarkClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExModal } from 'jf/components/DevExModal';
import { DevExRadioButtons } from 'jf/components/DevExRadioButtons';
import { ThumbsFeedback } from 'jf/components/ThumbsFeedback';
import { formatISO } from 'jf/utils/formatISO';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { BenchmarkPercentileChart } from './BenchmarkPercentileChart';
import { BenchmarkPercentile, useBenchmark } from './BenchmarkSelect';

export const DEFAULT_PERCENTILE_MESSAGE =
  'What benchmark percentile would you like your scores to be compared against?';

const styles = {
  header: css`
    font-size: ${(props) => props.theme.variable.fontSize.md};
    font-weight: 600;
  `,
};

const BENCHMARK_PERCENTILES: BenchmarkPercentile[] = ['p50', 'p75', 'p90'];

export const BenchmarkModal: React.FC = () => {
  const modal = useJFOverlay(BenchmarkModal);
  const theme = useDevExTheme();
  const benchmark = useBenchmark();

  const [percentile, setPercentile] = useState<BenchmarkPercentile>();

  const { data: cohorts } = useClientQuery(BenchmarkClient.getBenchmarkCohorts, null);
  const cohort = cohorts?.at(-1); // grab the most recent cohort

  const formattedStartDate = formatISO(cohort?.startDate, DateTime.DATE_MED);
  const formattedEndDate = formatISO(cohort?.endDate, DateTime.DATE_MED);
  const onCancel = () => {
    modal.close();
  };

  const onClear = () => {
    trackEvent('survey-analyzer:benchmarks:clear');
    benchmark.setPercentile(undefined);
    modal.close(true);
  };

  const onOk = () => {
    trackEvent('survey-analyzer:benchmarks:set', { percentile });
    benchmark.setPercentile(percentile);
    modal.close(true);
  };

  useEffect(() => {
    setPercentile(benchmark.percentile);
  }, [benchmark.percentile]);

  return (
    <DevExModal
      open={modal.isOpened}
      onCancel={onCancel}
      title="Benchmark"
      footer={
        <>
          <DevExButton type="outline" onClick={onClear} disabled={!benchmark.percentile}>
            Clear
          </DevExButton>
          <DevExButton type="primary" onClick={onOk} disabled={!percentile}>
            {'Set benchmark'}
          </DevExButton>
        </>
      }
      width={650}
    >
      <Flex
        vertical
        gap={theme.variable.spacing.md}
        style={{ paddingInline: theme.variable.spacing.sm }}
      >
        <ThumbsFeedback title="What do you think of benchmarks?" eventName="benchmarks" />

        <div css={styles.header}> Percentile</div>
        <div>{DEFAULT_PERCENTILE_MESSAGE}</div>
        <DevExRadioButtons
          items={BENCHMARK_PERCENTILES.map((key) => ({
            key,
            label: `${key.slice(1)}th`,
          }))}
          value={percentile}
          onChange={(percentile: BenchmarkPercentile) => setPercentile(percentile)}
        />
        <BenchmarkPercentileChart percentile={percentile} />
        <div
          style={{ paddingTop: theme.variable.spacing.lg, fontSize: theme.variable.fontSize.xs }}
        >
          Our benchmark data is based on DevEx scores collected from
          <strong> all companies </strong> that conducted a DevEx survey
          <strong>
            {' '}
            between {formattedStartDate} and {formattedEndDate}.
          </strong>
        </div>
      </Flex>
    </DevExModal>
  );
};
