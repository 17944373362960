/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatedStarredTopic } from '../models/CreatedStarredTopic';
import type { CreatedStarredTopicActionItem } from '../models/CreatedStarredTopicActionItem';
import type { CreatedStarredTopicActionItemRequest } from '../models/CreatedStarredTopicActionItemRequest';
import type { CreatedStarredTopicRequest } from '../models/CreatedStarredTopicRequest';
import type { CreatedStudySequence } from '../models/CreatedStudySequence';
import type { CreatedStudySequenceRequest } from '../models/CreatedStudySequenceRequest';
import type { StarredTopic } from '../models/StarredTopic';
import type { StudySequence } from '../models/StudySequence';
import type { UpdatedStarredTopicActionItem } from '../models/UpdatedStarredTopicActionItem';
import type { UpdatedStarredTopicActionItemRequest } from '../models/UpdatedStarredTopicActionItemRequest';
import type { UpdatedStarredTopicRequest } from '../models/UpdatedStarredTopicRequest';
import type { UpdatedStudySequenceRequest } from '../models/UpdatedStudySequenceRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StudySequenceClient {

    /**
     * Create a new study sequence.
     * @returns CreatedStudySequence
     * @throws ApiError
     */
    public static createStudySequence({
        requestBody,
    }: {
        requestBody: CreatedStudySequenceRequest,
    }): CancelablePromise<CreatedStudySequence> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/study-sequence',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a specific study sequence.
     * @returns StudySequence
     * @throws ApiError
     */
    public static getStudySequence({
        sequenceRef,
    }: {
        sequenceRef: string,
    }): CancelablePromise<StudySequence> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study-sequence/{sequenceRef}',
            path: {
                'sequenceRef': sequenceRef,
            },
        });
    }

    /**
     * Update a specific study sequence.
     * @returns void
     * @throws ApiError
     */
    public static updateStudySequence({
        sequenceRef,
        requestBody,
    }: {
        sequenceRef: string,
        requestBody?: UpdatedStudySequenceRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/study-sequence/{sequenceRef}',
            path: {
                'sequenceRef': sequenceRef,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a specific study sequence.
     * @returns void
     * @throws ApiError
     */
    public static deleteStudySequence({
        sequenceRef,
    }: {
        sequenceRef: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/study-sequence/{sequenceRef}',
            path: {
                'sequenceRef': sequenceRef,
            },
        });
    }

    /**
     * Creates a starred topic.
     * @returns CreatedStarredTopic
     * @throws ApiError
     */
    public static createStarredTopic({
        sequenceRef,
        requestBody,
    }: {
        sequenceRef: string,
        requestBody: CreatedStarredTopicRequest,
    }): CancelablePromise<CreatedStarredTopic> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/study-sequence/{sequenceRef}/starred-topic',
            path: {
                'sequenceRef': sequenceRef,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update a specific starred topic.
     * @returns void
     * @throws ApiError
     */
    public static updateStarredTopic({
        sequenceRef,
        topicSlug,
        requestBody,
    }: {
        sequenceRef: string,
        topicSlug: string,
        requestBody?: UpdatedStarredTopicRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/study-sequence/{sequenceRef}/starred-topic/{topicSlug}',
            path: {
                'sequenceRef': sequenceRef,
                'topicSlug': topicSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a specific starred topic.
     * @returns void
     * @throws ApiError
     */
    public static deleteStarredTopic({
        sequenceRef,
        topicSlug,
    }: {
        sequenceRef: string,
        topicSlug: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/study-sequence/{sequenceRef}/starred-topic/{topicSlug}',
            path: {
                'sequenceRef': sequenceRef,
                'topicSlug': topicSlug,
            },
        });
    }

    /**
     * Creates a starred topic action item.
     * @returns CreatedStarredTopicActionItem
     * @throws ApiError
     */
    public static createStarredTopicActionItem({
        sequenceRef,
        topicSlug,
        requestBody,
    }: {
        sequenceRef: string,
        topicSlug: string,
        requestBody: CreatedStarredTopicActionItemRequest,
    }): CancelablePromise<CreatedStarredTopicActionItem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/study-sequence/{sequenceRef}/starred-topic/{topicSlug}/action-item',
            path: {
                'sequenceRef': sequenceRef,
                'topicSlug': topicSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Updates an action item for a starred topic.
     * @returns UpdatedStarredTopicActionItem
     * @throws ApiError
     */
    public static updateStarredTopicActionItem({
        actionItemRef,
        sequenceRef,
        topicSlug,
        requestBody,
    }: {
        actionItemRef: string,
        sequenceRef: string,
        topicSlug: string,
        requestBody?: UpdatedStarredTopicActionItemRequest,
    }): CancelablePromise<UpdatedStarredTopicActionItem> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/study-sequence/{sequenceRef}/starred-topic/{topicSlug}/action-item/{actionItemRef}',
            path: {
                'actionItemRef': actionItemRef,
                'sequenceRef': sequenceRef,
                'topicSlug': topicSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Deletes an action item for a starred topic.
     * @returns void
     * @throws ApiError
     */
    public static deleteStarredTopicActionItem({
        actionItemRef,
        sequenceRef,
        topicSlug,
    }: {
        actionItemRef: string,
        sequenceRef: string,
        topicSlug: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/study-sequence/{sequenceRef}/starred-topic/{topicSlug}/action-item/{actionItemRef}',
            path: {
                'actionItemRef': actionItemRef,
                'sequenceRef': sequenceRef,
                'topicSlug': topicSlug,
            },
        });
    }

    /**
     * Get all starred topics.
     * @returns StarredTopic
     * @throws ApiError
     */
    public static getStarredTopics({
        sequenceRef,
    }: {
        sequenceRef: string,
    }): CancelablePromise<Array<StarredTopic>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study-sequence/{sequenceRef}/starred-topics',
            path: {
                'sequenceRef': sequenceRef,
            },
        });
    }

    /**
     * Get all study sequences.
     * @returns StudySequence
     * @throws ApiError
     */
    public static getStudySequences(): CancelablePromise<Array<StudySequence>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study-sequences',
        });
    }

}
