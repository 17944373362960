import React, { useEffect, useMemo, useState } from 'react';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBy } from 'lodash-es';

import { trackEvent } from 'jf/analytics/Analytics';
import { StudyScoreClient, Team } from 'jf/api';
import { DevExCascader } from 'jf/components/DevExCascader';
import { useClientQueries } from 'jf/utils/useClientQuery';

import { useSequenceStudies } from '../studySequence/useSequenceStudies';

type SequenceTeamCascaderProps = {
  sequenceRef: string;
  onChange?: (value?: string) => void;
  allowClear?: boolean;
  disabled?: boolean;
  value?: string;
};
export const SequenceTeamCascader: React.FC<SequenceTeamCascaderProps> = (props) => {
  const sequenceStudies = useSequenceStudies(props.sequenceRef);
  const scoredTeamsQueries = useClientQueries(
    StudyScoreClient.getStudyScoredTeams,
    sequenceStudies?.map((study) => ({ studyRef: study.ref })) ?? []
  );
  const scoredTeams = useMemo(() => {
    if (!scoredTeamsQueries.every((query) => query.isSuccess)) {
      return undefined;
    }
    const dict: { [key: string]: Team } = {};
    scoredTeamsQueries.forEach((query) => {
      const studyTeams = query.data!;
      studyTeams.map((team) => {
        dict[team.ref] = team;
      });
    });
    const sequenceTeams = Object.values(dict);
    return sequenceTeams;
  }, [scoredTeamsQueries]);

  const [value, setValue] = useState<string[]>();

  const teamOptions = Object.entries(groupBy(scoredTeams, 'level')).map(([level, teams]) => ({
    value: level.toString(),
    label: teams[0].levelName,
    children: teams.map((team) => ({ value: team.ref, label: team.value })),
  }));

  useEffect(() => {
    if (scoredTeams && props.value) {
      const team = scoredTeams?.find((team) => team.ref === props.value);
      if (team && !value) {
        setValue([team.level.toString(), team.ref]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoredTeams]);

  return (
    <DevExCascader
      placeholder={
        <>
          <FontAwesomeIcon icon={faUsers} />
          Team
        </>
      }
      options={teamOptions}
      value={value}
      onChange={(value) => {
        setValue(value);

        const teamRef = value?.at(-1);
        props.onChange?.(teamRef);

        if (teamRef && scoredTeams) {
          const team = scoredTeams.find((team) => team.ref === teamRef);
          if (team) {
            trackEvent('series-analyzer:team-selector:click', {
              teamLevel: team.level,
              isLeaf: team.isLeaf,
            });
          }
        }
      }}
      allowClear={props.allowClear}
      disabled={props.disabled}
      size="large"
    />
  );
};
