/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PublicStudyClient, Study } from 'jf/api';
import { startFullstory } from 'jf/common/DevEx';
import { DevExLoader } from 'jf/components/DevExLoader';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import { PreviewStudyHeader } from './PreviewStudyHeader';
import { StudyPage } from './StudyPage';
import { StudyStateProvider, useStudyState } from './StudyStateContext';

export const StudyController: React.FC = () => (
  <StudyStateProvider>
    <StudyPageController />
  </StudyStateProvider>
);

const StudyPageController: React.FC = () => {
  const studyState = useStudyState();

  const { data: study } = useClientQuery(PublicStudyClient.getPublicStudy, {
    studyRef: studyState.refs.studyRef,
  });

  const isPreview = study?.status === Study.status.DRAFT;
  useEffect(() => startFullstory(`survey-${isPreview ? 'preview' : 'taker'}`), []);

  const { data: studyResponse } = useClientQuery(PublicStudyClient.getStudyResponse, {
    studyResponseRef: studyState.refs.studyResponseRef,
  });

  const navigate = useNavigate();

  const createStudyResponse = useClientMutation(PublicStudyClient.createStudyResponse);

  useEffect(() => {
    if (study && studyState.loaded) {
      const missingStudyResponse = !studyState.refs.studyResponseRef;

      // get/create response/respondent by email
      if (
        !isPreview &&
        missingStudyResponse &&
        studyState.refs.studyRef &&
        (studyState.email || studyState.refs.targetRef)
      ) {
        createStudyResponse
          .mutateAsync({
            requestBody: {
              studyRef: studyState.refs.studyRef,
              targetRef: studyState.refs.targetRef,
              email: studyState.email,
            },
          })
          .then((data) =>
            studyState.update({
              refs: { ...studyState.refs, studyResponseRef: data.ref },
            })
          )
          .catch((error) => {
            studyState.update({
              stageIndex: 0,
              email: '',
              refs: { ...studyState.refs, targetRef: undefined },
            });
            navigate(`/study/${studyState.refs.studyRef}?message=${error.body}`);
          });
      }

      // skip email collection for preview mode
      if (isPreview && studyState.stageIndex === 0) {
        studyState.update({ stageIndex: 1 });
      }
    }
  }, [studyState, study]);

  useEffect(() => {
    // if StudyResponse is loading by targetRef, skip email step
    if (studyState.refs.targetRef && !studyState.refs.studyResponseRef) {
      studyState.update({ stageIndex: 1 });
    }
  }, [studyState.refs.targetRef]);

  useEffect(() => {
    // if study is closed, skip to the end where we will display a message
    if (study?.status === Study.status.CLOSED) {
      studyState.update({ stageIndex: 2 });
    }
  }, [study]);

  useEffect(() => {
    if (!studyResponse) {
      return;
    }

    if (studyResponse.endTime) {
      studyState.update({
        savedEndTime: {
          endTime: studyResponse.endTime,
          saved: true,
        },
      });
    }

    if (studyState.stageIndex === 0) {
      // progress stageIndex based on if study response has already been submitted
      studyState.update({ stageIndex: studyResponse.endTime ? 2 : 1 });
    }
  }, [studyResponse]);

  if (
    !study || // loading Study
    (study.status !== Study.status.OPEN && studyState.stageIndex < 1) || // skipping to StudyEnd
    (studyState.refs.targetRef && !studyState.refs.studyResponseRef) // loading StudyResponse by targetRef
  ) {
    return <DevExLoader />;
  }

  return (
    <>
      {isPreview && <PreviewStudyHeader />}

      <StudyPage />
    </>
  );
};
