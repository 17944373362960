import { DevExThemeColors } from '../DevExTheme';

import { LIGHT_THEME } from './lightTheme';

export const DARK_THEME: DevExThemeColors = {
  color: {
    text: {
      primary: 'neutral-1 95%',
      secondary: 'neutral-1 60%',
      tertiary: 'neutral-1 40%',
      disabled: 'neutral-1 30%',
      placeholder: 'neutral-1 40%',
      contrast: 'neutral-1 95%',
      dark: 'neutral-15',
    },
    background: {
      default: 'neutral-15', // IF UPDATED, ALSO UPDATE jf_tags.py!
      raised: 'neutral-13',
      sunken: 'neutral-11',
      disabled: 'neutral-1 15%',
      active: 'purple-4 16%',
      hovered: 'purple-5 16%',
      overlay: 'neutral-13',
      backdrop: 'neutral-15 40%',
      contrast: 'neutral-15',
    },
    border: {
      primary: 'neutral-11',
      secondary: 'neutral-12',
      disabled: 'neutral-13',
      active: 'purple-6',
      hovered: 'purple-7',
    },
    shadow: {
      default: 'neutral-15 64%',
      subtle: 'neutral-15 16%',
    },
    brand: {
      default: 'purple-6',
      active: 'purple-6',
      hovered: 'purple-7',
      background: {
        default: 'purple-4 16%',
        hovered: 'purple-5 16%',
      },
      logo: 'lime-5',
    },
    offbrand: {
      default: 'lime-5',
      active: 'lime-5',
      hovered: 'lime-6',
      background: {
        default: 'lime-5 16%',
        hovered: 'lime-6 16%',
      },
    },
    scrollbar: {
      track: {
        default: 'neutral-1 15%',
      },
      thumb: {
        default: 'neutral-1 15%',
        hovered: 'neutral-1 30%',
      },
    },
    tooltip: {
      text: 'neutral-15',
      background: 'neutral-1',
    },
    skeleton: {
      background: 'neutral-1 5%',
    },
    status: {
      default: {
        text: 'neutral-5',
        background: 'neutral-8 20%',
      },
      info: {
        text: 'blue-4',
        background: 'blue-9 15%',
        border: 'blue-9',
      },
      warning: {
        text: 'lime-6',
        background: 'lime-10 15%',
        border: 'lime-10',
      },
      error: {
        text: 'red-5',
        background: 'red-10 15%',
        border: 'red-10',
      },
      success: {
        text: 'green-5',
        background: 'green-10 15%',
        border: 'green-10',
      },
      discovery: {
        text: 'purple-3',
        background: 'purple-9 15%',
        border: 'purple-9',
      },
    },
    tag: {
      default: {
        text: 'neutral-5',
        background: 'neutral-8 20%',
      },
      green: {
        text: 'green-5',
        background: 'green-9',
      },
      blue: {
        text: 'blue-4',
        background: 'blue-9',
      },
      yellow: {
        text: 'lime-6',
        background: 'lime-10',
      },
      red: {
        text: 'red-5',
        background: 'red-10',
      },
      purple: {
        text: 'purple-3',
        background: 'purple-9',
      },
    },
    link: {
      text: {
        default: 'blue-5',
        hovered: 'blue-4',
        active: 'blue-6',
      },
    },
    card: {
      background: {
        default: 'neutral-14',
      },
    },
    banner: {
      background: 'neutral-12',
      text: 'neutral-1 95%',
    },
    reveal: LIGHT_THEME.color.reveal,
    visualization: {
      ...LIGHT_THEME.color.visualization,
      grid: 'neutral-1 15%',
      palette: {
        1: {
          data: 'lime-4',
        },
      },
    },
    topic: {
      tools_envs: 'blue-2',
      clear_dir: 'red-4',
      team_proc: 'lime-4',
      con_switch: 'purple-5',
      codebase: 'green-4',
      review: 'magenta-4',
      test: 'blue-4',
      release: 'neutral-8',
      oncall: 'blue-6',
      docs: 'red-6',
      learning: 'lime-7',
      onboard: 'purple-8',
      dev_enable: 'green-7',
      satis: 'magenta-7',
      perc_prod: 'red-8',
      other: 'neutral-10',
    },
    star: {
      text: {
        default: 'yellow-6',
        hovered: 'yellow-5',
      },
      border: {
        default: 'neutral-8',
        hovered: 'neutral-6',
      },
    },
  },
};
