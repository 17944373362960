import React from 'react';
import { Flex } from 'antd';
import {
  faArrowRightLong,
  faArrowTrendDown,
  faArrowTrendUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExDash } from 'jf/components/DevExColumnDash';

type TrendCellProps = {
  trend?: number;
  style?: React.CSSProperties;
};

export const TrendCell: React.FC<TrendCellProps> = ({ trend, style }) => {
  const theme = useDevExTheme();

  if (trend === undefined) {
    return <DevExDash centered />;
  }

  return (
    <Flex justify="center" gap={theme.variable.spacing.xs} style={style}>
      <span>
        {trend === 0 || trend < 0 ? '' : '+'}
        {trend}
      </span>
      <div>
        {trend === 0 && (
          <FontAwesomeIcon
            icon={faArrowRightLong}
            color={theme.color.visualization.polarity.neutral.text}
          />
        )}
        {trend > 0 && (
          <FontAwesomeIcon
            icon={faArrowTrendUp}
            color={theme.color.visualization.polarity.positive.high.text}
          />
        )}
        {trend < 0 && (
          <FontAwesomeIcon
            icon={faArrowTrendDown}
            color={theme.color.visualization.polarity.negative.high.text}
          />
        )}
      </div>
    </Flex>
  );
};
