import { useMemo } from 'react';
import { keyBy, sortBy } from 'lodash-es';

import { StudyClient, StudyLibraryClient, StudyScoreClient } from 'jf/api';
import { arrayOf } from 'jf/utils/arrayOf';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyVerbatims } from '../useStudyVerbatims';

type DataRecord = {
  responseText: string;
  topicLabel: string;
  teamName?: string;
};

const getCsvData = (data: DataRecord[]) => {
  const headerRow = ['Topic', 'Comment', 'Team'];

  const csvData = [headerRow];

  if (!data.length) {
    csvData.push(arrayOf(headerRow.length).map(() => '')); // empty row
    return csvData;
  }

  for (const datum of data) {
    const dataRow = [datum.topicLabel, datum.responseText, datum.teamName ?? ''];

    csvData.push(dataRow);
  }

  return csvData;
};

const getJsonData = (data: DataRecord[]) => {
  return data.map((datum) => {
    const json = {
      topic: datum.topicLabel,
      comment: datum.responseText,
      team: datum.teamName,
    };

    return json;
  });
};

type UseExportableStudyVerbatimsProps = {
  studyRef: string | undefined;
  teamRef: string | undefined;
};

export const useExportableStudyVerbatims = (props: UseExportableStudyVerbatimsProps) => {
  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: props.studyRef });
  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const { data: topicVerbatims } = useStudyVerbatims({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
  });
  const { data: scoredTeams } = useClientQuery(StudyScoreClient.getStudyScoredTeams, {
    studyRef: props.studyRef,
  });

  const [csv, json] = useMemo(() => {
    if (!topics || !topicVerbatims || !scoredTeams || !study) {
      return [];
    }

    const topicsBySlug = keyBy(topics, 'slug');
    const teamBySlug = keyBy(scoredTeams, 'ref');

    let data = topicVerbatims.map((verbatim) => {
      return {
        responseText: verbatim.responseText,
        topicLabel: topicsBySlug[verbatim.topicSlug].label,
        teamName: verbatim.teamRef && teamBySlug[verbatim.teamRef]?.value,
      };
    });

    data = sortBy(data, ['topicLabel', 'teamName']);

    return [getCsvData(data), getJsonData(data)];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topics, topicVerbatims, scoredTeams, study]);

  return { csv, json };
};
