import React, { useState } from 'react';
import { Flex } from 'antd';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';

import { DevExButton } from './DevExButton';
import { DevExPopover } from './DevExPopover';
import { DevExTag } from './DevExTag';
import { DevExTextArea } from './DevExTextArea';

const styles = {
  feedback: css`
    border-radius: ${(props) => props.theme.variable.borderRadius} !important;
    width: fit-content;
    cursor: pointer;
  `,
};

type DevExFeedbackPopoverProps = {
  setOpen: (bool: boolean) => void;
  open: boolean;
  submitted: boolean;
  setSubmitted: (bool: boolean) => void;
} & Pick<DevExFeedbackProps, 'eventName' | 'placeholder' | 'eventContext'>;

export const FeedbackPopover: React.FC<
  DevExFeedbackPopoverProps & Pick<DevExFeedbackProps, 'eventName' | 'placeholder'>
> = (props) => {
  const [feedback, setFeedback] = useState('');

  const theme = useDevExTheme();
  const handleClose = () => {
    props.setOpen(false);
    setTimeout(() => {
      setFeedback('');
      props.setSubmitted(false);
    }, 300);
  };
  const onSubmit = () => {
    trackEvent(props.eventName, {
      feedback,
      ...props.eventContext,
    });
    props.setSubmitted(true);
    setTimeout(() => {
      handleClose();
    }, 1000); // delay closure so they can see content change
  };
  return (
    <DevExPopover
      trigger={['click']}
      open={props.open}
      placement="bottom"
      onOpenChange={props.setOpen}
      content={
        <Flex vertical gap={theme.variable.spacing.md} style={{ width: 300 }}>
          <DevExTextArea
            value={feedback}
            onChange={setFeedback}
            placeholder={props.placeholder}
            autoSize={{ minRows: 2 }}
            onKeyUp={(event) => event.key === 'Enter' && !event.shiftKey && onSubmit()}
            disabled={props.submitted}
          />
          <Flex justify="end" gap={theme.variable.spacing.sm}>
            <DevExButton onClick={() => handleClose()}>Cancel</DevExButton>
            <DevExButton type="primary" disabled={!feedback || props.submitted} onClick={onSubmit}>
              {props.submitted ? 'Submitted' : 'Submit'}
            </DevExButton>
          </Flex>
        </Flex>
      }
    >
      {props.children}
    </DevExPopover>
  );
};

type DevExFeedbackProps = {
  prompt: string;
  placeholder: string;
  eventName: string;
  eventContext?: object;
};

export const DevExFeedback: React.FC<DevExFeedbackProps> = (props) => {
  const theme = useDevExTheme();
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  return (
    <div>
      <FeedbackPopover
        open={open}
        setOpen={setOpen}
        eventName={props.eventName}
        placeholder={props.placeholder}
        submitted={submitted}
        setSubmitted={setSubmitted}
      >
        <DevExTag css={styles.feedback}>
          <Flex gap={theme.variable.spacing.sm}>{props.prompt}</Flex>
        </DevExTag>
      </FeedbackPopover>
    </div>
  );
};
