import React from 'react';
import { Flex } from 'antd';

import { StudyClient } from 'jf/api';
import { DevExPageLayout } from 'jf/common/DevExPageLayout';
import { DevExPageTitle } from 'jf/common/DevExPageTitle';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { ChooseStudyStep } from './chooseStudy/ChooseStudyStep';
import { ConfigureTeamsStep } from './configureTeams/ConfigureTeamsStep';
import { CustomizeStudyStep } from './customizeStudy/CustomizeStudyStep';
import { PublishStudyStep } from './publishStudy/PublishStudyStep';
import { TargetRespondentStep } from './targetRespondents/TargetRespondentsStep';
import { StudyBuilderHeader } from './StudyBuilderHeader';
import { useStudyBuilderState } from './StudyBuilderState';

const STUDY_BUILDER_WIDTH = 650;

export const StudyBuilderPage: React.FC = () => {
  const state = useStudyBuilderState();

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: state.studyRef });

  return (
    <Flex vertical style={{ flex: 1 }}>
      <DevExPageTitle>{`${state.studyRef ? study?.name ?? 'Study Builder' : 'Create DevEx Survey'}`}</DevExPageTitle>
      <StudyBuilderHeader />

      {state.step === 'choose-study' && (
        <DevExPageLayout maxWidth={STUDY_BUILDER_WIDTH}>
          <ChooseStudyStep />
        </DevExPageLayout>
      )}

      {state.step === 'customize-study' && <CustomizeStudyStep />}

      {state.step === 'configure-teams' && (
        <DevExPageLayout maxWidth={STUDY_BUILDER_WIDTH}>
          <ConfigureTeamsStep />
        </DevExPageLayout>
      )}

      {state.step === 'target-respondents' && (
        <DevExPageLayout maxWidth={STUDY_BUILDER_WIDTH}>
          <TargetRespondentStep />
        </DevExPageLayout>
      )}

      {state.step === 'publish-study' && (
        <DevExPageLayout maxWidth={STUDY_BUILDER_WIDTH}>
          <PublishStudyStep />
        </DevExPageLayout>
      )}
    </Flex>
  );
};
