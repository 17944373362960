/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TeamMetric } from '../models/TeamMetric';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MetricsClient {

    /**
     * Get metric data for the specified team.
     * @returns TeamMetric
     * @throws ApiError
     */
    public static getTeamMetrics({
        studyRef,
        topicSlug,
        teamRef,
    }: {
        studyRef: string,
        /**
         * A topic slug to request metrics for.
         */
        topicSlug: 'clear_dir' | 'codebase' | 'con_switch' | 'docs' | 'oncall' | 'perc_prod' | 'release' | 'review' | 'team_proc' | 'test' | 'tools_envs',
        /**
         * A team ID to filter the results by.
         */
        teamRef?: string,
    }): CancelablePromise<TeamMetric> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}/metrics/{topicSlug}',
            path: {
                'studyRef': studyRef,
                'topicSlug': topicSlug,
            },
            query: {
                'teamRef': teamRef,
            },
        });
    }

}
