import { useMemo, useState } from 'react';
import React from 'react';
import { debounce } from 'lodash-es';
import { useQueryClient } from 'react-query';

import { StudyClient, UpdatedStudyRequest } from 'jf/api';
import { useClientMutation } from 'jf/utils/useClientQuery';

import { useStudyBuilderState } from './StudyBuilderState';

type _StudyUpdaterContext = {
  studyRequest?: UpdatedStudyRequest;
  setStudyRequest: React.Dispatch<React.SetStateAction<UpdatedStudyRequest>>;
};

const StudyUpdaterContext = React.createContext<_StudyUpdaterContext>({
  setStudyRequest: () => {},
});

export const StudyUpdaterProvider: React.FC = (props) => {
  const [studyRequest, setStudyRequest] = useState<UpdatedStudyRequest>();

  return (
    <StudyUpdaterContext.Provider value={{ studyRequest, setStudyRequest }}>
      {props.children}
    </StudyUpdaterContext.Provider>
  );
};

export const useStudyUpdater = (studyRef: string | undefined) => {
  const queryClient = useQueryClient();
  const state = useStudyBuilderState();
  const { setStudyRequest } = React.useContext(StudyUpdaterContext);

  const { mutateAsync: updateStudy } = useClientMutation(StudyClient.updateStudy);

  const debounceUpdateStudy = useMemo(
    () =>
      debounce((request: UpdatedStudyRequest) => {
        updateStudy({
          studyRef: studyRef!,
          requestBody: request,
        }).then(() => {
          queryClient.invalidateQueries('GET_STUDIES');
          queryClient.invalidateQueries(['GET_STUDY', studyRef]);
          queryClient.removeQueries(['GET_PUBLIC_STUDY', studyRef]);
          state.update({ updatingStudy: false });
        });
      }, 2000),
    [studyRef]
  );

  const update = async (newStudyRequest: UpdatedStudyRequest) => {
    setStudyRequest((currentStudyRequest) => {
      // combine study requests such that if several properties are updated within the debounce time, we merge them into one request
      const studyRequest = { ...currentStudyRequest, ...newStudyRequest };

      if (studyRequest.name === '') {
        // prevent saving an empty name
        debounceUpdateStudy.cancel();
        state.update({ updatingStudy: false });
      } else {
        debounceUpdateStudy(studyRequest);
        state.update({ updatingStudy: true });
      }

      return studyRequest;
    });
  };

  return { update };
};
