import { useStudyBuilderState } from '../StudyBuilderState';

import { useStudySlackTargetUpdater } from './useStudySlackTargetUpdater';

export const useCompleteTargetRespondentsStep = () => {
  const state = useStudyBuilderState();
  const studySlackTargetUpdater = useStudySlackTargetUpdater(state.studyRef);

  const complete = async () => {
    if (state.slackTargetingEnabled) {
      // save changes
      return studySlackTargetUpdater.update(state.slackTargets);
    } else {
      // delete all existing targets
      return studySlackTargetUpdater.update([]);
    }
  };

  return complete;
};
