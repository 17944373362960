import React, { useState } from 'react';

import { DevExCard } from 'jf/components/DevExCard';
import { DevExSegmented } from 'jf/components/DevExSegmented';

import { AnonymityThresholdAlert } from '../AnonymityThresholdAlert';
import { useStudyParticipation } from '../useStudyParticipation';

import { SegmentParticipationCard } from './SegmentParticipationCard';

type ParticipationBySegmentCardProps = {
  studyRef: string | undefined;
  teamRef?: string;
};

export const ParticipationBySegmentCard: React.FC<ParticipationBySegmentCardProps> = (props) => {
  const [segmentKey, setSegmentKey] = useState<string>();

  const { data: participation } = useStudyParticipation({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
  });

  // collate segments and teamSegments together and add keys
  const segments = participation && [
    ...participation.teamSegments.map((teamSegment) => ({
      key: `teamSegment:${teamSegment.name}`,
      ...teamSegment,
    })),
    ...participation.segments.map((segment) => ({
      key: `segment:${segment.name}`,
      ...segment,
    })),
  ];

  const segmentOptions = segments?.map((segment) => ({
    value: segment.key,
    label: segment.name,
  }));

  if (!segmentOptions?.length) {
    return null;
  }

  const segment = segmentKey ? segments?.find((segment) => segment.key === segmentKey) : undefined;
  const segmentData = segment?.values.map((v) => ({ label: v.value, value: v.n }));

  return (
    <DevExCard
      title="Participation by Segment"
      extra={
        <DevExSegmented options={segmentOptions} value={segmentKey} onChange={setSegmentKey} />
      }
    >
      {!!segment && !!segmentData?.length && (
        <SegmentParticipationCard segmentationLabel={segment.name} data={segmentData} />
      )}
      <AnonymityThresholdAlert />
    </DevExCard>
  );
};
